import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';
////import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as Icons from '@mui/icons-material/';

interface CandidateListTableProps {
  state: App.CandidateListState;
  onChange?: Function;

}

export class CandidateListTable extends React.PureComponent<CandidateListTableProps> {

  public candidateService: App.CandidateService;

  constructor(props: any) {
    super(props);
    this.candidateService = new App.CandidateService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('CandidateListTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }

  public setSelected(id: any){
    var state = this.props.state;
    var vm = state.vm;
    var found = state.candidates.find(x => x.candidateId === id);

    vm.setSelectedCandidate(found);
  }

  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, id: any, rowIndex: number) {

    var vm = this.props.state.vm;
    //var target:any = e.target;
    //console.log(e.currentTarget);
    this.setSelected(id);

    console.log('onActionMenuItemClick 1: ', key);

        if(key=="Resume"){
          console.log('onActionMenuItemClick 2: ', key);
          vm.isRequestResumeOpen=true;
         
          //App.AppStore.dispatch(App.ActionTypes.EDIT_REQUEST_RESET);
         // App.AppBase.history.push("EditRequest?candidateId=" + id);
        }
         /*
        else if(key=="Delete"){
          vm.isDeleteOpen=true;
          this.forceUpdate();
        } */

        else if(key=="View"){
          vm.isViewCandidateOpen=true;
          this.forceUpdate();
        } 

        this.forceUpdate();

        if (this.props.onChange)
          this.props.onChange();

  }

  public getActionMenuItems(id: any, tableMeta: any, updateValue: any): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    //var vm = state.vm;
    var candidates = state.candidates;

    var item = candidates.find(x => x.candidateId === id)
    var isReadOnly = false;

    /* 
        if(item!==undefined)
          isReadOnly = (item.workflowRecord.userPermissions.canEdit===false); */

    var result = [
      <MenuItem key="Resume"
        onClick={(e) => this.onActionMenuItemClick(e, 'Resume', id, tableMeta.rowIndex)}
      >Request Resume</MenuItem>,
    /*   <MenuItem key="Delete"
        onClick={(e) => this.onActionMenuItemClick(e, 'Delete', id, tableMeta.rowIndex)}
      >Delete</MenuItem>
 */

    ];

    return result;


  }

  public getTableColumns() {

    var pkColumn = LIB.MUITableHelper.getColumnSetup("ID", "candidateId", false);
   // pkColumn.options.display = 'excluded';
    pkColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (

    //   <span>{value}</span> 
      <Link
        onClick={(e: any) => this.onActionMenuItemClick(e, 'View', value, tableMeta.rowIndex)}
        color="primary">{value}</Link>

    );

    var actionsColumn = LIB.MUITableHelper.getColumnSetup("Actions", "candidateId", false);
    actionsColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      LIB.MUITableHelper.getActionsButton(value, tableMeta, updateValue,
        this.getActionMenuItems(value, tableMeta, updateValue)
      )
    );

    var visaTypeCol = LIB.MUITableHelper.getColumnSetup("Work Auth.", "visaTypeNameOrOther", true);
    
     
   /* 
   var visaTypeCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Work Auth.", "visaType", "name");
   visaTypeCol.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {
      var id = tableMeta.rowData[0];
      //return value+'+ years';

      if(value.name!=='Other') 
        return value.name;
      else
        return <span>test</span>
       
    }; */

    

    var nameColumn = LIB.MUITableHelper.getColumnSetup("Name", "name");
    nameColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { minWidth: 140 });
    }
    nameColumn.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {

      var id = tableMeta.rowData[0];
      var item = this.props.state?.candidates?.find(x => x.candidateId === id);

      if(item?.sortWeight!=undefined && item.sortWeight>0){

        return App.FeaturedManager.getFeaturedStar(value);
        
      }
     else 
       return value;

    };

    //var relocationCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Relocation", "relocationType", "name");
    var relocationCol = LIB.MUITableHelper.getColumnSetup("Relocation", "relocationTypeNameOrOther", true);
    relocationCol.options.customBodyRender = (value:string, tableMeta: any, updateValue: any) => {

      if(LIB.Common.isNullOrEmpty(value))
         return '';
      return <div style={{maxWidth:'5rem', wordWrap:'break-word'}} title={value}>{value}</div>;
       
   };

   // var dateUpdatedCol = LIB.MUITableHelper.getColumnSetupDateTime("Updated", "dateModified");
    //var datePublishedCol = LIB.MUITableHelper.getColumnSetupShortDate("Published", "datePublished");
    var datePublishedCol = LIB.MUITableHelper.getColumnSetup("Published", "datePublished");
    datePublishedCol.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {
      var id = tableMeta.rowData[0];
      var item = this.props.state?.candidates?.find(x => x.candidateId === id);
 
      if(item?.sortWeight!>0){
       // return '';
       return LIB.DateHelper.getFormattedShortDate(new Date());
      }
     
      return LIB.DateHelper.getFormattedShortDate(value);
    };

    var hotColumn = LIB.MUITableHelper.getColumnSetup("Hot", "isHot", true);
    hotColumn.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {

      if(value){
        return <span title="HotList Candidate!"><Icons.Whatshot style={{color:'red'}} /></span>
      }
     

    };

    var linkedInColumn =  LIB.MUITableHelper.getColumnSetup("LinkedIn", "linkedInProfile");
    linkedInColumn.options.customBodyRender = (value:string, tableMeta: any, updateValue: any) => {

      if(!LIB.Common.isNullOrEmpty(value) && !value.startsWith('test-')){
        var url = `https://www.linkedin.com/in/${value}/`;
        return <a href={url} title="Go to LinkedIn Profile" target='_blank'><Icons.LinkedIn  /></a>
      }
      
    };



    var expColumn = LIB.MUITableHelper.getColumnSetup("Experience", "experience", true);
    expColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any)  => {

     
      if(LIB.Common.isNullOrEmpty(value))
          return '';

      return value+'+ years'

    };

    
    var skillsColumn =   LIB.MUITableHelper.getColumnSetup("Skills", "skills");
    skillsColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { maxWidth: 50 });
    }
    skillsColumn.options.customBodyRender = (value:string, tableMeta: any, updateValue: any) => {

       if(LIB.Common.isNullOrEmpty(value))
          return '';
       var str =LIB.StringHelper.maxLength(value,80);
       return <div style={{maxWidth:'10rem', wordBreak:'break-all'}} title={value}>{str}</div>;
        
    };

    var titleColumn =   LIB.MUITableHelper.getColumnSetup("Title", "title", true);
    titleColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, {width: 200 });
    }
    titleColumn.options.customBodyRender = (value:string, tableMeta: any, updateValue: any) => {

      
      return <div style={{width:'10rem'}} >{value}</div>;
       
   };

    var columns = [
      pkColumn,
      nameColumn,
      LIB.MUITableHelper.getColumnSetup("Agency", "companyName", true),
      titleColumn,
      LIB.MUITableHelper.getColumnSetup("Location", "location", true),
      skillsColumn,
      expColumn,
      visaTypeCol,
      relocationCol,
      //linkedInColumn,
      hotColumn,
      datePublishedCol,
      actionsColumn,
    ];


    return columns;
  }

  public onRowSelectionChange(currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) {

    //  console.log('onRowSelectionChange');
    var vm = this.props.state.vm;
    var state = this.props.state;

    var dataIndex = currentRowsSelected[ 0 ].dataIndex;

    var item = state.candidates[dataIndex];
    vm.setSelectedCandidate(item);
  
  }

  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    // vm.selectedRowIndex = rowMeta.rowIndex;
    this.forceUpdate();

  }


  public onPagingChange(tableState:MUIDataTableState){

    var vm = this.props.state.vm;
 
    if(!App.AppBase.isUserAuthenticated)
      return;
    var searchFilter = this.props.state.searchFilter;

    searchFilter.pageNum = tableState.page;
    searchFilter.maxRows = tableState.rowsPerPage;
    searchFilter.sortOrder = tableState.sortOrder.name;
    searchFilter.sortDirection = tableState.sortOrder.direction;
    searchFilter.isSearch=false;
  
    App.AppStore.dispatch(App.ActionTypes.CANDIDATE_LIST_PAGE_DATA_REQUEST, searchFilter);
 
    
  }

  public render() {
    console.log('CandidateListTable render');
    var state = this.props.state;
    var vm = state.vm;

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded)
      return <LIB.Loading />;

    var filter = state.searchFilter;
    var rows = state.candidates;

    //@ts-ignore
    const oldRender = TableCell.render

    //@ts-ignore
TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args);
    }
} 

    //console.log('CandidateListTable render rows', rows);
/* 
    var StyledTableRow = withStyles((theme: Theme) =>
      createStyles({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }),
    )(TableRow);

    var StyledTableCell = withStyles((theme: Theme) =>
      createStyles({
        head: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        body: {
          fontSize: 14,
        },
      }),
    )(TableCell);
 */



    const options: MUIDataTableOptions  = {
      filterType: 'dropdown',
      download: false,
      print: false,
      filter:false,
      viewColumns:false,
      selectableRows: 'single',
      onRowClick: this.onRowClick.bind(this),

      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: true,
      // rowsSelected:[vm.selectedRowIndex],
      selectToolbarPlacement: 'none',
      onRowSelectionChange: this.onRowSelectionChange.bind(this),
      search: false,
      rowsPerPage: 50,
      jumpToPage: App.AppBase.isUserAuthenticated,  //true,
     // rowsPerPageOptions: [10, 50, 100, 200],      
      rowsPerPageOptions: [],      
       
      serverSide:App.AppBase.isUserAuthenticated,  //true,
      count:state.candidatesCount,
       
      onTableChange: (action, tableState) => {
        console.log('onTableChange action: ', action);
        console.log('onTableChange tableState: ', tableState);
 
        if(action=='changePage' || action=='sort'){
          this.onPagingChange(tableState)
        }
        

      }

    };


    return <div>
      <MUIDataTable
        title={""}
        data={rows}
        /*  data={rows.map(item => {
          return [
              item.projectId,
              item.type,
          ]
      })}  */
        columns={this.getTableColumns()}
        options={options}

      />



    </div>
  }
};

