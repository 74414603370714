import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { ContentPagesListTable } from './ContentPagesListTable';
 


class ContentPagesList extends React.Component<App.CMSState, {}> {


  constructor(props: any) {
    super(props);

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('ContentPagesList componentDidMount');


    if (!this.props.hasContentPageDataLoaded) {
      console.log('ContentPagesList getContentPages');

      this.onFilterClick();


    }

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('ContentPagesList componentDidUpdate');

  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;


    vm.setSelectedContentPage();

    App.RouteManager.routeToPath(`/AdminCMS/Pages/0/`);

    //vm.isContentPagesAddOpen = true;
    this.forceUpdate();


  }


  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = this.props;
    var vm = state.vm;

    var itemId = vm.selectedContentPage?.contentPageId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }


  public getFilters() {

    var fullWidth: CSSProperties = {
      // paddingRight: 20,
      paddingLeft: 0,
      //  marginBottom: 10,
      //  padding: 10,

      width: '30vw'
    };
    var formInput: CSSProperties = {
      height: 50,
      //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
    };

    return <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <Grid item xs={12} sm={6} >

        <TextField
          name="keyword"
          //label="Search"
          //value={item.location || ''}
          placeholder='Search'
          {...App.AppBase.getInputErrorProps('keyword')}
          onChange={this.onInputChange.bind(this)}
          style={fullWidth}
          inputProps={{ style: formInput }}
          //variant="outlined"

          InputProps={{
            // className: 'standardFormInput30',
            startAdornment: (
              <InputAdornment position="start">
                <Icons.Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  id="btnCreate"
                  variant="contained"
                  //style={{padding:10}}
                  color="primary"

                  onClick={this.onFilterClick.bind(this)}
                >Filter</Button>
              </InputAdornment>
            ),
          }}

        />

      </Grid>

      <Grid item xs={12} sm={4} >

      </Grid>

    </Grid>

  }

  public onFilterClick() {
    console.log('ContentPagesList onFilterClick');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.contentPageSearchFilter;
    searchFilter.isSearch = true;
    App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_LIST_DATA_REQUEST, searchFilter);

  }


  public render() {

    console.log('ContentPagesList render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var filter = state.contentPageSearchFilter;

    console.log('ContentPagesList hasContentPageDataLoaded', state.hasContentPageDataLoaded);
    // console.log('ContentPagesList isServiceCallPending',this.myListingsService.isServiceCallPending);
    // console.log('ContentPagesList vm.isActionInProgress',vm.isActionInProgress);


    var createBtn: any = null;

  /*   if (currentUser.hasRole(App.UserRoleTypeEnum.PermitsAdmin)) { */
      createBtn = <Button
        id="btnCreate"
        variant="contained"
        style={{ margin: 5,  padding:2, minWidth:10  }}
        color="primary"

        onClick={() => this.onCreateClick()}
      ><Icons.Add   /></Button>
    /* } */


    return <App.MainPage
      pageTitle={<span>Pages {createBtn}</span>}
      state={vm}
      hasDataLoaded={state.hasContentPageDataLoaded}
      loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
     // isChildPage={true}
      showLeftMenu={true}
    >
      <div>

        <div style={{ textAlign: 'right', width: '100%' }} >

          

        </div>




        <Stack direction="row" spacing={2}>
          <LIB.MSearchBar
            filter={filter}
            onSearchClick={() => this.onFilterClick()}
            isSearchInProgress={state.isServiceCallPending}
            btnLabel='Filter'
          >

          </LIB.MSearchBar>

          <span style={{ margin: 10, paddingTop: 20 }}>Count: {state.contentPages?.length}</span>

        </Stack>


        <ContentPagesListTable state={state} onChange={() => this.forceUpdate()} />
 

      </div>



      <LIB.ConfirmModal
        visible={vm.isContentPagesDeleteOpen}
        onCancelClick={() => { vm.isContentPagesDeleteOpen = false; this.forceUpdate(); }}
        onConfirmClick={() => this.onDeleteConfirmClick()}
        title={`Confirm Delete: ${vm.selectedContentPage?.contentPageId}`}
        content={`Are you sure you want to delete "${vm.selectedContentPage?.contentPageId}" permit?`}
        confirmButtonLabel="Delete"
        disabled={vm.isActionInProgress}
      />


    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.cmsState })
)(ContentPagesList as any);

