import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


import { CMSPageReducers } from './CMSPageReducers';
 



export class CMSReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.CMSState | undefined, action: App.AppAction): App.CMSState {
        var str = "";

        var result = new App.CMSState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("CMS"))
            return state;

        if (action.type.startsWith("CMS_CONTENT_PAGES"))
            return CMSPageReducers.getReducers(state, action);
  
   /*      if (action.type.startsWith("CMS_JOBS"))
            return AdministrationJobReducers.getReducers(state, action); */

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var adminUsersService = new App.AdminUsersService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.CMS_API_ERROR:
                console.log('CMS_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                var vm = result.vm;
                //App.AppBase.setApiError(action, appState);
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isServiceCallPending = false;
                result.isContentPageCreateUpdatePending = false;
                vm.isActionInProgress = false;
                break;

            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;

    }



}