import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid, Stack,
  DialogTitle, TextField, Switch, Badge, Popper, Typography
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';
import { UnprivilegedEditor } from 'react-quill';


interface AdministrationJobsAddEditModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationJobsAddEditModal extends React.Component<AdministrationJobsAddEditModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('AdministrationJobsAddEditModal componentDidMount');

    if (!this.props.state.hasUserDataLoaded) {
      console.log('AdministrationJobsAddEditModal getUsersListData for owner drop down list');

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST, null);

      this.forceUpdate();
      console.log('getUsersListData forceUpdate...');

    }
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('AdministrationJobsAddEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;

  }


  public onAddOrUpdateClick(e: React.ChangeEvent<{}>) {
    console.log('onAddOrUpdateClick');

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.jobSm;
    var item = vm.selectedJob;
    var items = state.jobs;

    var isValid = this.validationManager.validateAdminJobCreate(null, item, true);

    if (!isValid) {
      console.log('onSaveClick validateAdminJobCreate: NOT valid');
      this.forceUpdate();
      return;
    }

    if (vm.isJobsEditOpen)
      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_JOBS_UPDATE_REQUEST, item);
    else if (vm.isJobsAddOpen)
      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_JOBS_ADD_REQUEST, item);

    vm.isActionInProgress = true;

    this.onCloseClick();

  }

  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.jobSm;

    //reset edits
    App.JobManager.update(vm.selectedJobOriginal, state.jobs);

    vm.isJobsAddOpen = false;
    vm.isJobsEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

  }

  public onOwnerAutocompleteChange(name: string, value: any, item: any) {
    console.log("onOwnerAutocompleteChange name:", name);
    console.log("onOwnerAutocompleteChange value:", value);
    console.log("onOwnerAutocompleteChange item:", item);

    var vm = this.props.state.vm;

    var selected = {
      name: name,
      value: value
    }

    this.validationManager.validateOwner(name, item);// vm);
    vm.selectedJob.ownerId = item?.userId;

    // console.log('onOwnerAutocompleteChange:', selected);
    console.log('onOwnerAutocompleteChange userId:', item?.userId);

    this.forceUpdate();
  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    console.log('onAutocompleteChange value', value);
    console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.jobSm;

    var item = vm.selectedJob;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);



    /* if (name == 'name')
      item.name = value;

    else {

      name = LIB.FormHelper.setInputChangeData(selected, item);

      if (LIB.Common.isNullOrEmpty(name))
        return;
    }
 */
    this.validationManager.validateAdminJobCreate(key, item, true);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (!item.sortWeight)
      item.sortWeight = 0;

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateAdminJobCreate(name, item, true);


    this.forceUpdate();
  }


  //public onDataChange(key: string, val:string, editor: UnprivilegedEditor) {
  public onDataChange(key: string, value: string) {
    console.log('onRichTextBlue key', key);
    console.log('onRichTextBlue value', value);

    var state = this.props.state;
    var vm = state.vm;
    var item: any = vm.selectedJob;
 
    item[ key ] = value;

    var isValid = this.validationManager.validateJobCreateUpdate(key, item);

    console.log('onDataChange item', item);
    console.log('onDataChange isValid', isValid);

    if (!isValid) {
      this.forceUpdate();
    }

  }

  public onRichTextBlue(key: string, editor: UnprivilegedEditor) {

    console.log('onRichTextBlue key', key);
    //  console.log('onRichTextBlue editor',editor);

    //var value = editor.getHTML();
    var value = editor?.getText();
    value = LIB.StringHelper.addHtmlLineBreaksToString(value);

    //console.log('onRichTextBlue value html',value);
    // console.log('onRichTextBlue valueText',valueText);



    var state = this.props.state;
    var vm = state.vm;
    var item: any = vm.selectedJob;

    item[ key ] = value;
    this.validationManager.validateJobCreateUpdate(key, item);

    this.forceUpdate();


  }


  public getForm() {
    //console.log('AdministrationJobsAddEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;
    console.log('AdministrationJobsAddEditModal getForm state', state);


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedJob;
    var appUsers = this.props.state.appUsers;



    if (item === undefined)
      return null;


    // console.log('getForm appUsers:',appUsers);
    // console.log('getForm appUsers 2:',appUsers[2]);
    console.log('getForm appUsers item:', item);

    var owner = item?.ownerId != undefined ? appUsers.find((appUser) => appUser.userId === item.ownerId) : null;
    console.log('getForm appUsers owner:', owner);


    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasUserDataLoaded && state.hasJobDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >

      <form     >
        <div style={{ padding: 0 }}>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} >
              <TextField
                name="title" label="Title"
                placeholder="i.e Full Stack .NET/React Developer"
                value={item.title || ''}
                {...App.AppBase.getInputErrorProps('title')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>
            <Grid item xs={12} sm={4}
            >


              <App.FormAutocomplete
                label="Type"
                name="jobType"
                data={state.jobTypes}
                onChange={this.onAutocompleteChange.bind(this)}
                labelProperty="name"
                valueProperty="jobTypeId"
                value={item.jobType}
                textFieldStyle={autocompleteInput}

              />



            </Grid>

          </Grid>

          <div style={{ paddingTop: 20 }} >
            <LIB.MRichTextEditor
              {...App.AppBase.getInputErrorProps('description')}
              name='description'
              label='Description'
              placeholder='Description'
              value={item.description || ''}
              onChangeText={this.onDataChange.bind(this)}
              formControlStyle={fullWidth}
             // onBlur={(val: any) => this.onRichTextBlue('description', val)}
              disableToolbar={true}
              addTextLineBreaks={true}
              height={100}
            />
          </div>


          <div >


            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} >
                <TextField
                  name="location" label="Location"
                  value={item.location || ''}
                  {...App.AppBase.getInputErrorProps('location')}
                  onChange={this.onInputChange.bind(this)}
                  style={fullWidth}
                  inputProps={{ style: formInput }}

                />

              </Grid>
              <Grid item xs={12} sm={4} >


                <App.FormAutocomplete
                  label="Visa Requirements"
                  name="requiredVisaType"
                  data={state.candidateVisaTypes}
                  onChange={this.onAutocompleteChange.bind(this)}
                  labelProperty="name"
                  valueProperty="candidateVisaTypeId"
                  value={item.requiredVisaType}
                  textFieldStyle={autocompleteInput}

                />



              </Grid>

              <Grid item xs={12} sm={4} >


                <App.FormAutocomplete
                  label="Partnership Type"
                  name="partnershipType"
                  data={state.partnershipTypes}
                  onChange={this.onAutocompleteChange.bind(this)}
                  labelProperty="name"
                  valueProperty="partnershipTypeId"
                  value={item.partnershipType}
                  textFieldStyle={autocompleteInput}

                />


              </Grid>

            </Grid>




          </div>

          <Grid item xs={12} sm={12}
          >
            <Grid container spacing={2}>
              <Grid item sm={3} >

                <Tooltip arrow placement="right"
                  title="Hot jobs will be included in daily email to all vendors."
                >
                  <FormControlLabel
                    control={
                      <Switch name="isHot"
                        checked={item.isHot}
                        onChange={this.onInputChange.bind(this)}
                      />

                    }
                    style={{ marginLeft: 0, marginTop: 40 }}
                    labelPlacement="start"
                    label="Hot"
                  />
                </Tooltip>

                
              </Grid>


              <Grid item sm={3} >
                <TextField
                  name="sortWeight" label="Sort Weight"
                  value={item.sortWeight || ''}
                  {...App.AppBase.getInputErrorProps('sortWeight')}
                  onChange={this.onInputChange.bind(this)}
                  style={fullWidth}
                  placeholder="sort Weight"
                  inputProps={{ style: formInput }}
                  type='number'
                />

              </Grid>
 
              <Grid item sm={3}
              >
                <TextField
                  name="expireDate" label="Expire Date"
                  //value={item.expireDate || ''}
                  {...App.AppBase.getInputErrorProps('expireDate')}
                  onChange={this.onInputChange.bind(this)}
                  style={fullWidth}
                  inputProps={{ style: formInput }}
                  value={LIB.DateHelper.getDateOnly(item.expireDate)}
                  InputLabelProps={dateInputLabelProps}
                  type="date"
                />

              </Grid>
              <Grid item sm={3}
              >

                <App.FormAutocomplete
                  label="Status"
                  name="listingStatusType"
                  data={state.listingStatusTypes}
                  onChange={this.onAutocompleteChange.bind(this)}
                  labelProperty="name"
                  valueProperty="listingStatusTypeId"
                  value={state.listingStatusTypes?.find(x => x.listingStatusTypeId == item.listingStatusTypeId) || ''}
                  textFieldStyle={autocompleteInput}

                />

              </Grid>
            </Grid>
          </Grid>


          <Grid container spacing={2}>

            <Grid item xs={12} sm={7} >
              <App.FormAutocomplete
                label="Owner"
                name="owner"
                data={appUsers}
                onChange={this.onOwnerAutocompleteChange.bind(this)}
                //  labelProperty="owner"
                labelRenderer={(appUser: any) => { return `${appUser.fullName} (${appUser.email})`; }}
                // value={item?.ownerId != undefined ? appUsers.find((appUser) => appUser.userId === item.ownerId) : null}
                value={owner}
                valueProperty="userId"
                textFieldStyle={textFieldStyle}
              />


          <Tooltip arrow placement="right"
              title="When turned On, System will Automatically Renew this Job every Week so it never expires."

            >
              <FormControlLabel
                control={

                  <Switch name="isAutoRenew"
                    checked={item.isAutoRenew}
                    onChange={this.onInputChange.bind(this)}
                  />

                }
                style={{ marginLeft: 10 }}
                labelPlacement="start"
                label="Auto Renew"
              />
            </Tooltip>


            </Grid>

            <Grid item xs={12} sm={2} >

            </Grid>

          </Grid>

          <div
            style={{ marginTop: 20 }}
          >
          </div>

        </div>

      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('AdministrationJobsAddEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isJobsAddOpen || vm.isJobsEditOpen);
    var item = vm.selectedJob;

    var title = "Add Job";
    var saveBtnTitle = "Add";
    if (vm.isJobsEditOpen) {
      title = `Edit Job (${item.jobId})`;
      saveBtnTitle = "Save";
    }

    var buttonMargin = -50;

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              /* minHeight: '50%',
              maxHeight: '50%', */
              minHeight: 650,
              maxHeight: '80vh',
              minWidth: 800,
              maxWidth: '40vw',
              paddingBottom: 0
            }
          }}
        >
          <DialogTitle  >{title}</DialogTitle>
          <DialogContent
            style={{ paddingBottom: 0 }}
          >

            {this.getForm()}

          </DialogContent>
          <DialogActions style={{marginRight:10}}>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10, marginTop: buttonMargin }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10, marginTop: buttonMargin }}
              onClick={this.onAddOrUpdateClick.bind(this)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</Button>

          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 