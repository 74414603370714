import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as LIB from '_LIB';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import * as XGrid from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

interface MyAccountJobListTableProps {
  state: App.MyAccountState;
  onChange?: Function;

}

export class MyAccountJobListTable extends React.PureComponent<MyAccountJobListTableProps> {

  public myAccountService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myAccountService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountJobListTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }


  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, params: XGrid.GridRenderCellParams) {

    var vm = this.props.state.vm;
    //var target:any = e.target;
    //console.log(e.currentTarget); 
    if (key == "Edit") {
      vm.isJobsEditOpen = true;
    }
    else if (key == "Delete") {
      vm.isJobsDeleteOpen = true;

    }

    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();
  }

  public getActionMenuItems(params: XGrid.GridRenderCellParams): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    //var vm = state.vm;
   // var jobs = state.jobs;

   // var item = jobs.find(x => x.jobId === params?.row?.jobId)
 

    var isReadOnly = false;

    /* 
        if(item!==undefined)
          isReadOnly = (item.workflowRecord.userPermissions.canEdit===false); */

    var result = [
      <MenuItem key="Edit"
        onClick={(e) => this.onActionMenuItemClick(e, 'Edit', params)}
      >Edit</MenuItem>,
      <MenuItem key="Renew"
      onClick={(e) => this.onActionMenuItemClick(e, 'Edit', params)}
    >Renew</MenuItem>,
      <MenuItem key="Delete"
        onClick={(e) => this.onActionMenuItemClick(e, 'Delete', params)}
      >Delete</MenuItem>

    ];

    return result;


  }

  

  public getAlertColumnRenderer = (value: any, tableMeta: any, updateValue: any) => (
    <App.AlertTypeIcon name={value} />
  )


  public onSelectionModelChange(model: any[], details: XGrid.GridCallbackDetails) {

    //console.log('onSelectionModelChange model:', model)
    //console.log('onSelectionModelChange details:', details)

    var vm = this.props.state.vm;
    var state = this.props.state;

    var rows = state.jobs;

    var id = model[ 0 ];

    if (LIB.Common.isNullOrEmpty(id))
      return;

    console.log('onSelectionModelChange id:', id);
    console.log('onSelectionModelChange rows 1:', rows);


    var item = rows.find(x => x.jobId == id);

    console.log('onSelectionModelChange item:', item);

    vm.setSelectedJob(item);

    console.log('onSelectionModelChange selectedJob', vm.selectedJob);

    console.log('onSelectionModelChange rows', rows);


  }

  public setSelected(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var found = state.jobs.find(x => x.jobId === id);

    vm.setSelectedJob(found);
  }
 
  public getTableColumns() {

    var editable = false;
    var readOnly = false;
    //https://mui.com/x/api/data-grid/data-grid/ API


    var pkColumn: GridColDef = {
      field: 'jobId',
      headerName: 'ID',
      width: 80,
      renderCell: (params: XGrid.GridRenderCellParams) => {

          return <Link
          onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', params)}
          color="primary">{params.row.jobId}</Link>;


      }
    }
 


    var actionsColumn: GridColDef = {
      field: '',
      headerName: 'Actions',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: XGrid.GridRenderCellParams) => {


        var menuItems = this.getActionMenuItems(params);
        return LIB.XGridHelper.getActionsButton(menuItems);


      }
    } 

    var statusColumn: GridColDef = {
      headerName: 'Status',
      field: 'statusName',
      width: 110,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.Job;

        return <div>
        {App.FeaturedManager.getStatusNameTag(item)}
        {App.FeaturedManager.getExpiringTag(item)}
    </div>
      }
    }

    
    var titleColumn: GridColDef = {
      headerName: 'Title',
      field: 'title',
      width: 200,
      flex:1,
      editable: editable,
      renderCell: (params: XGrid.GridRenderCellParams) => {

        var item = params.row as Api.Job;
   
        if (item?.sortWeight != undefined && item.sortWeight > 0) {

          return App.FeaturedManager.getFeaturedStar(item?.title);

        }
        else
          return item?.title;


      }
    }

    var columns: GridColDef[] = [

      pkColumn, 
      titleColumn,
      {
        headerName: 'Type',
        field: 'jobType.name',
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.Job;
          return item?.jobType?.name;
        }
      },
      {
        headerName: 'Company',
        field: 'company.companyName',
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.Job;
          return item?.company?.companyName;
        }
      },
      {
        headerName: 'Location',
        field: 'location',
        width: 100,
        editable: editable,
      },
      {
        headerName: 'Remote',
        field: 'isRemote',
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.Job;
          if (item?.isRemote) {
            return <span title="HotList Job!"><Icons.Check style={{ color: 'green' }} /></span>
          }
          else return '';
        }
      },
      {
        headerName: 'Hot',
        field: 'isHot',
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.Job;
          if (item?.isHot) {
            return <span title="HotList Job!"><Icons.Whatshot style={{ color: 'red' }} /></span>
          }
          else return '';
        }
      },
      {
        headerName: 'Partnership',
        field: 'partnershipType.name',
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.Job;
          return item?.partnershipType?.name;
        }
      },
      {
        headerName: 'Visa',
        field: 'requiredVisaType.name',
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.Job;
          return item?.requiredVisaType?.name;
        }
      },
      statusColumn,
      {
        field: 'expireDate',
        headerName: 'Expire Date',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          let item = params.row as Api.Job;
          if(LIB.Common.isNullOrEmpty(item.expireDate))
          return '';
          return LIB.DateHelper.getFormattedShortDate(params.row.expireDate);
        }
      },
      {
        field: 'datePublished',
        headerName: 'Published',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          let item = params.row as Api.Job;
          if(LIB.Common.isNullOrEmpty(item.datePublished))
            return '';
          return LIB.DateHelper.getFormattedShortDate(item.datePublished);
        }
      },

      actionsColumn

    ];


    return columns;
  }

 

  public onExportClick() {
    console.log('onExportClick');
    var state = this.props.state;

    var rows = state.jobs;
    var includeCols = 'jobId,title,skills,location,experience,isRemote,isHot,jobTypeName,requiredVisaTypeName,partnershipTypeName,statusName,datePublished';

    LIB.DownloadHelper.downloadCSVTable(rows, 'Jobs',includeCols);
 
  }

  public render() {
    console.log('MyAccountJobListTable render');
    var state = this.props.state;
    var vm = state.vm;

    /*   if (LIB.Common.isNullOrEmpty(state) || !state.hasJobDataLoaded)
        return <LIB.Loading />;
   */
    var filter = state.jobSearchFilter;
    var rows = [...state.jobs];


    return <div style={{ marginTop: 20 }}>

      <Box sx={{  height: '65vh', width: '100%' }}>
        <DataGrid

          getRowId={(row) => row?.jobId as number | 0}
          rows={rows}
          columns={this.getTableColumns()}
          rowCount={100}
          pageSizeOptions={[ 1000 ]}
          checkboxSelection={false}
          // disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          //   onRowEditCommit={(id: XGrid.GridRowId, e: any) => { console.log('onRowEditCommit:', id, e) }}
          //  onRowEditStart={(params: XGrid.GridRowParams, e: any) => { console.log('onRowEditStart:', params, e) }}
          //   onRowEditStop={(params: XGrid.GridRowParams, e: any) => { console.log('onRowEditStop:', params, e) }}
          // onCellEditCommit={(params: XGrid.GridCellEditCommitParams, e: any) => { console.log('onCellEditCommit:', params, e) }}
          onRowSelectionModelChange={this.onSelectionModelChange.bind(this)}
        // processRowUpdate={(newRow:any, oldRow:any)=>{console.log('processRowUpdate:', newRow, oldRow)}}
        /*      components={{
              Toolbar: CustomToolbar,
              }} */
        />
      </Box>
      <div className='tableLinkBtn'>
        <Link underline="none"
          onClick={() => this.onExportClick()}
        >
          <Icons.Download fontSize="medium" />
          <span style={{ paddingBottom: 10, verticalAlign: 'top' }}>EXPORT</span>
        </Link>
      </div>



    </div>
  }

};

