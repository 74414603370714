import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import * as App from 'AppReferences';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';


import { createStyles,  Theme } from '@mui/material/styles';

//import './AccountMenuItem.css'


interface TutorialsMenuItemProps {
  globalState: App.GlobalUIStateVm;
}

export class TutorialsMenuItem extends React.Component<TutorialsMenuItemProps, {}> {

  public adminDataService: App.AdminDataService;

  constructor(props: any) {
    super(props);
    this.adminDataService = new App.AdminDataService();

    // this.state = new AccountMenuItemState();

  }
 
  public render() {


    return (
      <PopupState variant="popper" popupId="demo-popup-menu">
        {(popupState) => (
          <div  >
          
            <UI.IconButton
              aria-controls="menu-appbar"
               title="TUTORIALS"
              color="inherit"
              className='navMenuBtn'

              {...bindTrigger(popupState)}
             > 
            TUTORIALS
            </UI.IconButton> 

             
            <Menu  {...bindMenu(popupState)}
              id="createProjectBtnMenu"
              onClick={popupState.close}
             // getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

            >
         
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/o4yaklJRULU' target='_blank'  > 
                  Talgy Overview
              </UI.MenuItem>
  
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/oU9V3I9to9o' target='_blank'  > 
              How to Create and Publish a Candidate
              </UI.MenuItem>
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/JK-bltD-qGk' target='_blank'  > 
              How to Create and Publish Jobs
              </UI.MenuItem>
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/I8n8bN6TjIg' target='_blank'  > 
              How to Request Candidate Resume
              </UI.MenuItem>
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/_9lnfqB1zB8' target='_blank'  > 
              How to Submit a Candidate on Job Listings
              </UI.MenuItem>
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/p_mv2-qkl2c' target='_blank'  > 
              How to Renew/Re-Publish Candidates and Jobs
              </UI.MenuItem>
              <UI.MenuItem component={UI.Link}  href='https://youtu.be/R_rkaUnrBcc' target='_blank'  > 
              How To Configure Jobs And Candidates To Automatically Renew
              </UI.MenuItem>
            

           
            </Menu>
          </div>
        )}
      </PopupState>
    );


  }
}

