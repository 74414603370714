import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class BillingService extends App.BaseAppService {


    constructor() {

        super();
    }

    public getPricingPlans(onAfterStateSet: Function | null = null) {
        console.log("BillingService : getPricingPlans");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Billing/getActiveBillingPlans`;

        this.get(apiUrl,
            App.ActionTypes.ACCOUNT_BILLING_PLANS_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR

        );

    }


    public createStripeSession(billingPlanId:number, onAfterStateSet: Function | null = null) {
        console.log("BillingService : createStripeSession");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Billing/createStripeSession?billingPlanId=${billingPlanId}`;

        this.get(apiUrl,
            App.ActionTypes.ACCOUNT_BILLING_CREATE_STRIPE_SESSION_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR

        );

    }


    

 


}
