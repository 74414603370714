import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class JobListReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.JobListState | undefined, action: App.AppAction): App.JobListState {
        var str = "";

        var result = new App.JobListState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("JOB_LIST"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var userService = new App.UserService();
        var accountService = new App.AccountService();
        var jobService = new App.JobService();
        // accountService.appState = appState;
        // accountService.accessToken = currentUser?.token;


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.JOB_LIST_PAGE_DATA_REQUEST: {
                console.log('JOB_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                
                var filter = action.payload;
                jobService.getJobListData(filter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.JOB_LIST_PAGE_DATA_RECEIVED: {
                console.log('JOB_LIST_PAGE_DATA_RECEIVED:', action.payload);
                result = { ...state };
                if (LIB.Common.isNullOrEmpty(result.searchFilter?.keyword)) {
                    result.jobsFullList = action.payload.jobs;
                }
                result.jobs = action.payload.jobs;
                result.jobsCount = action.payload.jobsCount;

                result.hasDataLoaded = true;
                result.isServiceCallPending = false;
                
                break;
            }
            case App.ActionTypes.JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST: {
                console.log('JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var searchFilter = action.payload;
               // myAccountService.getCandidateListData();
                 let candidateFilter = new Api.CandidateFilterVm();
                 candidateFilter.onlyPublished=true;
                jobService.getCandidateListData(candidateFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.JOB_LISTING_CANDIDATE_LIST_DATA_RECEIVED: {
                console.log('JOB_LISTING_CANDIDATE_LIST_DATA_RECEIVED:', action.payload);
                result = { ...state };
                result.currentUserCandidates = action.payload.candidates;
               /*  result.candidateRelocationTypes = action.payload.candidateRelocationTypes;
                result.candidateVisaTypes = action.payload.candidateVisaTypes;
                result.partnershipTypes = action.payload.partnershipTypes; */

                result.hasCandidateDataLoaded = true;
                result.isServiceCallPending = false;

                break;
            }
            case App.ActionTypes.JOB_LIST_API_ERROR:
                console.log('JOB_LIST_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };

                App.AppBase.setApiError(action, appState);

                result.isServiceCallPending = false;
                result.forceUpdateCount++;

                break;

         
            case App.ActionTypes.JOB_LIST_PAGE_RESET: {
                console.log('JOB_LIST_PAGE_RESET:', action.payload);
                result = { ...state };
                result.jobs = result.jobsFullList;
                result.searchFilter.keyword = '';
               // result.hasDataLoaded = true;
                break;
            }
            //JOB_LIST_SUBMIT_CANDIDATE
            case App.ActionTypes.JOB_LIST_SUBMIT_CANDIDATE: {
                console.log('JOB_LIST_SUBMIT_CANDIDATE:', action.payload);
                result = state;
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                jobService.submitCandidate(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.JOB_LIST_SUBMIT_CANDIDATE_SUCCESS: {
                console.log('JOB_LIST_SUBMIT_CANDIDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };

                result.submitCandidateSm = new Api.SubmitACandidateSm();
                App.AppBase.showMessage('Candidate has been submitted for this job!');

                vm.isSubmitCandidateOpen = false;
               // vm.isViewCandidateOpen=false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                result.forceUpdateCount++;
                break;
            }
             
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}