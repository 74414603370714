import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Method } from 'axios';
import * as LIB from '_LIB';
import jwt_decode from "jwt-decode";
import * as App from 'AppReferences';
import { useLocation } from 'react-router';



export class BaseService {


    isServiceCallPending: boolean = false;
    authTokenKey: string;//AccessTokenResponse;

    onError?: Function;
    accessToken: string;
    isAccessTokenRequired: boolean = false;


    constructor(authTokenKey: string, onError?: Function) {
        //console.log('BaseService authTokenKey', authTokenKey);

        this.isServiceCallPending = false;
        this.authTokenKey = authTokenKey;


    }

    public getApiCall(apiUrl: string, onSuccess: Function, onError: Function | undefined = undefined): void {

        this.callApi(apiUrl, null, onSuccess, onError, 'GET');
    }

    public postApiCall(apiUrl: string, postBodyData: any, onSuccess: Function, onError: Function | undefined = undefined): void {

        this.callApi(apiUrl, postBodyData, onSuccess, onError);
    }

    public async getAccessToken() {
        // console.log('BaseService getAccessToken');

        var idToken = sessionStorage.getItem(this.authTokenKey);

        // console.log('BaseService getAccessToken idToken 1', idToken);

        var expirationTime: any;

        if (!LIB.Common.isNullOrEmpty(idToken)) {
            // @ts-ignore
            const { exp } = jwt_decode(idToken)
            // Refresh the token a minute early to avoid latency issues
            expirationTime = (exp * 1000) - 60000
        }

        if (LIB.Common.isNullOrEmpty(expirationTime) || Date.now() >= expirationTime) {
            console.log('BaseService API getAccessToken expirationTime', expirationTime);

             

        }
        //console.log('BaseService getAccessToken idToken', idToken);
        return idToken;
    }

    public async callApi(apiUrl: string,
        data: any,
        onSuccess: Function,
        onErrorFunc: Function | undefined = undefined,
        method: Method = 'POST',
        torkenRequired: boolean = false,
        isFileUpload: boolean = false

    ) {

        console.log('callApi apiUrl: ' + apiUrl + ' Type: ' + method);
       // if (!LIB.Common.isNullOrEmpty(data))
      //      console.log(data);

        // var location = useLocation();

        var onError: Function = this.onErrorDefault;

        if (onErrorFunc !== undefined)
            onError = onErrorFunc;
        else if (this.onError !== undefined)
            onError = this.onError;

        this.isServiceCallPending = true;

        // var accessToken:any;

        //if not set
        if (this.isAccessTokenRequired)
            torkenRequired = this.isAccessTokenRequired;

        if (torkenRequired) {

            //todo if needed to refresh token, this.accessToken = await this.getAccessToken();

            if (LIB.Common.isNullOrEmpty(this.accessToken)) {

                var errMsg = 'Auth token is not found, please log out and login again!';
                console.error(errMsg);
                this.isServiceCallPending = false;
                //setTimeout(onError(new Error(errMsg)), 1000);
                return;
            }


        }

        var contentType = 'application/json';
        if (isFileUpload)
            contentType = 'multipart/form-data';

      // console.log('callApi isFileUpload: ' + isFileUpload + ' contentType: ' + contentType);
      // console.log('callApi data: ',data);

        axios({
            method: method,
            url: apiUrl,
            data: data,
            headers: {
                Authorization: 'Bearer ' + this.accessToken,
                'Content-Type': contentType,
            },
            validateStatus: () => true
        })
            .then(response => {
                //console.log('axios Response:',response);
                var responseURL = response?.request?.responseURL?.toString();
                var redirectUrlKey = 'Login?ReturnUrl=';
                this.isServiceCallPending = false;
                if (response.status === 200) {

                    onSuccess(response.data);

                }
                else if (response.status === 404 && responseURL?.indexOf(redirectUrlKey) > -1) {
                    console.log('axios API Response Error, Auth Redirect responseURL:', responseURL);
                    //responseURL: "/api/Account/Login?ReturnUrl=%2FTalgy%2Fapi%2FCandidate%2FgetCandidateListData%3F"
                    // var returnUrl = responseURL.substring(responseURL.indexOf(redirectUrlKey)+redirectUrlKey.length);
                    //console.log('axios API Response Error, Auth Redirect returnUrl:', returnUrl);
                    //var appState = App.AppBase.getState();
                    var location: any = {};
                    //console.log('axios API Response Error, Auth Redirect router state:', appState.router);
                    App.RouteManager.redirect(`/Login?returnUrl=${location?.pathname || ''}`);

                }
                else {

                    var extraErrorMsg = '';
                    if (response.status === 404 && responseURL?.indexOf('AccessDenied?ReturnUrl=') > -1) {
                        console.log('axios API Response Error: AccessDenied, responseURL:', responseURL);
                        extraErrorMsg = ' - Access Denied!';
                    }

                    console.log('axios API Response Error, response:', response);


                    //var ex = new Error("Error: "  + errorMsg);
                    var ex = BaseService.getErrorEx(response, extraErrorMsg);
                    //console.log(ex);
                    onError(ex);
                    this.isServiceCallPending = false;

                    //todo if (response.status === 401) {
                    //todo     this.authProvider.loginPopup();
                    //todo }


                }



            })
            .catch((error) => {
                console.log('axios API Response Error3:', error);

                let response = error?.response;

                if (!response)
                    response = { data: error };

                //var ex =new Error(error.response.status + " " + error.response.statusText);
                var ex = BaseService.getErrorEx(response, error?.message);

               // console.log('axios API Response Error4:', error);

                onError(ex);
                this.isServiceCallPending = false;


            });

    }

    public static getErrorEx(response: any, extraErrorMsg: string = '') {

        var errorMsg = "";

        if (!LIB.Common.isNullOrEmpty(response) && !LIB.Common.isNullOrEmpty(response.data))
            errorMsg = LIB.ErrorHelper.getStringErrorMessage("error", response.data);

        if (LIB.Common.isNullOrEmpty(errorMsg))
            errorMsg = response.status + " " + response.statusText;

        if(errorMsg=='0 ')
           errorMsg='';

        console.log('getErrorEx errorMsg',errorMsg);
        console.log('getErrorEx response',response);
        console.log('getErrorEx response.data',response.data);

        //var ex = new Error("Error: "  + errorMsg);
        // var ex = new Error(errorMsg);
        //var ex = {error:errorMsg};
        var ex = { "error": { "message": errorMsg + extraErrorMsg } };

        LIB.Common.scrollToTop();

        return ex;
    }


    public onErrorDefault(error: any) {
        console.log('API onError:');


        console.log(error);

        var errorMessage = LIB.ErrorHelper.getStringErrorMessage


    }



}