import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationUsersEditModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationUsersEditModal extends React.Component<AdministrationUsersEditModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationUsersEditModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationUsersEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onUpdateClick(e: React.ChangeEvent<{}>) {
    console.log('onUpdateClick');


    var state = this.props.state;
    var vm = state.vm;
    //  var sm = state.adminUserSm;
    var item = vm.selectedUser;

    var items = state.appUsers;
    console.log(items);

    //validate 
    var isValid = this.validationManager.validateAdministrationUserEdit(null, item);

    if (!isValid) {
      console.log('validateAccountProfileEditModal NOT valid');
      this.forceUpdate();
      return;
    }

    if (vm.isUsersEditOpen) {
      var sm = state.adminUpdateUserFormSm;
      sm.user = vm.selectedUser;
      console.log("item: ", item);
      console.log("sm: ", sm);

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_UPDATE_REQUEST, sm);

    }


    vm.isActionInProgress = true;

    this.forceUpdate();

    //re render parent to clear any api errors
    if (this.props.onChange)
      this.props.onChange();

  }

  public onCloseClick() {
    console.log("onCloseClick");
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminUserSm; //AdminUpdateUserFormSm?

    //reset edits
    App.AdministrationManager.update(vm.selectedUserOriginal, state.appUsers);

    vm.isUsersEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();
    // console.log("need to re render table");
    if (this.props.onChange)
      this.props.onChange();
  }

  /* public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    console.log('onAutocompleteChange value', value);
    console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.adminUserSm;

    var item = vm.selectedUser;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);

    this.validationManager.validateAdministrationUserEdit(key, item);
    
    this.forceUpdate();
  } */




  public onAutocompleteCountryChange(name: string, value: any, details: any) {
    //console.log('onAutocompleteAddressChange');

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.adminUserSm;

    var item = vm.selectedUser;

    var selected = {
      name: name,
      value: value
    }

    //console.log('onAutocompleteAddressChange selected', selected);


    var key = LIB.FormHelper.setInputChangeData(selected, item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateAdministrationUserEdit(key, item);


    this.forceUpdate();
  }

  public setInputChangeDataCompany(target: any, data: any): string {

    if (LIB.Common.isNullOrEmpty(target) || LIB.Common.isNullOrEmpty(data)) {
      return "";
    }
    var key = target.name;

    if (LIB.Common.isNullOrEmpty(key))
      key = target.id;

    if (LIB.Common.isNullOrEmpty(key))
      return "";

    // console.log('setInputChangeData data[key]', typeof(data["company"]), data["company"].key ,target.value);

    data[ "company" ].companyName = target.value;

    return key;
  }

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedUser;
    console.log("item:", item);

    var target: any = e.target;
    console.log("target:", target.name, target.type);

    var name;
    if (target.name == "companyName") {
      name = this.setInputChangeDataCompany(e.target, item);


    }
    else {
      name = LIB.FormHelper.setInputChangeData(e.target, item);
    }

    if (LIB.Common.isNullOrEmpty(name))
      return;

    this.validationManager.validateAdministrationUserEdit(name, item);

    this.forceUpdate();
  }

  public getForm() {
    console.log('AdministrationUsersEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedUser;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedUser;

    //console.log('AdministrationUsersEditModal getForm selectedUser',item);
    //console.log("item before FullAddress render", item);

    if (item === undefined)
      return null;
 
    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasUserDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      {

        <form >
          {/*   add div https://stackoverflow.com/questions/56156589/simple-material-ui-dialog-example-has-unwanted-scrollbar
 */}
          <div style={{ padding: 8  }}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} >
                <TextField
                  name="firstName" label="First Name"
                  value={item.firstName || ''}
                  {...App.AppBase.getInputErrorProps('firstName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}
                />
              </Grid>
              <Grid item xs={12} sm={6}

              >
                <TextField
                  name="lastName" label="Last Name"
                  value={item.lastName || ''}
                  {...App.AppBase.getInputErrorProps('lastName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <TextField
                  name="email" label="Email"
                  value={item.email || ''}
                  {...App.AppBase.getInputErrorProps('email')}
                  style={formControl}
                  inputProps={{ style: formInput }}
                  onChange={this.onInputChange.bind(this)}

                //   disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}
              >
                <TextField
                  name="phone" label="Phone"
                  value={item.phone || ''}
                  {...App.AppBase.getInputErrorProps('phone')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <TextField
                  name="companyName" label="Company"
                  value={item.company?.companyName || ''}
                  {...App.AppBase.getInputErrorProps('companyName')}
                  style={formControl}
                  inputProps={{ style: formInput }}
                  onChange={this.onInputChange.bind(this)}
                //  margin="normal" fullWidth

                />
              </Grid>
              <Grid item xs={12} sm={3} >
                <LIB.MCheckbox {...App.AppBase.getInputErrorProps('emailConfirmed')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="emailConfirmed"
                        color="primary"

                        checked={item.emailConfirmed}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: -10, marginTop: 57 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Confirm Email
                    </span>}
                  />}
                >

                </LIB.MCheckbox>

              </Grid>
              <Grid item xs={12} sm={3} >
                <LIB.MCheckbox {...App.AppBase.getInputErrorProps('isManager')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="isManager"
                        color="primary"

                        checked={item.isManager}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: -10, marginTop: 57 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      isManager
                    </span>}
                  />}
                >

                </LIB.MCheckbox>

              </Grid>
            </Grid>

            <App.FullAddress
              address={item.address}
              city={item.city}
              state={item.state}
              zipCode={item.zipCode}
              country={item.country}
              onInputChange={this.onInputChange.bind(this)}
              onAutocompleteChange={this.onAutocompleteCountryChange.bind(this)}
            />




            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enableDailyEmailHotCandidates"
                      color="primary"
                      {...App.AppBase.getInputErrorProps('enableDailyEmailHotCandidates')}
                      checked={item.enableDailyEmailHotCandidates}
                      onChange={this.onInputChange.bind(this)}
                      // style={fullWidth}
                      inputProps={{ style: formInput }}

                    />
                  }
                  style={{ marginLeft: 0 }}
                  labelPlacement="start"
                  label="Receive Daily Hot Candidates Emails?"
                />


              </Grid>
              <Grid item xs={12} sm={6} >


                <FormControlLabel
                  control={
                    <Checkbox
                      name="enableDailyEmailHotJobs"
                      color="primary"
                      {...App.AppBase.getInputErrorProps('enableDailyEmailHotJobs')}
                      checked={item.enableDailyEmailHotJobs}
                      onChange={this.onInputChange.bind(this)}
                      // style={fullWidth}
                      inputProps={{ style: formInput }}

                    />
                  }
                  style={{ marginLeft: 0 }}
                  labelPlacement="start"
                  label="Receive Daily Hot Jobs Emails?"
                />




              </Grid>
            </Grid>


          </div>
        </form>

      }

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    console.log('AdministrationUsersEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isUsersEditOpen;
    var item = vm.selectedUser;

    if (!isOpen)
      return;

    var title;
    var saveBtnTitle;

    if (vm.isUsersEditOpen) {
      title = `Edit User (${item?.userId})`;
      saveBtnTitle = "Save";
    }


    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        // fullWidth = {true}
        // maxWidth= "sm"
        //  PaperProps={{ sx: { width: "30%", height: "100%" } }}
        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{height:"60vh", minHeight: 300,minWidth:800  }} >


            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onUpdateClick.bind(this)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 