import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import LZString from 'lz-string';


export class AuthManager {


    private static currentUserKey: string ="talgy_currentUser";

   
    public static getAuthorizationToken() {

        if (!App.AppBase.isUserAuthenticated)
            return "";

        var user = App.AppBase.currentUser;
        if (LIB.Common.isNullOrEmpty(user.token))
            return "";

        var ret = 'Bearer ' + user.token;
        return ret;
    }
     

    public static redirectToLogin() {
        App.RouteManager.redirect("/Login");

    }

    public static logoutClearSession() {
        console.log('logoutClearSession');

        localStorage.removeItem(AuthManager.currentUserKey);
       
    }

    public static logout():boolean {
        console.log('logout');

        var result = App.AppBase.isUserAuthenticated;

        localStorage.removeItem(AuthManager.currentUserKey);
        var emptyUser: any = null;
        var state = App.AppBase.getState();
        var currentUser = state?.globalData?.initialData?.currentUser;
        currentUser.userId=0;
        currentUser.firstName='';
        currentUser.lastName='';

        return result;

    }

    public static saveUserToLocalStorage(user: App.AppUser) {

       // console.log('saveUserToLocalStorage user',user);

        if (LIB.Common.isNullOrEmpty(user))
            return;
 
        var strUser = JSON.stringify(user);
       // console.log('test22 saveUserToLocalStorage user stringify',strUser);

        var compressed = LZString.compress(strUser);

       // console.log('saveUserToLocalStorage user compressed',compressed);

        localStorage.setItem(AuthManager.currentUserKey, compressed);
      
    }

    public static getUserFromLocalStorage(): any {
    //console.log('getUserFromLocalStorage');
        
        var item = localStorage.getItem(AuthManager.currentUserKey);
        if (item == null)
            return null; 

        var decompressedUserStr = LZString.decompress(item);

         //console.log('test22 getUserFromLocalStorage decompressedUserStr',decompressedUserStr);

        if (LIB.Common.isNullOrEmpty(decompressedUserStr) || decompressedUserStr==null)
              return null;

        var userFromJson = JSON.parse(decompressedUserStr);
       // console.log('getUserFromLocalStorage userFromJson',userFromJson);

        var user:App.AppUser = new App.AppUser();
        user.setData(userFromJson);

        //console.log('getUserFromLocalStorage user',user);

        if (LIB.Common.isNullOrEmpty(user))
            return null;

        return user;
    }

     

}