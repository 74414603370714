import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class CandidateListReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.CandidateListState | undefined, action: App.AppAction): App.CandidateListState {
        var str = "";

        var result = new App.CandidateListState();

        if (state === undefined) {
            return result;
        }

        var allowedList: any[] = [];//App.ActionTypes.ROUTE_CHANGE];

        if (!action.type.startsWith("CANDIDATE_LIST") && allowedList.indexOf(action.type) < 0)
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var userService = new App.UserService();
        var accountService = new App.AccountService();
        var candidateService = new App.CandidateService();
        // accountService.appState = appState;
        // accountService.accessToken = currentUser?.token;


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.CANDIDATE_LIST_API_ERROR: {
                console.log('CANDIDATE_LIST_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };

                App.AppBase.setApiError(action, appState);

                result.isServiceCallPending = false;
                var vm = result.vm;
                vm.isActionInProgress = false;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.CANDIDATE_LIST_PAGE_DATA_REQUEST: {
                console.log('CANDIDATE_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var searchFilter = action.payload;
                candidateService.getCandidateListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.CANDIDATE_LIST_PAGE_DATA_RECEIVED: {
                console.log('CANDIDATE_LIST_PAGE_DATA_RECEIVED:');
                result = { ...state };
                if (LIB.Common.isNullOrEmpty(result.searchFilter?.keyword)) {
                    result.candidatesFullList = action.payload.candidates;
                }
                result.candidates = action.payload.candidates;
                result.candidatesCount = action.payload.candidatesCount;
                result.hasDataLoaded = true;
                result.isServiceCallPending = false;
                console.log('CANDIDATE_LIST_PAGE_DATA_RECEIVED result:', result);
                break;
            }
            case App.ActionTypes.CANDIDATE_LIST_PAGE_RESET: {
                console.log('CANDIDATE_LIST_PAGE_RESET:');
                result = { ...state };
                result.candidates = result.candidatesFullList;
                result.searchFilter.keyword = '';
               // result.forceUpdateCount++;
                console.log('CANDIDATE_LIST_PAGE_RESET result:', result);
                break;
            }
            case App.ActionTypes.CANDIDATE_LIST_REQUEST_RESUME: {
                console.log('CANDIDATE_LIST_REQUEST_RESUME:', action.payload);
                result = state;
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                candidateService.requestResume(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.CANDIDATE_LIST_REQUEST_RESUME_SUCCESS: {
                console.log('CANDIDATE_LIST_REQUEST_RESUME_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };

                result.resumeRequestSm = new Api.CandidateRequestResumeSm();

             

              //  App.AppBase.showMessage('Request has been sent, Agency will respond soon!');

                vm.isRequestResumeOpen = false;
                vm.isViewCandidateOpen=false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                result.forceUpdateCount++;
                break;
            }
            /*  case App.ActionTypes.ROUTE_CHANGE: {
                 console.log('ROUTE_CHANGE check ResumeRequest:');
                 result = state;
                 break;
             }    */
            default:
                result = state;
                //return result;
                break;
        }

        console.log('CANDIDATE_LIST_PAGE_DATA_RECEIVED result2:', result);

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}