import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationEmailTemplatesEditProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationEmailTemplatesEdit extends React.Component<AdministrationEmailTemplatesEditProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationEmailTemplatesEdit componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationEmailTemplatesEdit componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {
    console.log("onCloseClick");
    var state = this.props.state;
    var vm = state.vm;
   // var sm = state.adminCreateEmailTemplateSm;
    //vm.selectedEmailTemplateOriginal

    //reset to original
    App.AdministrationManager.updateEmailTemplate(vm.selectedEmailTemplateOriginal, state.emailTemplates);


    vm.isEmailTemplateEditOpen = false;
    vm.isEmailTemplateTableOpen = true;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();

  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedEmailTemplate;
    //var sm = state.adminCreateEmailTemplateSm;

    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange key', key);
    //console.log('onInputChange item', item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateEmailTemplateCreateUpdate(key, item);

    this.forceUpdate();
  }

  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');

    var state = this.props.state;
    var vm = state.vm;
    //var sm = state.adminCreateEmailTemplateSm;
    var item = vm.selectedEmailTemplate;

    var isValid = this.validationManager.validateEmailTemplateCreateUpdate(null, item);

    if (!isValid) {
      console.log('validateEmailTemplateCreateUpdate NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST, item);

    vm.isActionInProgress = true;

    this.forceUpdate();

    if (this.props.onChange)
    this.props.onChange();
  }

  public onDataChange(key: string, value: any) {
    console.log('onDataChange key, value', key, value);

    var state = this.props.state;
    var vm = state.vm;
    var item: any = vm.selectedEmailTemplate;
    //var sm = state.adminCreateEmailTemplateSm;

    //item[key] = value;
    item.body = value;


    var isValid = this.validationManager.validateEmailTemplateCreateUpdate(key, item);

    // console.log('onDataChange item', item);
    console.log('onDataChange isValid', isValid);

    if (!isValid) {
      this.forceUpdate();
    }

  }


  public onRichTextBlue(key: string, value: any) {

    console.log('onRichTextBlue key, value', key, value);

    var state = this.props.state;
    var vm = state.vm;
    //    var item: any = vm.selectedJob;
    var sm = state.adminCreateEmailTemplateSm;


    // item[key] = value;
    sm.body = value;

    this.validationManager.validateEmailTemplateCreateUpdate(key, sm);// item);

    this.forceUpdate();


  }


  public getForm() {
    console.log('AdministrationEmailTemplatesEdit getForm');

    var state = this.props.state;
    var vm = state.vm;
    //var sm = state.adminCreateEmailTemplateSm;
    var item = vm.selectedEmailTemplate;
    //console.log(sm);



    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    /* var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    }; */

    var formControl: CSSProperties = {
      margin: theme.spacing(2),
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20,
      paddingBottom: 10
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    /* var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    }; */

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasEmailTemplateDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      //  errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      {

        <form     >

          <div style={{ width: '40vw' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} >
                <TextField
                  name="name" label="Name"
                  value={item.name || ''}
                  {...App.AppBase.getInputErrorProps('name')}
                  onChange={this.onInputChange.bind(this)}
                  style={fullWidth}
                  inputProps={{ style: formInput }}

                />

              </Grid>
              <Grid item xs={12} sm={4}>
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>

                <TextField
                  name="fromName"
                  label="From Name:"
                  value={item.fromName || ''}
                  {...App.AppBase.getInputErrorProps('fromName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />

              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  name="fromEmail"
                  label="From Email:"
                  value={item.fromEmail || ''}
                  {...App.AppBase.getInputErrorProps('fromEmail')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />
              </Grid>

            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} >
                <TextField
                  name="subject" label="Subject:"
                  //   placeholder="i.e Full Stack .NET/React Developer"
                  value={item.subject || ''}
                  {...App.AppBase.getInputErrorProps('subject')}
                  onChange={this.onInputChange.bind(this)}
                  style={fullWidth}
                  inputProps={{ style: formInput }}

                />

              </Grid>
              <Grid item xs={12} sm={4}>
              </Grid>

            </Grid>
          </div>

          <div style={{ paddingTop: 40 }} >
            <LIB.MRichTextEditor
              {...App.AppBase.getInputErrorProps('body')}
              name='body'
              label='Body:'
              placeholder='Description'
              value={item.body || ''}
              onChange={this.onDataChange.bind(this)}
              formControlStyle={fullWidth}
             // onBlur={(val: any) => this.onRichTextBlue('description', val)}
            />
          </div>

          <div >


            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} >


              </Grid>
              <Grid item xs={12} sm={4} >
              </Grid>

              <Grid item xs={12} sm={4} >

              </Grid>

            </Grid>



            <div>
              <FormControlLabel
                control={

                  <Switch name="isActive"
                    checked={item.isActive}
                    onChange={this.onInputChange.bind(this)}
                    color="primary"
                  />

                }
                style={{ marginLeft: 0 }}
                labelPlacement="start"
                label="Is Active:"
              />


            </div>



          </div>


        </form>

      }

    </App.MainPage>;


  }


  /* public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  } */

  public render() {
    console.log('AdministrationEmailTemplatesEdit render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isEmailTemplateEditOpen;
    var item = vm.selectedEmailTemplate;


    if (!isOpen)
      return;

    //var title = "Edit Email Template";

    return (
      <div style={{ width: '50vw' }}>
        <h1>Edit Email Template</h1>
        <h3 style={{ marginLeft: '1rem' }}>ID: {item.emailTemplateId}</h3>

        {this.getForm()}





        <div style={{ textAlign: 'right', width: '100%' }} >

          <Button
            variant="contained"
            //color="default"
            style={{ margin: 10 }}
            onClick={this.onCloseClick.bind(this)}
            disabled={vm.isActionInProgress}
          >Cancel</Button>

          <Button
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={this.onSaveClick.bind(this)}
            disabled={vm.isActionInProgress}
          >Save</Button>

        </div>

      </div>
    );



  }
} 