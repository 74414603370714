import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';
//import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as Icons from '@mui/icons-material/';
import { SubmitCandidateModal } from './SubmitCandidateModal';

interface JobListTableProps {
  state: App.JobListState;
}

export class JobListTable extends React.PureComponent<JobListTableProps> {

  public jobService: App.JobService;

  constructor(props: any) {
    super(props);
    this.jobService = new App.JobService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('JobListTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }

  public setSelected(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var found = state.jobs.find(x => x.jobId === id);

    vm.setSelectedJob(found);
  }

  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, id: any, rowIndex: number) {

    var vm = this.props.state.vm;
    //var target:any = e.target;
    //console.log(e.currentTarget);
    this.setSelected(id);
    /* 
        if(key=="Edit"){
          App.AppStore.dispatch(App.ActionTypes.EDIT_REQUEST_RESET);
          App.AppBase.history.push("EditRequest?jobId=" + id);
        }
        else if(key=="Delete"){
          vm.isDeleteOpen=true;
          this.forceUpdate();
        } */

    if (key == "SubmitCandidate") {
      vm.isSubmitCandidateOpen = true;
      this.forceUpdate();
    }

  }

  public getActionMenuItems(id: any, tableMeta: any, updateValue: any): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    var vm = state.vm;
    var jobs = state.jobs;

    var item = jobs.find(x => x.jobId === id)
    var isReadOnly = false;

    /* 
        if(item!==undefined)
          isReadOnly = (item.workflowRecord.userPermissions.canEdit===false); */

    var result = [
      <MenuItem key="SubmitCandidate"
        onClick={(e) => this.onActionMenuItemClick(e, 'SubmitCandidate', id, tableMeta.rowIndex)}
      >Submit a Candidate</MenuItem>,
      /*   <MenuItem key="Delete"
          onClick={(e) => this.onActionMenuItemClick(e, 'Delete', id, tableMeta.rowIndex)}
        >Delete</MenuItem>
   */

    ];

    return result;


  }

  public getTableColumns() {

    var currentUser = App.AppBase.currentUser;

    var pkColumn = LIB.MUITableHelper.getColumnSetup("ID", "jobId", false);
    pkColumn.options.display = 'excluded';
    pkColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (

      <Link
        onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', value, tableMeta.rowIndex)}
        color="primary">{value}</Link>

    );

    var titleColumn = LIB.MUITableHelper.getColumnSetup("Title", "title", true);
    titleColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
      var id = tableMeta.rowData[ 0 ];

      var item = this.props.state?.jobs?.find(x => x.jobId === id);

      var titleLink = <Link
      onClick={(e: any) => this.onActionMenuItemClick(e, 'SubmitCandidate', id, tableMeta.rowIndex)}
      color="primary">{value}</Link>;

      if(item?.sortWeight!=undefined && item.sortWeight>0){

        return App.FeaturedManager.getFeaturedStar(titleLink);
        
      }
      else 
      return titleLink;

    };
 

    var actionsColumn = LIB.MUITableHelper.getColumnSetup("Actions", "jobId", false);
    //if currrentuser has admin role, show actions
    if (!currentUser?.isAuthenticated) {
      actionsColumn.options.display = 'excluded';
    }

    actionsColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      LIB.MUITableHelper.getActionsButton(value, tableMeta, updateValue,
        this.getActionMenuItems(value, tableMeta, updateValue)
      )
    );

    var visaTypeCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Visa", "requiredVisaType", "name");
    var jobTypeCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Type", "jobType", "name");
    //var dateUpdatedCol = LIB.MUITableHelper.getColumnSetupShortDate("Updated", "dateModified");
    var partnerTypeCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Partnership", "partnershipType", "name");
    var companyCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Company", "company", "companyName");
   
   // var datePublishedCol = LIB.MUITableHelper.getColumnSetupShortDate("Published", "datePublished");

    var datePublishedCol = LIB.MUITableHelper.getColumnSetup("Published", "datePublished");
    datePublishedCol.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {
      var id = tableMeta.rowData[0];
      var item = this.props.state?.jobs?.find(x => x.jobId === id);
 
      if(item?.sortWeight!>0){
       // return '';
       return LIB.DateHelper.getFormattedShortDate(new Date());
      }
     
      return LIB.DateHelper.getFormattedShortDate(value);
    };

    var hotColumn = LIB.MUITableHelper.getColumnSetup("Hot", "isHot", true);
    hotColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {

      if (value) {
        return <span title="HotList Job!"><Icons.Whatshot style={{ color: 'red' }} /></span>
      }


    };

    var remoteColumn = LIB.MUITableHelper.getColumnSetup("Remote", "isRemote", true);
    remoteColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {

      if (value) {
        return <span title="HotList Job!"><Icons.Check style={{ color: 'green' }} /></span>
      }


    };

    var expColumn = LIB.MUITableHelper.getColumnSetup("Experience", "experience", true);
    expColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (

      <span>{value}+ years</span>

    );


    var skillsColumn = LIB.MUITableHelper.getColumnSetup("Skills", "skills");
    skillsColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { maxWidth: 50 });
    }


    var columns = [
      pkColumn,
      // LIB.MUITableHelper.getColumnSetup("Title", "title", true),
      titleColumn,
      jobTypeCol,
      companyCol,
      LIB.MUITableHelper.getColumnSetup("Location", "location", true),
      remoteColumn,
      hotColumn,
      visaTypeCol,
      partnerTypeCol,
      datePublishedCol,
      actionsColumn,
    ];


    return columns;
  }

  public onRowSelectionChange(currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) {

    //  console.log('onRowSelectionChange');
    var vm = this.props.state.vm;
    var state = this.props.state;

    var dataIndex = currentRowsSelected[ 0 ].dataIndex;

    //todo vm.selectedItem = state.jobs[dataIndex];
    //todo  vm.selectedIndex = dataIndex;


  }

  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    // vm.selectedRowIndex = rowMeta.rowIndex;
    this.forceUpdate();

  }



  public onPagingChange(tableState:MUIDataTableState){

    var vm = this.props.state.vm;
 
    if(!App.AppBase.isUserAuthenticated)
      return;
    var searchFilter = this.props.state.searchFilter;

    searchFilter.pageNum = tableState.page;
    searchFilter.maxRows = tableState.rowsPerPage;
    searchFilter.sortOrder = tableState.sortOrder.name;
    searchFilter.sortDirection = tableState.sortOrder.direction;
    searchFilter.isSearch=false;
  
    App.AppStore.dispatch(App.ActionTypes.JOB_LIST_PAGE_DATA_REQUEST, searchFilter);
 
    
  }
  public render() {
    console.log('JobListTable render');
    var state = this.props.state;
    var vm = state.vm;

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded)
      return <LIB.Loading />;

    var filter = state.searchFilter;
    var rows = state.jobs;


    //@ts-ignore
    const oldRender = TableCell.render

    //@ts-ignore
    TableCell.render = function (...args) {
      const [ props, ...otherArgs ] = args
      if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [ propsWithoutEmpty, ...otherArgs ])
      } else {
        return oldRender.apply(this, args);
      }
    }


    const options: MUIDataTableOptions  = {
      filterType: 'dropdown',
      download: false,
      print: false,
      filter:false,
      viewColumns:false,
      selectableRows: 'single',
      onRowClick: this.onRowClick.bind(this),

      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: true,
      // rowsSelected:[vm.selectedRowIndex],
      selectToolbarPlacement: 'none',
      onRowSelectionChange: this.onRowSelectionChange.bind(this),
      search: false,
      rowsPerPage: 50,
      jumpToPage: App.AppBase.isUserAuthenticated,  //true,
     // rowsPerPageOptions: [10, 50, 100, 200],      
      rowsPerPageOptions: [],      
       
      serverSide:App.AppBase.isUserAuthenticated,  //true,
      count:state.jobsCount,
       
      onTableChange: (action, tableState) => {
        console.log('onTableChange action: ', action);
        console.log('onTableChange tableState: ', tableState);
 
        if(action=='changePage' || action=='sort'){
          this.onPagingChange(tableState)
        }
        

      }

    };



    return <div>
      <MUIDataTable
        title={""}
        data={rows}
        /*  data={rows.map(item => {
          return [
              item.projectId,
              item.type,
          ]
      })}  */
        columns={this.getTableColumns()}
        options={options}

      />

     
     <SubmitCandidateModal state={state} onChange={() => this.forceUpdate()} />
     

    </div>
  }
};

