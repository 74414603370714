
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class CMSVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;
  
    public isContentPagesAddOpen: boolean = false;
    public isContentPagesDeleteOpen: boolean = false;
    public isContentPagesEditOpen: boolean = false;
    public selectedContentPage : Api.ContentPage;
    public selectedContentPageOriginal : Api.ContentPage;
    public contentPageTableLoading:boolean = false;
    public selectedContentPageId: number=-1;

    public selectedFormContentTab: string;


    constructor() {

        super();
    }
 
       
    public setSelectedContentPage(item: Api.ContentPage | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.ContentPage();


        this.selectedContentPage = item;
        this.selectedContentPageOriginal = LIB.ObjectHelper.clone(this.selectedContentPage);

    }

 
    
    

}