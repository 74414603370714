import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, TextareaAutosize, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Link, Stack, Tabs, Tab
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
//import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface ContentPagesDetailFormProps {
  state: App.CMSState;
  onChange?: Function;
}


export class ContentPagesDetailForm extends React.Component<ContentPagesDetailFormProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('ContentPagesDetailForm componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('ContentPagesDetailForm componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onAddOrUpdateClick(isPublished: boolean) {
    console.log('onAddOrUpdateClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = state.contentPageSm;
    var item = vm.selectedContentPage;
    var items = state.contentPages;

    //todo item.isPublished = isPublished;

    var isValid = this.validationManager.validateCMSContentPageCreateUpdate(null, item);

    if (!isValid) {
      console.log('validateAddContentPageModal NOT valid, ErrorMessage:', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    if (vm.selectedContentPage.contentPageId!! > 0)
      App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_UPDATE_REQUEST, item);
    else
      App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_ADD_REQUEST, item);

    vm.isActionInProgress = true;

    // this.onCloseClick();

  }

  public onAIGenerateContentClick() {
    console.log('onAIGenerateContentClick');
 
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.contentPageSm;
    var item = vm.selectedContentPage;
    
    App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_REQUEST, item);
 
    vm.isActionInProgress = true;
    this.forceUpdate();
 

  }

  public onCloseClick() {
    console.log('onCloseClick');
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.contentPageSm;



    //reset edits
    App.ContentPagesManager.update(vm.selectedContentPageOriginal, state.contentPages);


    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    window.history.go(-1);


    // console.log('onCloseClick onChange');

    if (this.props.onChange !== undefined) {
      //@ts-ignore
      this.props.onChange();
    }

    // console.log('onCloseClick end');

  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    console.log('onAutocompleteChange value', value);
    console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.contentPageSm;

    var item = vm.selectedContentPage;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);


    this.validationManager.validateCMSContentPageCreateUpdate(key, item);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedContentPage;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange name',name);

    if (LIB.Common.isNullOrEmpty(name))
      return;

    if (name == 'name') {

      if (LIB.Common.isNullOrEmpty(vm.selectedContentPageOriginal?.title))
        item.title = item.name;

    }


    this.validationManager.validateCMSContentPageCreateUpdate(name, item);


    this.forceUpdate();
  }

  
  public onRichTextChange(key: string, value: any, forceUpdate:boolean=false) {

    console.log('onRichTextChange key', key);
    console.log('onRichTextChange value', value);

    var state = this.props.state;
    var vm = state.vm;
    //    var item: any = vm.selectedJob;
    var sm = vm.selectedContentPage;


    // item[key] = value;
    //if(LIB.Common.isNullOrEmpty(sm.content))
     sm.content = value;

     var isValid = this.validationManager.validateCMSContentPageCreateUpdate(key, sm);

     console.log('onRichTextChange isValid', isValid);


     //if (!isValid || forceUpdate) {
      this.forceUpdate();
    //}

  }
   

  public getContentPart() {

    var state = this.props.state;
    var vm = state.vm;

    var selectedTab = vm.selectedFormContentTab;
    var item = vm.selectedContentPage;

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      //paddingTop: 10
      marginLeft:0
    };
    var textInput: CSSProperties = {
     // padding: 10,
      width: '100%'
    };

    var fullWidth: CSSProperties = {
      //paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };

    var tabBoxStyle: CSSProperties = {
      padding: 0,
    };

    return <div>


      

    
    <App.SmartTabs
     // tabList={[ 'Editor','Source', 'AI Script' ]}
      tabList={[  'AI Script', 'Source' ]}
      orientation='horizontal'
      selectedTab={vm.selectedFormContentTab}
      //startUrl={startUrl}
     forceUpdate={() => {

      console.log("getContentPart forceUpdate");
        this.forceUpdate();
         // this.onTabUpdate();
       }}
      
      onSelectedChange={(newTab: string) => {
        console.log("getContentPart onSelectedChange newTab", newTab);
        vm.selectedFormContentTab = newTab;
        this.forceUpdate();
      }}
    >
     {/*  <LIB.TabPanel name="Editor" selectedKey={selectedTab} tabBoxStyle={tabBoxStyle}
      > 

        <LIB.MRichTextEditor
              {...App.AppBase.getInputErrorProps('content')}
              name='content'
              
             // label='Body:'
              placeholder='Content'
              value={item.content}
               onChange={(key: any, val:any) => this.onRichTextChange(key, val)}
              formControlStyle={fullWidth}
             // onBlur={(val:any) => this.onRichTextBlue('content', val,true)}
              height={400}
            />
 

      </LIB.TabPanel> */}

    <LIB.TabPanel name="AI Script" selectedKey={selectedTab} tabBoxStyle={tabBoxStyle}
      >
       <TextField
            name="aiScript" 
            //label="Content"
            value={item.aiScript || ''}
            {...App.AppBase.getInputErrorProps('aiScript')}
            onChange={this.onInputChange.bind(this)}
            style={fullWidth}
            //placeholder="1 or 2 main skills, i.e .Net, React"
            inputProps={{ style: formInput }}
            rows={15}
            multiline
          /> 

      </LIB.TabPanel>


      <LIB.TabPanel name="Source" selectedKey={selectedTab} tabBoxStyle={tabBoxStyle}
      >
       <TextField
            name="content" 
            //label="Content"
            value={item.content || ''}
            {...App.AppBase.getInputErrorProps('content')}
            onChange={this.onInputChange.bind(this)}
            style={fullWidth}
            //placeholder="1 or 2 main skills, i.e .Net, React"
            inputProps={{ style: formInput }}
            rows={15}
            multiline
          /> 

      </LIB.TabPanel>

    

    </App.SmartTabs> 

      <div style={{textAlign:'right'}}>
    <Button
        color="primary"
        variant="contained"
        style={{ margin: 10 }}
        onClick={() => this.onAIGenerateContentClick()}
        disabled={vm.isActionInProgress}
      >Generate Content</Button>
      </div>
    </div>;



  }


  public getForm() {
    //console.log('ContentPagesDetailForm getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedContentPage;


    var theme = App.AppBase.theme;


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 10
    };
    var textInput: CSSProperties = {
      padding: 10,
      width: '100%'
    };

    var fullWidth: CSSProperties = {
      //paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var textFieldStyle: CSSProperties = {
      paddingTop: 0,
      minWidth: 200,
      marginBottom: 0

    };


    var item = vm.selectedContentPage;

    if (item === undefined)
      return null;

    //  var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasContentPageDataLoaded}
      loading={(state.isContentPageCreateUpdatePending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
      alignError='left'
    >
      <Stack direction={'column'} style={{ width: '100%' }}>


        <div >


          <TextField
            variant='standard'
            name="name" label="Name"
            value={item.name || ''}
            {...App.AppBase.getInputErrorProps('name')}
            onChange={this.onInputChange.bind(this)}
            style={fullWidth}
            inputProps={{ style: formInput }}
          />


          <div>

            <TextField
              name="title" label="Title"
              value={item.title || ''}
              {...App.AppBase.getInputErrorProps('title')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              //placeholder="1 or 2 main skills, i.e .Net, React"
              inputProps={{ style: formInput }}

            />

          </div>

          <div>
            {this.getContentPart()}
          </div>


          <Stack style={{ marginTop: 30 }}>
            <TextField
              name="urlPath" label="URL Path"
              //variant="outlined"
              //placeholder="i.e Full Stack .NET/React Developer"
              value={item.urlPath || ''}
              {...App.AppBase.getInputErrorProps('urlPath')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
            //inputProps={{ style: formInput }}

            />

          </Stack>



          <Grid container spacing={2} style={{ marginTop: 30 }}>

            <Grid item xs={12} sm={4} >


              <App.FormAutocomplete
                label="Site"
                name="contentSite"
                data={state.contentSites}
                labelProperty="name"
                valueProperty='contentSiteId'
                onChange={this.onAutocompleteChange.bind(this)}
                value={state.contentSites?.find(c => c.contentSiteId === item.contentSiteId) || ''}
                textFieldStyle={textFieldStyle}

              />
            </Grid>

            <Grid item xs={12} sm={4}  >


              <App.FormAutocomplete
                label="Content Type"
                name="contentType"
                data={state.contentTypes}
                labelProperty="name"
                valueProperty='contentTypeId'
                onChange={this.onAutocompleteChange.bind(this)}
                value={state.contentTypes?.find(c => c.contentTypeId === item.contentTypeId) || ''}
                textFieldStyle={textFieldStyle}

              />
            </Grid>

            <Grid item xs={12} sm={4}  >


              <App.FormAutocomplete
                label="Status"
                name="contentStatusType"
                data={state.contentStatusTypes}
                labelProperty="name"
                valueProperty='contentStatusTypeId'
                onChange={this.onAutocompleteChange.bind(this)}
                value={state.contentStatusTypes?.find(c => c.contentStatusTypeId === item.contentStatusTypeId) || ''}
                textFieldStyle={textFieldStyle}

              />
            </Grid>
          </Grid>


          {/*  <div>

            <FormControlLabel
              control={

                <Switch name="isPublished"
                  checked={item.isPublished}
                  onChange={this.onInputChange.bind(this)}

                  color="primary"
                />

              }
              style={{ marginLeft: 20 }}
              labelPlacement="start"
              label="Has Inspections"
            />



          </div>
 */}


        </div>


        <div style={{ marginTop: 50, textAlign: 'right' }}>
          {this.getButtons()}
        </div>


      </Stack>


    </App.MainPage >;


  }

  public getButtons() {


    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedContentPage;


    return <span >

      <Button
        variant="contained"
        color="inherit"
        style={{ margin: 10, marginLeft: 0 }}
        onClick={this.onCloseClick.bind(this)}
        disabled={vm.isActionInProgress}
      >Close</Button>


      <Button
        color="primary"
        variant="contained"
        style={{ margin: 10 }}
        //onClick={() => this.onAddOrUpdateClick(true)}
        onClick={() => this.onAddOrUpdateClick(false)}
        disabled={vm.isActionInProgress}
      >Save</Button>


    </span>

  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('ContentPagesDetailForm render');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedContentPage;

    if (!item)
      return null;

    var title = "Create A Page";
    var saveBtnTitle = "Save Draft"; //"Add";
    if (item.contentPageId!! > 0) {
      title = `Edit Page: ${item.name}  (ID: ${item.contentPageId})`;
      saveBtnTitle = "Save Draft";
    }

    var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return <div style={{ marginLeft: 10, maxWidth: 1000 }}>






      <h2 >{title} {statusTag}

        {/*  <span style={{ marginLeft: 200 }}>
          {this.getButtons()}

        </span> */}

      </h2>


      {this.getForm()}


    </div>;

  }
} 