import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { Table, Typography, NativeSelect, InputLabel, FormControl, Container, Button, Select, Backdrop, MenuItem, CircularProgress } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import { Tabs, Tab } from '@mui/material';
import * as Api from 'app-api';
import { AccountProfile } from './Profile/AccountProfile';
import { CompanyProfile } from './Company/CompanyProfile';
import { AccountSecurity } from './Security/AccountSecurity';
import { AccountBilling } from './Billing/AccountBilling';
import { CSSProperties } from "react";



//import { AccountForm } from './AccountForm';



class Account extends React.Component<App.AccountState, {}> {

  // public projectDataService: App.ProjectDataService;

  public validationManager: App.ValidationManager;


  constructor(props: any) {

    super(props);
    //  this.projectDataService = new App.ProjectDataService();
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Account componentDidMount');
    var vm = this.props.vm;


    if (!this.props.hasDataLoaded) {
      App.AppStore.dispatch(App.ActionTypes.ACCOUNT_GET_PAGE_DATA_REQUEST);
    }

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('Account componentDidUpdate');

  }




  public onTabChange(e: React.ChangeEvent<{}>, newValue: number) {
    console.log('onTabChange');

    //var project = this.props.project;

    // if (LIB.Common.isNullOrEmpty(project) || LIB.Common.isNullOrEmpty(project.aliasId))
    //   return;

    var id: string = (e.currentTarget as any).id;
    if (!LIB.Common.isNullOrEmpty(id))
      id = id.replace('Tab', '');

    var vm = this.props.vm;
    vm.selectedTabIndex = newValue;

    vm.selectedTab = id;
    //  App.AppBase.history.push(`Account?id=${project.aliasId}&tab=${vm.selectedTab}`);

    this.forceUpdate();
  }


  private getTabList(): string[] {

    var user = this.props.profile;

    var result: string[] = [];
    result.push('Profile');
    result.push('Company');
    result.push('Security');

    if(user.isManager)
    result.push("Billing");

    return result;

  }


  public onTabUpdate(){
    console.log('onTabUpdate');
    this.forceUpdate();
  }


  public getTabs() {
   
    var theme = App.AppBase.theme;
    var vm = this.props.vm;
    var state = this.props;

    console.log('Account getTabs selectedTab', vm.selectedTab);


    var selected = vm.selectedTabIndex;
    var theme = App.AppBase.theme;
    var tabList = this.getTabList();
    //var startUrl = `Account?&tab=`;
    var startUrl = `/Account/`;

    var user = state.profile;

 

    return <App.SmartTabs
      tabList={tabList}
      selectedTab={vm.selectedTab}
      startUrl={startUrl}
      forceUpdate={() => { this.onTabUpdate();}}
      onSelectedChange={(newTab: string) => {
        console.log("Account getTabs onSelectedChange newTab", newTab);
        vm.selectedTab = newTab;
      }}
    >
      <LIB.TabPanel name="Profile" selectedKey={vm.selectedTab} >
        <AccountProfile state={state} />
      </LIB.TabPanel>

      <LIB.TabPanel name="Company" selectedKey={vm.selectedTab}>
        <CompanyProfile state={state} />

      </LIB.TabPanel>

      <LIB.TabPanel name="Security" selectedKey={vm.selectedTab}>
      {/*   <h1>Security</h1> */}
        <AccountSecurity state={state} />
      </LIB.TabPanel>

      {user.isManager && 
      <LIB.TabPanel name="Billing" selectedKey={vm.selectedTab}>
        <AccountBilling state={state} />
      </LIB.TabPanel>  
      }

    </App.SmartTabs>;


  }

  public render() {
    console.log('Account render');

    var state = this.props;
    var vm = this.props.vm;

    return <App.MainPage
      pageTitle='Account Settings'
      state={vm}
      hasDataLoaded={state.hasDataLoaded}
    
      inProgress={vm.isActionInProgress}
      //showModalForLoading={true}
      showContentWhenInProgress={(!state.isServiceCallPending || vm.isActionInProgress)}
      alignError='left tab'
      showLeftMenu={true} 
    >

      <div>

        {this.getTabs()}

      </div>

    </App.MainPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.account })
)(Account as any);
