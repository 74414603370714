
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class JobListVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;

    public selectedJob: Api.Job;

    public selectedJobOriginal: Api.Job;

    public isSubmitCandidateOpen: boolean = false;


    constructor() {

        super();
    }

    public setSelectedJob(item: Api.Job | undefined = undefined) {

        if (item === undefined)
            item = new Api.Job();

        this.selectedJob = item;
        this.selectedJobOriginal = LIB.ObjectHelper.clone(this.selectedJob);
    }



}