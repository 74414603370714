import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Snackbar } from '@mui/material';
////import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { Checkbox, FormControlLabel, FormControl, FormHelperText, InputLabel } from '@mui/material';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import * as Quill from "quill";
import 'react-quill/dist/quill.snow.css';
import { createStyles, Theme } from '@mui/material/styles';
import { CSSProperties } from "react";


interface MRichTextEditorProps {
  error?: boolean,
  helperText: string;
  height?: number;
  label: string;
  name: string;
  value?: any;
  onChangeHtml?: Function;
  onChangeText?: Function;
  onChange?: Function;
  placeholder?: string;
  formControlStyle?: CSSProperties;
  onBlur?: Function;
  toolbarOptions?: any[];
  disableToolbar?: boolean;
  addTextLineBreaks?: boolean;
}

export class MRichTextEditor extends React.Component<MRichTextEditorProps, {}> {


  private reactQuillRef: ReactQuill | null;

  constructor(props: any) {
    super(props);
    //  this.state = { visible: false, isCancel:false };

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('componentDidUpdate:'+this.state.visible);


  }

  public onChange(value: string, delta: Quill.DeltaStatic | any, source: Quill.Sources | any, editor: UnprivilegedEditor) {
    var state = this.props;

   
    let newVal = this.getCleanHtml(editor, value);

    if(newVal==state.value)
     return;

   //  console.log('MRichTextEditor onChange value:',value);
   //  console.log('MRichTextEditor onChange state.value:',state.value);

     
    if (state.onChangeHtml) {
       state.onChangeHtml(state.name, newVal);
    }
 
    if (state.onChangeText) {
      let textVal = editor?.getText();

      if (state.addTextLineBreaks)
        textVal = this.getCleanText(textVal);

      state.onChangeText(state.name, textVal);
    }

    if (state.onChange) {
       state.onChange(state.name, newVal, editor);
    }



  }

  private getCleanText(val: string) {
    console.log('RichText getCleanText val:', val);

 
    return val;

    val = LIB.StringHelper.addHtmlLineBreaksToString(val);

    console.log('RichText getCleanText val2:', val);

    return val;
  }

  private getCleanHtml(editor: UnprivilegedEditor, val: string) {

   // val = val?.replace('<p><br><\/p>', '<br/>');
   // val = val?.replace(/<p><br><\/p>/g, '<br/>');

    //val = val?.replace(/<p>/g, '');
    //val = val?.replace(/<\/p>/g, '<br/>');

    var text = editor.getText();

    //console.log("MRichTextEditor getCleanHtml text", text);

    if(LIB.Common.isNullOrEmpty(text?.trim())){

      val = val?.replace(/<p><br><\/p>/g, '<br/>');

    }

   //console.log("MRichTextEditor getCleanHtml val", val);

    if (val == '<br/>')
      val = '';

    return val;
  }



  public onBlur(editor: UnprivilegedEditor) {

  //  console.log("MRichTextEditor onBlur editor", editor);

    var state = this.props;
    var value = editor.getHTML();
    this.onChange(value, null, null, editor);


    if (this.props.onBlur !== undefined) {
      let val2 = this.getCleanHtml(editor,value);
      this.props.onBlur(state.name, val2, editor);
    }



    //this.props.onBlur(this.props.name,val2,editor);


  }


  /*  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
     console.log('onInputChange', e.target.name, e.target.value);
 
     var state = this.props;
 
     var editor = this.reactQuillRef?.getEditor();
  
 
      console.log("adminBulkImportProspectsInput",  e.target.value, item, state.adminBulkImportProspectsSm)
     var target: any = e.target;
  
     this.forceUpdate();
   } */

  /* 
    public getHtmlView(){
  
      
      var state = this.props;
  
  
      return <div>
        
        <UI.TextField
                name="message" 
                label="Message"
                value={state.value || ''}
                onChange={this.onChange.bind(this)}
  
               
               // {...App.AppBase.getInputErrorProps('message')}
               //  style={fullWidth}
                multiline={true}
             //   inputProps={{ style: formInput }}
                minRows={3}
                maxRows={8}
              />
      </div>
  
    } */

  public render() {


    var state = this.props;

    var height: any = 200;
    if (!LIB.Common.isNullOrEmpty(state.height) && state.height !== undefined)
      height = state.height;

    var reactQ: any;

    // var toolbarOptions = [[]]; //[['bold', 'italic'], ['link', 'image']];
    var toolbarOptions = [ [ 'bold', 'italic' ], [ 'link', 'image', 'script' ], [ 'ql-html' ] ];

    var modules: any = {};

    if (this.props.disableToolbar) {
      modules.toolbar = [];
    }
    else if (this.props.toolbarOptions) {
      modules.toolbar = this.props.toolbarOptions;
    }
    else modules.toolbar = toolbarOptions

    console.log("MRichTextEditor toolbar", modules.toolbar);

    return (
      <FormControl style={state.formControlStyle} error={this.props.error} >

        <InputLabel style={{ marginTop: -50 }}>{state.label}</InputLabel>

        <ReactQuill theme="snow"
          ref={(el) => { this.reactQuillRef = el }}

          //id='my-input'
          modules={modules}
          value={state.value || ''}
          onChange={this.onChange.bind(this)}
          style={{ height: height }}
          placeholder={state.placeholder}

        /*   onBlur={(previousRange: Quill.RangeStatic, source: Quill.Sources,
            editor: UnprivilegedEditor
          ) => this.onBlur(editor)
          } */
        />

        <FormHelperText
          style={{ marginTop: 45 }}
        >{state.helperText}</FormHelperText>

      </FormControl>
    );



  }
} 
