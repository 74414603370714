import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { AccountBillingReducers } from './AccountBillingReducers';


export class AccountReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.AccountState | undefined, action: App.AppAction): App.AccountState {
        var str = "";

        var result = new App.AccountState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ACCOUNT") && !action.type.startsWith("USER"))
            return state;

        if (action.type.startsWith("ACCOUNT_BILLING"))
            return AccountBillingReducers.getReducers(state, action);

        var appState = action.appState;
        var globalState = appState.globalData;
      
        // console.log('GuestUser.getActionHandlersReducers', appState);

        var userService = new App.UserService();
        var accountService = new App.AccountService();
        // accountService.appState = appState;
        // accountService.accessToken = currentUser?.token;

        var hasError = false;

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.ACCOUNT_API_ERROR:
                console.log('ACCOUNT_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                let vm = result.vm;

                //App.AppBase.setApiError(action, appState);
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.apiErrorMessage = vm.apiErrorMessage;
                hasError = true;
                result.isServiceCallPending = false;
                vm.isActionInProgress = false;
                result.forceUpdateCount++;

                console.log('ACCOUNT_API_ERROR apiErrorMessage:', vm.apiErrorMessage);

                break;

            case App.ActionTypes.ACCOUNT_LOGOUT_REQUEST: {
                console.log('ACCOUNT_LOGOUT_REQUEST');
                console.log(action.payload);

                let  currentUser = App.AppBase.getCurrentUser(appState);
                console.log('ACCOUNT_LOGOUT_REQUEST, currentUser', currentUser);

                result = { ...state };
                let sm = action.payload;
                let vm = result.vm;
                //result.isServiceCallPending = true;

                accountService.logout()
                App.AuthManager.logoutClearSession();

                result.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.ACCOUNT_LOGOUT_SUCCESS: {
                console.log('ACCOUNT_LOGOUT_SUCCESS');
                // result = { ...state };
                result = state;
                let sm = action.payload;
                let vm = result.vm;
                result.isServiceCallPending = false;
 
                globalState.initialData?.currentUser?.reset();

                let  currentUser = App.AppBase.getCurrentUser(appState);
                console.log('ACCOUNT_LOGOUT_SUCCESS, currentUser', currentUser);

                globalState.forceUpdateCount++;
                result.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST: {
                console.log('ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST');

                let  currentUser = App.AppBase.getCurrentUser(appState);
                console.log('ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST, currentUser', currentUser);
                console.log(action.payload);
                result = { ...state };
                let sm = action.payload;
                let vm = result.vm;
                vm.hasResentConfirmEmail = false;
                result.isServiceCallPending = true;

                accountService.resendConfirmEmail()

                break;
            }
            case App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS: {


                console.log('ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS');
                console.log(action.payload);
                result = { ...state };
                let vm = result.vm;
                vm.hasResentConfirmEmail = true;

                result.isServiceCallPending = false;
                appState.globalData.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_TOKEN_REQUEST:
                console.log('ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS', action.payload);
                result = { ...state };

                var sm = action.payload;
                result.isServiceCallPending = true;
                accountService.confirmEmailToken(sm);


                break;
            case App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS: {
                console.log('ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS', action.payload);
                result = { ...state };
                let vm = result.confirmEmailVm;
                result.isServiceCallPending = false;
                var success = action.payload;

                if (success) {
                    vm.iconType = 'success';
                    vm.pageTitle = 'Successfully Confirmed';
                }
                else {
                    vm.iconType = 'error';
                    vm.pageTitle = 'Something went wrong or already confirmed!';
                }

                globalState.forceUpdateCount++;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR: {
                console.log('ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR', action);
                result = { ...state };
                App.AppBase.setApiError(action, appState);

                let vm = result.confirmEmailVm;
                vm.iconType = 'error';
                vm.pageTitle = appState?.globalData?.globalUIState?.apiErrorMessage;
                result.isServiceCallPending = false;

                break;
            }
            case App.ActionTypes.ACCOUNT_GET_PAGE_DATA_REQUEST: {
                console.log('ACCOUNT_GET_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };

                accountService.getAccountState();
                result.isServiceCallPending = true;
                result.forceUpdateCount++;

                result.forceUpdateCount++;
                result.isServiceCallPending = false;
                break;
            }
            case App.ActionTypes.ACCOUNT_GET_PAGE_DATA_SUCCESS: {
                console.log('ACCOUNT_GET_PAGE_DATA_SUCCESS:', action.payload);
                result = { ...state };
                result.profile.setData(action.payload.profile);
                result.profileOriginal.setData(result.profile);


                result.company = action.payload.company;

                console.log('ACCOUNT_GET_PAGE_DATA_SUCCESS company:',  result.company);

                result.companyOriginal = LIB.ObjectHelper.clone(result.company);


                result.avgBenchTalentCountTypes = action.payload.avgBenchTalentCountTypes;
                result.companySizeTypes = action.payload.companySizeTypes;
                result.companySizeRecruitersTypes = action.payload.companySizeRecruitersTypes;

                result.isServiceCallPending = false;
                result.hasDataLoaded = true;
                result.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.ACCOUNT_PROFILE_UPDATE_REQUEST: {
                console.log('ACCOUNT_PROFILE_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };

                var data = action.payload;

                accountService.updateProfile(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ACCOUNT_PROFILE_UPDATE_SUCCESS: {
                console.log('ACCOUNT_PROFILE_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                let data = action.payload;


                var currentUser = appState.globalData.initialData.currentUser;
                var current_isProfileDetailsComplete = currentUser.isProfileDetailsComplete;

                currentUser.isProfileDetailsComplete = data.isProfileDetailsComplete;
                result.profile.isProfileDetailsComplete = currentUser.isProfileDetailsComplete;

                currentUser.company.isCompanyDetailsComplete = data?.company?.isCompanyDetailsComplete;
                result.profile.company.isCompanyDetailsComplete = data?.company?.isCompanyDetailsComplete;

                currentUser.company.hasActiveBillingPlan = data?.company?.hasActiveBillingPlan;
                result.profile.company.hasActiveBillingPlan = data?.company?.hasActiveBillingPlan;

                console.log('ACCOUNT_PROFILE_UPDATE_SUCCESS currentUser:', currentUser);

                App.AuthManager.saveUserToLocalStorage(currentUser);


                result.forceUpdateCount++;
                App.AppBase.showSaveSuccess();

                if (!current_isProfileDetailsComplete && result.profile.isProfileDetailsComplete) {
                    console.log('ACCOUNT_PROFILE_UPDATE_SUCCESS redirect');
                    if (!currentUser?.company.isCompanyDetailsComplete)
                        App.RouteManager.routeToPath("/account/company");
                    else
                        App.RouteManager.routeToPath("/");
                }

                break;
            }
            case App.ActionTypes.ACCOUNT_COMPANY_UPDATE_REQUEST: {
                console.log('ACCOUNT_COMPANY_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };

                var data = action.payload;

                accountService.updateCompany(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ACCOUNT_COMPANY_UPDATE_SUCCESS: {
                console.log('ACCOUNT_COMPANY_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                var currentUser = appState.globalData.initialData.currentUser;

                //result.company =  action.payload;


                if (currentUser.company) {
                    var current_isCompanyDetailsComplete = currentUser.company.isCompanyDetailsComplete;
                    currentUser.company.isCompanyDetailsComplete = true;
                    App.AuthManager.saveUserToLocalStorage(currentUser);

                    if (!current_isCompanyDetailsComplete) {
                        console.log('ACCOUNT_COMPANY_UPDATE_SUCCESS redirect');
                        App.RouteManager.routeToPath("/");
                    }


                }

                App.AppBase.resetErrorMessages();
                App.AuthManager.saveUserToLocalStorage(currentUser);
                App.AppBase.showSaveSuccess();

                result.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.ACCOUNT_PASSWORD_CHANGE_REQUEST: {
                console.log('ACCOUNT_PASSWORD_CHANGE_REQUEST:', action.payload);
                result = state;//{ ...state };

                var data = action.payload;

                accountService.changePassword(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ACCOUNT_PASSWORD_CHANGE_SUCCESS: {
                console.log('ACCOUNT_PASSWORD_CHANGE_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                vm.accountPasswordChangeSm = new Api.AccountPasswordChangeSm();
                result.forceUpdateCount++;
                App.AppBase.showMessage("Password Successfully Updated");

                break;
            }
            default:
                result = state;
                //return result;
                break;
        }

        if (!hasError) {
            result.vm.apiErrorMessage = '';
            result.apiErrorMessage = ''
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        console.log('AccountReducers result:', result);


        return result;
    }



}