import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, FormControl, FormHelperText, InputLabel,Link
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';

interface SubmitCandidateModalProps {
  state: App.JobListState;
  onChange?: Function;
}


export class SubmitCandidateModal extends React.Component<SubmitCandidateModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('SubmitCandidateModal componentDidMount');

    //has candidate data loaded, get candidates by owner
    //   if (!this.props.state.hasCandidateDataLoaded) {
    console.log('SubmitCandidateModal get current user candidates');

    //{App.AppBase.isUserAuthenticatedAndEmailConfirmed &&

    if (App.AppBase.isUserAuthenticatedAndEmailConfirmed) {

      App.AppStore.dispatch(App.ActionTypes.JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST, null);
      this.forceUpdate();

    }



  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('SubmitCandidateModal componentDidUpdate');



  }


  public onSubmitClick(e: React.ChangeEvent<{}>) {
    console.log('onSubmitClick');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;
    var sm = state.submitCandidateSm;

    sm.jobPosterId = item.ownerId!;

    sm.jobId = item.jobId!;

    console.log('onSubmitClick ', item);

    var isValid = this.validationManager.validateSubmitCandidate(null, sm);

    if (!isValid) {
      console.log('validateSubmitCandidate NOT valid');
      this.forceUpdate();
      return;
    }


    if (vm.isSubmitCandidateOpen)
      App.AppStore.dispatch(App.ActionTypes.JOB_LIST_SUBMIT_CANDIDATE, sm);

    vm.isActionInProgress = true;

    App.RouteManager.routeToPath('?');

    this.forceUpdate();

  }

  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;

    //reset edits
    //App.JobManager.update(vm.selectedCandidateOriginal, state.jobs);

    vm.isSubmitCandidateOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';
    App.RouteManager.routeToPath('?');

    this.forceUpdate();

  }

  public onAutocompleteChange(name: string, value: any, item: any) {
    console.log("onAutocompleteChange name:", name);
    console.log("onAutocompleteChange value:", value);
    console.log("onAutocompleteChange item:", item);

    var state = this.props.state;
    var vm = this.props.state.vm;

    var selected = {
      name: name,
      value: value
    }

    this.validationManager.validateOwner(name, item);// vm);
    //vm.selectedJob.ownerId = item?.userId;
    var sm = state.submitCandidateSm;
    sm.candidateId = item.candidateId;
    console.log('sm:', sm);


    console.log('onAutocompleteChange:', selected);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.submitCandidateSm;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateSubmitCandidate(name, item);


    this.forceUpdate();
  }


  public getSubmitCandidateForm(){


    if (!App.AppBase.isUserAuthenticatedAndEmailConfirmed) 
      return null;

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;
    
    var sm = state.submitCandidateSm;
    
    var candidates = this.props.state.currentUserCandidates;

    var textFieldStyle: CSSProperties = {
      margin: 0 
    };

   return <Grid container spacing={2}>
          <Grid item xs={12} sm={8} >
            <h3 >Submit a Candidate:</h3>
            <App.FormAutocomplete label="Select a Candidate" name="candidate" data={candidates}
              onChange={this.onAutocompleteChange.bind(this)}
              labelProperty="name"
              labelRenderer={(candidate: Api.Candidate) => { 
                //console.log('FormAutocomplete labelRenderer',candidate);
                return `${candidate?.name} - ${candidate?.title} - (${candidate?.candidateId}) `; 
              }}
              value={candidates?.find(x => x.candidateId === sm.candidateId)}
              valueProperty="candidateId"
             // textFieldStyle={{ paddingTop: 20, marginBottom: 0, paddingBottom:0 }}
             style={{ paddingBottom:0 }}
            />

    <Link
    style={{marginTop:-20}}
    key='manageCandidatesLink'
    onClick={(e: any) => App.RouteManager.routeToPath('/MyAccount/Candidates')}
    color="primary">Manage Candidates</Link>
          </Grid>
          <Grid item xs={12} sm={6} >
 
      
   
          </Grid>
        </Grid>
  }

  public getForm() {
    console.log('SubmitCandidateModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedJob;

    var candidates = this.props.state.currentUserCandidates;

    console.log("selectedJob item modifiedByUserName", item?.modifiedByUserName);

    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    //var item = vm.selectedJob;



    if (item === undefined)
      return null;

    var sm = state.submitCandidateSm;

    var description = item.description!;


    var textStyle: CSSProperties = {
      fontSize: 17,
      //  padding: 20,
    };





    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasDataLoaded && state.hasCandidateDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >

        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} style={textStyle}>
            <b>Title: </b>{item.title}

          </Grid>
          <Grid item xs={12} sm={8} style={textStyle} >
            <b>Type: </b>{item.jobType?.name}

          </Grid>

        </Grid>

      
        <br />
        <b style={textStyle}>Description: </b>
        <div style={{
          marginBottom: '40px',
          marginLeft: '20px'
        }}>
          <div dangerouslySetInnerHTML={{ __html: item.description! }} style={{}} />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} >

            <b>Location: </b>{item.location}

          </Grid>
          <Grid item xs={12} sm={4} >

            <b>Visa Requirements: </b>{item.requiredVisaType?.name}

          </Grid>

          <Grid item xs={12} sm={4} >

            <b>Partnership Type: </b>{item.partnershipType?.name}

          </Grid>

        </Grid>
        <hr style={{ marginTop: 15 }} />


        {this.getSubmitCandidateForm()}


      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {

    console.log('SubmitCandidateModal render');


    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isSubmitCandidateOpen);
    var item = vm.selectedJob;


    var title = `Submit A Candidate`;


    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ width: '40vw', height: '55vh', minWidth: '300px' }}>

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>


            {App.AppBase.isUserAuthenticated &&
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={this.onSubmitClick.bind(this)}
                disabled={vm.isActionInProgress}
              >Submit</Button>

            }




            {!App.AppBase.isUserAuthenticated &&
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={(e) => App.RouteManager.routeToPath("/Login")}
              >Login to Continue</Button>

            }


          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 