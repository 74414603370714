import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { Table, Typography, Paper, Container, TextField, Box, Button, Grid, InputAdornment } from '@mui/material';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { Tabs, Tab } from "@mui/material";
import { CSSProperties } from "react";


import { createStyles, Theme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';



interface MSearchBarProps {
  filter: any;
  onSearchClick: Function;
  onValidate?: Function;
  children?: React.ReactNode;
  isSearchInProgress?: boolean;
  disabled?: boolean;
  btnLabel?:string;
}

export const MSearchBar: React.FC<MSearchBarProps> = (props) => {

  // var params = useParams();
  //var location = useLocation();
  //var locationState = location.state;
  // const [forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [ , updateState ] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    console.log("MSearchBar useEffect update");

    //checkSetSelected();
  });


  //var theme = App.AppBase.theme;
  const classes: any = {
    root: {
      // backgroundColor: "white",
      // display: "flex",

    },

  };


  var fullWidth: CSSProperties = {
    // paddingRight: 20,
    paddingLeft: 0,
    //  marginBottom: 10,
    //  padding: 10,

    width: '30vw',
    minWidth: '350px'
  };
  var formInput: CSSProperties = {
    height: 50,
    //  marginTop: 10,
    //paddingTop: 20,
    padding: 10,
  };

  var sm = props.filter;

  // var theme = App.AppBase.theme;





  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {


    // console.log('onSearchChange:',  e.target.value);


    sm.keyword = e.target.value;

    //console.log('onSearchChange', sm);


    if (props.onValidate !== undefined)
      props.onValidate();
    //this.validationManager.validateJobCreateUpdate(name, item);

    forceUpdate();
  }

  function onSearchClick() {
    // console.log("MSearchBar onSearchClick, sm: ",sm);
    if (props.isSearchInProgress)
      return;

    props.onSearchClick();
  }

  function getFilterBtn() {

    var btnLabel = 'Search';

    var startIcon: any = undefined;
    if (props.isSearchInProgress) {
      startIcon = <UI.CircularProgress style={{ color: 'white' }} size="1rem" />;
    }
    
    if(!LIB.Common.isNullOrEmpty(props.btnLabel))
          btnLabel = props.btnLabel!;

    return <Button
      id="btnCreate"
      variant="contained"
      //style={{padding:10}}
      color="primary"
      onClick={() => onSearchClick()}
      //  onClick={this.onCreateClick.bind(this)}
      disabled={props.disabled}
      startIcon={startIcon}


    >{btnLabel}</Button>;
  }

 
  var placeholderText = "Search";

  if (!App.AppBase.isUserAuthenticated)
    placeholderText = "Login to access Search feature!"

  



  return (
    <div style={{ marginBottom: 30, marginLeft: 5 }}>
      <TextField
        name="keyword"
        //label="Search"
        value={sm.keyword || ''}
        placeholder={placeholderText}
        {...App.AppBase.getInputErrorProps('keyword')}
        onChange={(e: any) => onSearchChange(e)}
        style={fullWidth}
        inputProps={{ style: formInput }}
        disabled={props.disabled}
        //variant="outlined" 
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            onSearchClick();
          }
        }}
        InputProps={{
          // className: 'standardFormInput30',
          startAdornment: (
            <InputAdornment position="start">
              <Icons.Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
                {getFilterBtn()}
            </InputAdornment>
          ),
        }}

      />

      {props.children}

    </div>
  );


}

