//https://v4.mui.com/components/tree-view/#tree-view

import React from 'react';
import { Theme, createStyles } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';

import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import AdminIcon from '@mui/icons-material/PeopleAlt';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button, Box } from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import * as App from 'AppReferences';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import { CSSProperties } from "react";

import * as LIB from '_LIB';
import * as Api from 'app-api';

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}



export default function CMSTreeMenu() {
    //const classes = useStyles();

    return (
        <TreeView
            //  className={classes.root}
            defaultExpanded={[ '3' ]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
        //  defaultEndIcon={<div style={{ width: 24 }} />}
        >

            <LIB.StyledTreeItem nodeId="101" labelText="CMS" labelIcon={Icons.Newspaper}>
                <ListItem
                    key="CMSPages"
                    component={RouterLink}
                    to="/AdminCMS/Pages"

                >
                    <LIB.StyledTreeItem
                        nodeId="102"
                        labelText="Pages"
                        labelIcon={Icons.People}

                    />
                </ListItem>

                <ListItem
                    key="CMSPageBlocks"
                    component={RouterLink}
                    to="/AdminCMS/PageBlocks"

                >
                    <LIB.StyledTreeItem
                        nodeId="103"
                        labelText="Page Blocks"
                        labelIcon={Icons.People}

                    />
                </ListItem>


                <ListItem
                    key="CMSCategories"
                    component={RouterLink}
                    to="/AdminCMS/Categories"

                >


                    <LIB.StyledTreeItem
                        nodeId="104"
                        labelText="Categories"
                        labelIcon={Icons.EmailOutlined}
                        color="#a250f5"
                        bgColor="#f3e8fd"
                    />
                </ListItem>

                <ListItem
                    key="CMSSettings"
                    component={RouterLink}
                    to="/AdminCMS/Settings"

                >


                    <LIB.StyledTreeItem
                        nodeId="105"
                        labelText="Settings"
                        labelIcon={Icons.Settings}
                        color="#3c8039"
                        bgColor="#e6f4ea"
                    />
                </ListItem>
                
            </LIB.StyledTreeItem>
        </TreeView>
    );
}
