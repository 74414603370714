import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Alert,Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { MyAccountResumeRequestsTable } from './MyAccountResumeRequestsTable';
 


class MyAccountResumeRequests extends React.Component<App.MyAccountState, {}> {

 
  constructor(props: any) {
    super(props);
    
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountResumeRequests componentDidMount');
 
    App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_GET_RESUME_REQUESTS_REQUEST);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('MyAccountResumeRequests componentDidUpdate');

  }
 


  public render() {

    console.log('MyAccountResumeRequests render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
  

    return <App.MainPage
      pageTitle="My Resume Requests"
      state={vm}
      hasDataLoaded={state.hasResumeRequestsLoaded}
      loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
     // isChildPage={true}
     showLeftMenu={true} 
    >
      <div>
 

      <Alert severity="info" style={{marginBottom:10, maxWidth:1100}}>
      Note: All of your recent Resume Requests will be listed here. 
      You should see the Resume link for the candidates that already have Resumes attached. <br/>
      Click the Resume icon to view the Resume of the candidates. 
        </Alert>  

        <MyAccountResumeRequestsTable state={state} onChange={() => this.forceUpdate()} />


 
      </div>

 

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.myAccountState })
)(MyAccountResumeRequests as any);

