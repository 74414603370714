import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class AccountService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

    public resendConfirmEmail() {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Account/resendConfirmEmail`;
 
        this.get(apiUrl, 
            App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR 
            );

    }
    public confirmEmailToken(data:Api.ConfirmEmailSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/confirmEmailToken`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS,
            App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR 
            );

    } 

    

    public getAccountState() {
        console.log("AccountService.getAccountState");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Account/getAccountState`;
        this.get(apiUrl, 
            App.ActionTypes.ACCOUNT_GET_PAGE_DATA_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR
            );
    
    }


    public updateProfile(data:Api.AccountUpdateProfileSm) {
        console.log("AccountService.updateProfile");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Account/updateProfile`;
        this.post(apiUrl, data, 
            App.ActionTypes.ACCOUNT_PROFILE_UPDATE_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR
            );
    
    }

    public updateCompany(data:Api.AccountUpdateCompanySm) {
        console.log("AccountService.updateCompany", data);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Account/updateCompany`;
        this.post(apiUrl, data, 
            App.ActionTypes.ACCOUNT_COMPANY_UPDATE_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR
            );
    
    }

    public changePassword(data:Api.AccountPasswordChangeSm) {
        console.log("AccountService.changePassword");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Account/changePassword`;
        this.post(apiUrl, data, 
            App.ActionTypes.ACCOUNT_PASSWORD_CHANGE_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR
            );
    
    }

    public logout() {
        console.log("AccountService.logout");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/logout`;
        this.get(apiUrl, 
            App.ActionTypes.ACCOUNT_LOGOUT_SUCCESS,
            App.ActionTypes.ACCOUNT_API_ERROR
            );
    
    }

    
    
/* 
    public confirmEmail(data: Api.ConfirmFormSm, onSuccess: Function, onError: Function = this.onErrorDefault) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/confirmEmail`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_SUCCESS,
            App.ActionTypes.GUESTUSER_API_ERROR 
            );

    } */
 
}
