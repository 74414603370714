import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class MyAccountJobsReducers {


    constructor() {

    }

    public static getReducers(state: App.MyAccountState | undefined, action: App.AppAction): App.MyAccountState {
        var str = "";

        var result = new App.MyAccountState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("MY_ACCOUNT_JOB"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var myAccountService = new App.MyAccountService();
         

        // const action = incomingAction as KnownAction;
        switch (action.type) {
 
            case App.ActionTypes.MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED: {
                console.log('MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED:', action.payload);
                result = { ...state };
                result.jobs = action.payload.jobs;
                result.jobStatusTypes = action.payload.jobStatusTypes;
                result.jobTypes = action.payload.jobTypes;
                result.partnershipTypes = action.payload.partnershipTypes;
                result.candidateVisaTypes = action.payload.candidateVisaTypes;
                result.forceUpdateCount++;
                result.hasJobsDataLoaded = true;
                result.isServiceCallPending = false;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_JOB_ADD_REQUEST: {
                console.log('MY_ACCOUNT_JOB_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                myAccountService.createJob(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_JOB_ADD_SUCCESS: {
                console.log('MY_ACCOUNT_JOB_ADD_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };

                result.jobs.splice(0, 0, item); //push at the beginning
               // result.jobs.push(item);
                vm.isJobsAddOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedJob();
                  
               //console.log('MY_ACCOUNT_JOB_ADD_REQUEST jobs:', result.jobs);
               result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_JOB_UPDATE_REQUEST: {
                console.log('MY_ACCOUNT_JOB_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                myAccountService.updateJob(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_JOB_UPDATE_SUCCESS: {
                console.log('MY_ACCOUNT_JOB_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               
                console.log('MY_ACCOUNT_JOB_UPDATE_SUCCESS jobs:', result.jobs);

                App.JobManager.update(item,result.jobs);
                
                vm.isJobsEditOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedJob(item);
                  
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_JOB_DELETE_REQUEST: {
                console.log('MY_ACCOUNT_JOB_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                myAccountService.deleteJob(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_JOB_DELETE_SUCCESS: {
                console.log('MY_ACCOUNT_JOB_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedJob?.jobId;
                var success = action.payload;
 
                if(success)
                    App.JobManager.deleteById(itemId,result.jobs);
                
                vm.isJobsDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedJob();
                  
                result.forceUpdateCount++;
                break;
            }   

            
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}