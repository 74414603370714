import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ContentPagesManager extends LIB.BaseManager{


  constructor() {
    super('contentPageId');
  }
  
  public static deleteById(contentPageId: number, data: Api.ContentPage[]): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.contentPageId === contentPageId);
    data.splice(index, 1);
    return true;
  } 

  public static getById(contentPageId: number, data: Api.ContentPage[]) {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(contentPageId) )
      return null;

    var found = data.find((item) => item.contentPageId === contentPageId);
    
    
    if(LIB.Common.isNullOrEmpty(found))
      return null;
 
    return found;
  }
 
  public static update(item: Api.ContentPage, data: Api.ContentPage[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.contentPageId == undefined || item?.contentPageId < 1)
      return false;

    let index = data.findIndex((c) => c.contentPageId === item.contentPageId);
    
    if (index < 0)
      return false;
      
   /*  console.log('update data',data);
    console.log('update index',index);
    console.log('update item',item);
    console.log('update data[index]',data[index]); */
   

    data[index] =  item;

    return true;
  }
 

  public static updateContent(item: Api.ContentPage, data: Api.ContentPage[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.contentPageId == undefined || item?.contentPageId < 1)
      return false;

    let index = data.findIndex((c) => c.contentPageId === item.contentPageId);
    
    if (index < 0)
      return false;
       
    data[index].content =  item?.content;

    return true;
  }
}