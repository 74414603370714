import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';
////import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as Icons from '@mui/icons-material/';

interface AdministrationEmailTemplatesTableProps {
  state: App.AdministrationState;
  onChange?: Function;

}

export class AdministrationEmailTemplatesTable extends React.PureComponent<AdministrationEmailTemplatesTableProps> {

  public emailTemplateService: App.EmailTemplateService;

  constructor(props: any) {
    super(props);
    this.emailTemplateService = new App.EmailTemplateService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationEmailTemplatesTable componentDidMount');


  }

  public componentWillUnmount() {
    console.log("componentWillUnmount");
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET,null);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }

  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props.state;
    var vm = state.vm;

    // vm.setSelectedUserCreate(); 

    vm.isEmailTemplateCreateOpen = true;
    vm.isEmailTemplateTableOpen = false;
    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();
  }

  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, id: any, rowIndex: number) {

    var vm = this.props.state.vm;

    console.log("onActionMenuItemClick id", id);
    this.setSelected(id);

    console.log('setSelected onActionMenuItemClick 1: ', key);

    if (key == "Edit") {
      vm.isEmailTemplateEditOpen = true;
      vm.isEmailTemplateTableOpen = false;
    }
    else if (key == "Delete") {
      vm.isEmailTemplateDeleteOpen = true;
    }


    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();

  }


  public setSelected(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var found = state.emailTemplates.find(x => x.emailTemplateId === id);

    vm.setSelectedEmailTemplate(found);
  }

  public getActionMenuItems(id: any, tableMeta: any, updateValue: any): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    var emailTemplates = state.emailTemplates;



    var result = [

      <MenuItem key="Edit"
        onClick={(e) => this.onActionMenuItemClick(e, 'Edit', id, tableMeta.rowIndex)}
      >Edit</MenuItem>,
      <MenuItem key="Delete"
        onClick={(e) => this.onActionMenuItemClick(e, 'Delete', id, tableMeta.rowIndex)}
      >Delete</MenuItem>,


    ];

    return result;


  }

  public getTableColumns() {

    var pkColumn = LIB.MUITableHelper.getColumnSetup("ID", "emailTemplateId", false);
    // pkColumn.options.display = 'excluded';
    pkColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      <div>
        <Link
          onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', value, tableMeta.rowIndex)}
          color="primary">{value}</Link>
      </div>

    );

    var actionsColumn = LIB.MUITableHelper.getColumnSetup("Actions", "emailTemplateId", false);
    actionsColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      LIB.MUITableHelper.getActionsButton(value, tableMeta, updateValue,
        this.getActionMenuItems(value, tableMeta, updateValue)
      )
    );

    var dateCreatedCol = LIB.MUITableHelper.getColumnSetupDateTime("Created", "dateCreated");
    var dateModifiedCol = LIB.MUITableHelper.getColumnSetupDateTime("Modified", "dateModified");
    //var datePublishedCol = LIB.MUITableHelper.getColumnSetupDateTime("Published", "datePublished");

    //console.log(": ", this.props.state?.emailTemplates[0]?.isActive);

    
    var nameColumn = LIB.MUITableHelper.getColumnSetup("Name", "name", false);
    nameColumn.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {
      var id = tableMeta.rowData[0];
     // var item = this.props.state?.candidates?.find(x => x.candidateId === id);
  
      return <div>
      <Link
        onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', id, tableMeta.rowIndex)}
        color="primary">{value}</Link>
    </div>
    };



    var columns = [
      pkColumn,
      nameColumn,
      LIB.MUITableHelper.getColumnSetup("From Name", "fromName"),
      //    LIB.MUITableHelper.getObjectChildPropertyColumn("Company", "company", "companyName"),
      LIB.MUITableHelper.getColumnSetup("From Email", "fromEmail"),
      //    dateCreatedCol,
      dateModifiedCol,
      LIB.MUITableHelper.getCustomRenderValueColumn("Is Active", "isActive", (val: boolean) => String(val)),
      actionsColumn,
    ];


    return columns;
  }

  public onRowSelectionChange(currentRowsSelected: any[], allRowsSelected: any[], rowsSelected: any[]) {

    console.log('onRowSelectionChange');
    var vm = this.props.state.vm;
    var state = this.props.state;

    var dataIndex = currentRowsSelected[0].dataIndex;

    var item = state.emailTemplates[dataIndex];

    //  console.log('onRowSelectionChange item', item);

    vm.setSelectedEmailTemplate(item);

    console.log('onRowSelectionChange selectedEmailTemplate', vm.selectedEmailTemplate);


  }

  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    // vm.selectedRowIndex = rowMeta.rowIndex;
    this.forceUpdate();

  }

  public onSearchClick() {
    var state = this.props.state;
    var vm = state.vm;
    var searchFilter = state.emailTemplateSearchFilter;

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST, searchFilter);

  }


  public render() {
    console.log('AdministrationEmailTemplatesTable render');
    var state = this.props.state;
    var vm = state.vm;
    var searchFilter = state.emailTemplateSearchFilter;
    var isOpen = vm.isEmailTemplateTableOpen;

    if (!isOpen)
      return;

    if (LIB.Common.isNullOrEmpty(state) || !state.hasEmailTemplateDataLoaded)
      return <LIB.Loading />;

    var rows = state.emailTemplates;

    //@ts-ignore
    const oldRender = TableCell.render

    //@ts-ignore
    TableCell.render = function (...args) {
      const [props, ...otherArgs] = args
      if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
      } else {
        return oldRender.apply(this, args);
      }
    }


    const options: any = {
      filterType: 'dropdown',
      download: false,
      print: false,
      filter:false,
      viewColumns:false,
      selectableRows: 'single',
      onRowClick: this.onRowClick.bind(this),

      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: true,
      // rowsSelected:[vm.selectedRowIndex],
      selectToolbarPlacement: 'none',
      onRowSelectionChange: this.onRowSelectionChange.bind(this),
      search: false
      //,viewColumns:false

    };

    var createBtn = <Button
      id="btnCreate"
      variant="contained"
      style={{ marginBottom: 10 }}
      color="primary"
      onClick={() => this.onCreateClick()}
    >Create</Button>

    return <div>
      <h1>Administration {'>'} Email Templates</h1>
      {<LIB.MSearchBar
        filter={searchFilter}
        onSearchClick={() => this.onSearchClick()}
        isSearchInProgress={state.isServiceCallPending}
      />}

      <div style={{ textAlign: 'right', width: '100%' }} >

        {createBtn}

      </div>

      <MUIDataTable
        title={""}
        data={rows}
        columns={this.getTableColumns()}
        options={options}

      />



    </div>
  }
};

