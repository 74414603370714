
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class MyAccountVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;

    public isCandidatesAddOpen: boolean = false;
    public isCandidatesEditOpen: boolean = false;
    public isCandidatesDeleteOpen: boolean = false;
    //public apiCandidatesAddEditErrorMessage: string;
 
   
    public isJobsAddOpen: boolean = false;
    public isJobsEditOpen: boolean = false;
    public isJobsDeleteOpen: boolean = false;

    public selectedJob: Api.Job;
    public selectedCandidate: Api.Candidate;

    public selectedJobOriginal: Api.Job;
    public selectedCandidateOriginal: Api.Candidate;




    constructor() {

        super();
    }

    public setSelectedCandidate(item: Api.Candidate | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.Candidate();

            //console.log('setSelectedCandidate', item);

        this.selectedCandidate = item;
        this.selectedCandidateOriginal = LIB.ObjectHelper.clone(this.selectedCandidate);

    }

    public setSelectedJob(item: Api.Job | undefined=undefined) {

        if (item === undefined)
            item = new Api.Job();

         // console.log('setSelectedJob', item);
            
        this.selectedJob = item;
        this.selectedJobOriginal = LIB.ObjectHelper.clone(this.selectedJob);
    }



}