import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box,Link, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { CandidateListTable } from './CandidateListTable';
import { RequestResumeModal } from './RequestResumeModal';
import { ViewCandidateModal } from './ViewCandidateModal';



class CandidateList extends React.Component<App.CandidateListState, {}> {

  public candidateService: App.CandidateService;

  constructor(props: any) {
    super(props);
    this.candidateService = new App.CandidateService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('CandidateList componentDidMount');

    if (!this.props.hasDataLoaded) {
      console.log('CandidateList getCandidateListData');

      var requestResumeId = App.RouteManager.getRouterQueryParam('requestResumeId');
   
      var filter = new Api.CandidateFilterVm();
      
      if(!LIB.Common.isNullOrEmpty(requestResumeId)){
        filter.requestResumeId = requestResumeId;
      }
 
      this.candidateService.getCandidateListData(filter,
        (result:any) => {
          console.log('getCandidateListData success...');
          this.checkShowRequestResume(result,requestResumeId);
      });
 
      this.forceUpdate();
    }

     
   // this.candidateService.showSuccessMessage();
  }

  public componentWillUnmount() {
    console.log("componentWillUnmount");
   // App.AppStore.dispatch(App.ActionTypes.CANDIDATE_LIST_PAGE_RESET,null);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('CandidateList componentDidUpdate');
 
 
  }

  public checkShowRequestResume(state:App.CandidateListState, requestResumeId:any){
 
   // var state = this.props;
    console.log('checkShowRequestResume state', state);

    
   // console.log('checkShowRequestResume requestResumeId',requestResumeId);

    if(LIB.Common.isNullOrEmpty(requestResumeId))
      return;

       
      var vm = state.vm;
      var candidates = state.candidates;

     // console.log('checkShowRequestResume candidates',candidates);

      var item = candidates?.find(x => x.candidateId == requestResumeId);

      //console.log('checkShowRequestResume item',item);


      if(item==undefined)
          return;
 
        vm.isRequestResumeOpen = true;
        vm.setSelectedCandidate(item);
       // App.RouteManager.routeToPath('?');

     //   this.forceUpdate();
       
 
  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  } 
   

  public getTopRow(){

    var fullWidth: CSSProperties = {
     // paddingRight: 20,
      paddingLeft: 0,
     //  marginBottom: 10,
   //  padding: 10,
    
      width: '30vw'
    };
    var formInput: CSSProperties = {
      height: 50,
    //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
    };

    return <Grid container spacing={2} style={{marginBottom:10}}>
    <Grid item xs={12} sm={6} >
      
      <TextField
        name="keyword" 
        //label="Search"
        //value={item.location || ''}
        placeholder='Search'
        {...App.AppBase.getInputErrorProps('keyword')}
        onChange={this.onInputChange.bind(this)}
        style={fullWidth}
        inputProps={{ style: formInput }}
        //variant="outlined"
        
        InputProps={{
         // className: 'standardFormInput30',
          startAdornment: (
            <InputAdornment position="start">
              <Icons.Search />
            </InputAdornment>
          ),
            endAdornment: (
            <InputAdornment position="end">
            <Button
              id="btnCreate"
               variant="contained"
              //style={{padding:10}}
              color="primary"
              
            //  onClick={this.onCreateClick.bind(this)}
            >Search</Button>
            </InputAdornment>
          ), 
        }}

      />

       {/*   <Button
              id="btnCreate"
              variant="contained"
              style={{marginTop:13, marginLeft:10}}
              color="primary"
              
            //  onClick={this.onCreateClick.bind(this)}
            >Search</Button>  */}
 
    </Grid>
 
    <Grid item xs={12} sm={4} >

 


    </Grid>

  </Grid>

  }

  public onSearchClick(){
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.searchFilter;
 
    //this.candidateService.getCandidateListData(searchFilter);

    App.AppStore.dispatch(App.ActionTypes.CANDIDATE_LIST_PAGE_DATA_REQUEST,searchFilter);

  }
 


  public getSubmitCandidatesLink(){

    if(!App.AppBase.isUserAuthenticatedAndEmailConfirmed)
      return null;

    return <div  className='submitLinkBtn'>
    
    <Link
    key='submitCandidatesLink'
    onClick={(e: any) => App.RouteManager.routeToPath('/MyAccount/Candidates')}
    color="primary">SUBMIT CANDIDATES</Link>
    </div>
  }

  public render() {

    console.log('CandidateList render');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.searchFilter;
 
    console.log('CandidateList hasDataLoaded', state.hasDataLoaded);
    console.log('CandidateList isServiceCallPending',this.candidateService.isServiceCallPending);
    console.log('CandidateList vm.isActionInProgress',vm.isActionInProgress);
 
    var keyword = '';

    var loginWarning = App.AppBase.getLoginToForFullAccessWarning("You're only viewing first 50 record.");


     
   // if(App.AppBase.currentUser.hasRole(App.UserRoleTypeEnum.Vendor))

   
    return <App.MainPage
      pageTitle="Candidates"
      state={vm} 
      hasDataLoaded={state.hasDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      alignError='left'
      showLeftMenu={true} 
    >
      <div>

        {<LIB.MSearchBar filter={searchFilter} 
        onSearchClick={()=>this.onSearchClick()}
        isSearchInProgress={state.isServiceCallPending}
        disabled={!App.AppBase.isUserAuthenticatedAndEmailConfirmed}
         />}

         {loginWarning}

      {/*   <Button
               variant="contained"
              color="primary"
              onClick={()=>this.onTest()}
            >Test</Button> */}

        {/* {this.getTopRow()}
 
        <div style={{ textAlign: 'right', width: '100%'  }} >

            <Button
              id="btnCreate"
              variant="contained"
              style={{marginBottom:10}}
              color="primary"
              
              onClick={this.onCreateClick.bind(this)}
            >New Request</Button>
 
        </div>
 */}

        {this.getSubmitCandidatesLink()}
        <CandidateListTable state={state} onChange={() => this.forceUpdate()}/>

        <RequestResumeModal state={state} onChange={() => this.forceUpdate()} />

        <ViewCandidateModal state={state} onChange={() => this.forceUpdate()} />

        {loginWarning}
      </div>

      

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.candidateListState })
)(CandidateList as any);

