import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { MyAccountCandidateListTable } from './MyAccountCandidateListTable';
import { MyAccountCandidatesAddEditModal } from './MyAccountCandidatesAddEditModal';



class MyAccountCandidateList extends React.Component<App.MyAccountState, {}> {

  public myAccountService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myAccountService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountCandidateList componentDidMount');

    if (!this.props.hasCandidateDataLoaded) {
      console.log('MyAccountCandidateList getMyAccountCandidateListData');
      this.myAccountService.getCandidateListData();

      this.forceUpdate();
    }


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('MyAccountCandidateList componentDidUpdate');

  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;


    vm.setSelectedCandidate();

    vm.isCandidatesAddOpen = true;
    this.forceUpdate();


  }

  
  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');
 
    var state = this.props;
    var vm = state.vm;
  
    var itemId = vm.selectedCandidate?.candidateId;

    console.log('onDeleteConfirmClick itemId',itemId);

    App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_CANDIDATE_DELETE_REQUEST,itemId);
  
    vm.isActionInProgress=true;

    this.forceUpdate();
 
  }


  public render() {

    console.log('MyAccountCandidateList render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;

    // console.log('MyAccountCandidateList hasCandidateDataLoaded', state.hasCandidateDataLoaded);
    // console.log('MyAccountCandidateList isServiceCallPending',this.myAccountService.isServiceCallPending);
    // console.log('MyAccountCandidateList vm.isActionInProgress',vm.isActionInProgress);


    var createBtn: any = null;

    if (currentUser.hasRole(App.UserRoleTypeEnum.BenchRecruiter)) {
      createBtn = <Button
                  id="btnCreate"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                  color="primary"

                  onClick={() => this.onCreateClick()}
                >Create</Button>
    }


    return <App.MainPage
      pageTitle="My Candidates"
      state={vm}
      hasDataLoaded={state.hasCandidateDataLoaded}
      loading={(state.isServiceCallPending || this.myAccountService.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
     // isChildPage={true}
     showLeftMenu={true} 
    >
      <div>

        <div style={{ textAlign: 'right', width: '100%' }} >

          {createBtn}

        </div>

        <MyAccountCandidateListTable state={state} onChange={() => this.forceUpdate()} />



        <MyAccountCandidatesAddEditModal state={state} onChange={() => this.forceUpdate()} />


      </div>



      <LIB.ConfirmModal 
    visible={vm.isCandidatesDeleteOpen}
    onCancelClick={ ()=>{vm.isCandidatesDeleteOpen=false;this.forceUpdate();}}
    onConfirmClick={()=>this.onDeleteConfirmClick()}
    title={`Confirm Delete: ${vm.selectedCandidate?.name}`}
    content={`Are you sure you want to delete "${vm.selectedCandidate?.name}" candidate?`}
    confirmButtonLabel="Delete" 
    disabled={vm.isActionInProgress}
    />
 

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.myAccountState })
)(MyAccountCandidateList as any);

