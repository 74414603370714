import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class CMSPageReducers {


    constructor() {

    }

    public static getReducers(state: App.CMSState | undefined, action: App.AppAction): App.CMSState {
        var str = "";

        var result = new App.CMSState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("CMS_CONTENT_PAGES"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var cmsContentService = new App.CmsContentService();
        var attachmentService = new App.AttachmentService();
    

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            
            case App.ActionTypes.CMS_CONTENT_PAGES_LIST_DATA_REQUEST: {
                console.log('CMS_CONTENT_PAGES_LIST_DATA_REQUEST:', action.payload);
                result =  { ...state };
                result.apiErrorMessage='';
               

                var searchFilter = action.payload;

                cmsContentService.getContentPageListData(searchFilter);


                result.isServiceCallPending = true;
                result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.CMS_CONTENT_PAGES_LIST_DATA_RECEIVED: {
                console.log('CMS_CONTENT_PAGES_LIST_DATA_RECEIVED:', action.payload);
                result = { ...state };


                //result.contentPages = action.payload.contentPages;

                LIB.ObjectHelper.setData(action.payload, result,'vm');
 
                console.log('CMS_CONTENT_PAGES_LIST_DATA_RECEIVED result:',result);
                
                let vm = result.vm;


                if(result.contentPages.length==1){
                    vm.selectedContentPage=result.contentPages[0];
                }
                else{
                    result.hasContentPageDataLoaded = true;
                }
                result.hasContentPageDataLoaded = true;

                result.isServiceCallPending = false;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.CMS_CONTENT_PAGES_ADD_REQUEST: {
                console.log('CMS_CONTENT_PAGES_ADD_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;

                vm.setSelectedContentPage(data);
                cmsContentService.createContentPage(data);

                result.isContentPageCreateUpdatePending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CMS_CONTENT_PAGES_ADD_SUCCESS: {
                console.log('CMS_CONTENT_PAGES_ADD_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };

                result.contentPages.splice(0, 0, item); //push at the beginning
                
               // result.contentPages.push(item);
                App.AppBase.showSaveSuccess();
            
                App.RouteManager.routeToPath('/AdminCMS/Pages');

                vm.isContentPagesAddOpen=false;
                vm.isActionInProgress=false;
                vm.apiErrorMessage='';
                result.isContentPageCreateUpdatePending = false;
               // item.resume = vm.selectedContentPage?.resume;
                vm.setSelectedContentPage(item);
                  
               //console.log('CMS_CONTENT_PAGES_ADD_REQUEST contentPages:', result.contentPages);
               result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CMS_CONTENT_PAGES_UPDATE_REQUEST: {
                console.log('CMS_CONTENT_PAGES_UPDATE_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                cmsContentService.updateContentPage(data);

                 
                result.isContentPageCreateUpdatePending = true;
                result.forceUpdateCount++;
                console.log('CMS_CONTENT_PAGES_UPDATE_REQUEST isContentPageCreateUpdatePending:',result.isContentPageCreateUpdatePending);
                break;
            }   
            case App.ActionTypes.CMS_CONTENT_PAGES_UPDATE_SUCCESS: {
                console.log('CMS_CONTENT_PAGES_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               

                  
                var updated =  App.ContentPagesManager.update(item,result.contentPages);
            
                vm.isContentPagesEditOpen=false;


                vm.isActionInProgress=false;
                vm.apiErrorMessage='';
                result.isContentPageCreateUpdatePending = false;
                vm.setSelectedContentPage(item);
                  
                App.AppBase.showSaveSuccess();
              
                
               // console.log('CMS_CONTENT_PAGES_UPDATE_SUCCESS vm:',vm);


                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.CMS_CONTENT_PAGES_DELETE_REQUEST: {
                console.log('CMS_CONTENT_PAGES_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                cmsContentService.deleteContentPage(itemId);
                result.isContentPageCreateUpdatePending = true;
                result.forceUpdateCount++;
                break;
            }   
            
            case App.ActionTypes.CMS_CONTENT_PAGES_DELETE_SUCCESS: {
                console.log('CMS_CONTENT_PAGES_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedContentPage?.contentPageId;
                var success = action.payload;
 
                if(success)
                    App.ContentPagesManager.deleteById(itemId,result.contentPages);
                
                vm.isContentPagesDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isContentPageCreateUpdatePending = false;
                vm.setSelectedContentPage();
                  
                result.forceUpdateCount++;
                break;
            }   

            case App.ActionTypes.CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_REQUEST: {
                console.log('CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var item = action.payload;

                result.isContentPageCreateUpdatePending = true;
                result.forceUpdateCount++;

                cmsContentService.generateContentFromAIScript(item.contentPageId);
            
                break;
            }   
            
            case App.ActionTypes.CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_SUCCESS: {
                console.log('CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedContentPage?.contentPageId;
                var data = action.payload;
  
                App.ContentPagesManager.updateContent(data,result.contentPages);

                App.AppBase.showMessage('Successfully Generated!');
             
                
                vm.isActionInProgress=false;
                result.isContentPageCreateUpdatePending = false;
                //vm.setSelectedContentPage();
                  

                result.forceUpdateCount++;
                break;
            }   
           
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}