import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ValidationManager {


    constructor() {

    }


    public validateForgotPassword(key: any = null, sm: any): boolean {

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        if ((validateAll || key === 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is required!');
        }
        else if ((validateAll || key === 'email') && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email is not a valid email address!');
        }

        var result = (!App.AppBase.hasErrors);

        return result;

    }


    public validateLogin(key: any = null, sm: Api.LoginFormSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);



        if ((validateAll || key === 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is required!');
        }
        else if ((validateAll || key === 'email') && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email is not a valid email address!');
        }

        if ((validateAll || key === 'password') && LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('password', 'Password is required!');
        }
        var result = (!App.AppBase.hasErrors);
        console.log('validateLogin result', result);

        if (!result)
            console.log('validateLogin lastErrorMessage', App.AppBase.lastErrorMessage);



        return result;

    }



    public validateImpersonateUser(key: any = null, vm: App.AdminUserVm): boolean {
        //console.log('validateImpersonateUser, key:'+key);

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        if ((validateAll || key === 'impersonateUser') && LIB.Common.isNullOrEmpty(vm.selectedUserImpersonator)) {
            App.AppBase.addError('impersonateUser', 'User selection is required!');
        }


        return (!App.AppBase.hasErrors);
    }

    public validateOwner(key: any = null, item: App.AppUser): boolean {
        //console.log('validateImpersonateUser, key:'+key);

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        if ((validateAll || key === 'owner') && LIB.Common.isNullOrEmpty(item)) {
            App.AppBase.addError('owner', 'Owner selection is required!');
        }


        return (!App.AppBase.hasErrors);
    }

    public validateSubmitCandidate(key: any = null, item: Api.SubmitACandidateSm): boolean {

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        if ((validateAll || key === 'candidate') && LIB.Common.isNullOrEmpty(item.candidateId)) {
            App.AppBase.addError('candidate', 'Candidate selection is required, only Published Candidates are availble in this list!');
        }


        return (!App.AppBase.hasErrors);
    }

    public validateRegister(key: any = null, sm: Api.RegisterFormSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);


        // first name
        if ((key == null || key == 'firstName') && LIB.Common.isNullOrEmpty(sm.firstName?.trim())) {
            App.AppBase.addError('firstName', 'First Name is Required!');
        }
        else if ((key == null || key == 'firstName') && LIB.ValidationHelper.hasNumber(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name cannot contain numeric values, use letters only!')
        }
        else if ((key == null || key == 'firstName') && !LIB.ValidationHelper.hasLettersAndSpaceOnly(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name can only contain letters!')
        }
        else if ((key == null || key == 'firstName') && (sm.firstName.length < 2) && !LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name must have at least 2 characters!')
        }

        // last name
        if ((key == null || key == 'lastName') && LIB.Common.isNullOrEmpty(sm.lastName?.trim())) {
            App.AppBase.addError('lastName', 'Last Name is required!');
        }
        else if ((key == null || key == 'lastName') && LIB.ValidationHelper.hasNumber(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'lastName') && !LIB.ValidationHelper.hasLettersAndSpaceOnly(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name can only contain letters!')
        }
        else if ((key == null || key == 'lastName') && (sm.lastName.length < 2) && !LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name must have at least 2 characters!')
        }

        if ((key == null || key == 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is Required!')
        }
        else if ((key == null || key == 'email') && !LIB.Common.isNullOrEmpty(sm.email) && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email Format is Invalid!')
        }
        else if ((key == null || key == 'email') && !LIB.Common.isNullOrEmpty(sm.email) && LIB.ValidationHelper.isFreeEmailProvider(sm.email)) {
            App.AppBase.addError('email', 'Please use work email. We Do NOT allow personal emails')
        }

        if ((key == null || key == 'password') && LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('password', 'Password is Required!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && sm.password.length < 8) {
            App.AppBase.addError('password', 'Password must have at least 8 characters!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && LIB.ValidationHelper.hasSymbolsExceptSpace(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one symbol!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasUpperCaseLetter(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one uppercase letter!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasLowerCaseLetter(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one lowercase letter!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasNumber(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one numeric value!')
        }


        if ((key == null || key == 'acceptTerms') && !sm.acceptTerms) {
            App.AppBase.addError('acceptTerms', 'Please accept Terms of Service and Privacy Policy.')
        }

        if ((key == null || key == 'phone') && LIB.Common.isNullOrEmpty(sm.phone)) {
            App.AppBase.addError('phone', 'Phone is Required!')
        }
        else if ((key == null || key == 'phone') && !LIB.Common.isNullOrEmpty(sm.phone) && !LIB.ValidationHelper.isValidPhoneNumber(sm.phone)) {
            App.AppBase.addError('phone', 'Phone number is not valid!')
        }

        if ((key == null || key == 'companyName') && LIB.Common.isNullOrEmpty(sm.companyName)) {
            App.AppBase.addError('companyName', 'Company is Required!')
        }

        /* if ((key == null || key == 'userJobRole') && LIB.Common.isNullOrEmpty(sm.userJobRole)) {
             App.AppBase.addError('userJobRole', 'This Selection is required!');
         }
          else if ((key == null || key == 'userJobRole') && sm.userJobRole.startsWith("Zoom")) {
             App.AppBase.addError('userJobRole', 'Sorry, We are NOT affiliated with Zoom Meeting/Conference company. Please go to www.Zoom.us to register for their services.');
         } */

        var result = (!App.AppBase.hasErrors);
        console.log('validateLogin result', result);

        return result;

    }



    public validateAccountPasswordChange(key: any = null, sm: Api.AccountPasswordChangeSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 
        if ((key == null || key == 'currentPassword') && LIB.Common.isNullOrEmpty(sm.currentPassword)) {
            App.AppBase.addError('currentPassword', 'Current Password is Required!')
        }
        if ((key == null || key == 'password') && LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('password', 'Password is Required!')
        }
        if ((key == null || key == 'confirmPassword') && LIB.Common.isNullOrEmpty(sm.confirmPassword)) {
            App.AppBase.addError('confirmPassword', 'Confirm Password is Required!')
        }
        else if ((key == null || key == 'confirmPassword') && sm.confirmPassword!==sm.password) {
            App.AppBase.addError('confirmPassword', 'Confirm Password Does NOT match with Password!')
        }

        var result =  (!App.AppBase.hasErrors);

        if(!result)
            return result;

        var resetSm = new Api.PasswordResetFormSm();
        resetSm.password = sm.password;
        resetSm.confirmPassword = sm.confirmPassword;

        var resetCheck = this.validatePasswordReset(key, resetSm);
         return resetCheck;
    }

    public validateAdminUserPasswordChange(key: any = null, sm: Api.AdminPasswordChangeSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 
        if ((key == null || key == 'password') && LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('password', 'Password is Required!')
        }
        if ((key == null || key == 'confirmPassword') && LIB.Common.isNullOrEmpty(sm.confirmPassword)) {
            App.AppBase.addError('confirmPassword', 'Confirm Password is Required!')
        }
        else if ((key == null || key == 'confirmPassword') && sm.confirmPassword!==sm.password) {
            App.AppBase.addError('confirmPassword', 'Confirm Password Does NOT match with Password!')
        }

        var result =  (!App.AppBase.hasErrors);

        if(!result)
            return result;

        var resetSm = new Api.PasswordResetFormSm();
        resetSm.password = sm.password;
        resetSm.confirmPassword = sm.confirmPassword;

        var resetCheck = this.validatePasswordReset(key, resetSm);
         return resetCheck;
    }

    public validatePasswordReset(key: any = null, sm: Api.PasswordResetFormSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);



        if ((key == null || key == 'password') && LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('password', 'Password is Required!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && sm.password.length < 8) {
            App.AppBase.addError('password', 'Password must be at least 8 characters!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && LIB.ValidationHelper.hasSymbolsExceptSpace(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one symbol!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasUpperCaseLetter(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one uppercase letter!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasNumber(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one numeric value!')
        }
        else if ((key == null || key == 'password') && sm.password != sm.confirmPassword && !LIB.Common.isNullOrEmpty(sm.confirmPassword)
            && !LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('confirmPassword', 'Confirm Password Does NOT match with Password!')
        }



        if ((key == null || key == 'confirmPassword') && LIB.Common.isNullOrEmpty(sm.confirmPassword)) {
            App.AppBase.addError('confirmPassword', 'Confirmation Password is Required!');
        }
        else if ((key == null || key == 'confirmPassword') && !LIB.Common.isNullOrEmpty(sm.confirmPassword) && sm.confirmPassword.length < 8) {
            App.AppBase.addError('confirmPassword', 'Password must be at least 8 characters!');
        }
        else if ((key == null || key == 'confirmPassword') && !LIB.Common.isNullOrEmpty(sm.confirmPassword) && LIB.ValidationHelper.hasSymbolsExceptSpace(sm.confirmPassword)) {
            App.AppBase.addError('confirmPassword', 'Password must have at least one symbol!');
        }
        else if ((key == null || key == 'confirmPassword') && !LIB.Common.isNullOrEmpty(sm.confirmPassword) && !LIB.ValidationHelper.hasUpperCaseLetter(sm.confirmPassword)) {
            App.AppBase.addError('confirmPassword', 'Password must have at least one uppercase letter!');
        }
        else if ((key == null || key == 'confirmPassword') && !LIB.Common.isNullOrEmpty(sm.confirmPassword) && !LIB.ValidationHelper.hasNumber(sm.confirmPassword)) {
            App.AppBase.addError('confirmPassword', 'Confirm password must have at least one numeric value!')
        }
        else if ((key == null || key == 'confirmPassword') && sm.password != sm.confirmPassword && !LIB.Common.isNullOrEmpty(sm.confirmPassword)
            && !LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('confirmPassword', 'Confirm Password Does NOT match with Password!')
        }

        return (!App.AppBase.hasErrors);

    }


    public validateAccountProfileEdit(key: any = null, sm: App.AppUser) {

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        // first name
        if ((key == null || key == 'firstName') && LIB.Common.isNullOrEmpty(sm.firstName?.trim())) {
            App.AppBase.addError('firstName', 'First Name is Required!');
        }
        else if ((key == null || key == 'firstName') && LIB.ValidationHelper.hasNumber(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'firstName') && (sm.firstName.length < 2) && !LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name must have at least 2 characters!')
        }

        // last name
        if ((key == null || key == 'lastName') && LIB.Common.isNullOrEmpty(sm.lastName?.trim())) {
            App.AppBase.addError('lastName', 'Last Name is required!');
        }
        else if ((key == null || key == 'lastName') && LIB.ValidationHelper.hasNumber(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'lastName') && (sm.lastName.length < 2) && !LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name must have at least 2 characters!')
        }

        if ((key == null || key == 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is Required!')
        }
        else if ((key == null || key == 'email') && !LIB.Common.isNullOrEmpty(sm.email) && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email Format is Invalid!')
        }

        if ((key == null || key == 'phone') && LIB.Common.isNullOrEmpty(sm.phone)) {
            App.AppBase.addError('phone', 'Phone Number is Required!')
        }
        else if ((key == null || key == 'phone') && !LIB.ValidationHelper.isValidPhoneNumber(sm.phone)) {
            App.AppBase.addError('phone', 'Phone number is Invalid!')
        }

       /*  if ((key == null || key == 'address') && LIB.Common.isNullOrEmpty(sm.address)) {
            App.AppBase.addError('address', 'Address is Required!')
        }
        if ((key == null || key == 'city') && LIB.Common.isNullOrEmpty(sm.city)) {
            App.AppBase.addError('city', 'City is Required!')
        }
        if ((key == null || key == 'state') && LIB.Common.isNullOrEmpty(sm.state)) {
            App.AppBase.addError('state', 'State is Required!')
        }
        if ((key == null || key == 'country') && LIB.Common.isNullOrEmpty(sm.country)) {
            App.AppBase.addError('country', 'Country is Required!')
        }
        if ((key == null || key == 'zipCode') && LIB.Common.isNullOrEmpty(sm.zipCode)) {
            App.AppBase.addError('zipCode', 'Zip/Postal Code is Required!')
        } */

          return (!App.AppBase.hasErrors);
    }

    public validateAdministrationUserEdit(key: any = null, sm: Api.AppUser) {
        console.log("validateAdministrationUserEdit:", key);
        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        // first name
        if ((key == null || key == 'firstName') && LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name is Required!');
        }
        else if ((key == null || key == 'firstName') && LIB.ValidationHelper.hasNumber(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'firstName') && (sm.firstName.length < 2) && !LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name must have at least 2 characters!')
        }

        // last name
        if ((key == null || key == 'lastName') && LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name is required!');
        }
        else if ((key == null || key == 'lastName') && LIB.ValidationHelper.hasNumber(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'lastName') && (sm.lastName.length < 1) && !LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name must have at least 1 characters!')
        }
        //email
        if ((key == null || key == 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is Required!')
        }
        else if ((key == null || key == 'email') && !LIB.Common.isNullOrEmpty(sm.email) && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email Format is Invalid!')
        }
        //phone
        if ((key == null || key == 'phone') && LIB.Common.isNullOrEmpty(sm.phone)) {
            App.AppBase.addError('phone', 'Phone Number is Required!')
        }
        else if ((key == null || key == 'phone') && !LIB.ValidationHelper.isValidPhoneNumber(sm.phone!)) {
            App.AppBase.addError('phone', 'Phone number is Invalid!')
        }

        //company
        if ((key == null || key == 'companyName') && LIB.Common.isNullOrEmpty(sm.company?.companyName)) {
            App.AppBase.addError('companyName', 'Company Name is Required!');

        }
        else if ((key == null || key == 'companyName') && (sm.company?.companyName.length < 2) && !LIB.Common.isNullOrEmpty(sm.company?.companyName)) {
            App.AppBase.addError('companyName', 'Company Name must have at least 2 characters!');

        }

        /* if ((key == null || key == 'address') && LIB.Common.isNullOrEmpty(sm.address)) {
            App.AppBase.addError('address', 'Address is Required!')
        }
        if ((key == null || key == 'city') && LIB.Common.isNullOrEmpty(sm.city)) {
            App.AppBase.addError('city', 'City is Required!')
        }
        if ((key == null || key == 'state') && LIB.Common.isNullOrEmpty(sm.state)) {
            App.AppBase.addError('state', 'State is Required!')
        }
        if ((key == null || key == 'country') && LIB.Common.isNullOrEmpty(sm.country)) {
            App.AppBase.addError('country', 'Country is Required!')
        }
        if ((key == null || key == 'zipCode') && LIB.Common.isNullOrEmpty(sm.zipCode)) {
            App.AppBase.addError('zipCode', 'Zip/Postal Code is Required!')
        } */

          return (!App.AppBase.hasErrors);
    }

    public validateAdministrationUserCreate(key: any = null, sm: Api.AdminCreateUserFormSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);


        // first name
        if ((key == null || key == 'firstName') && LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name is Required!');
        }
        else if ((key == null || key == 'firstName') && LIB.ValidationHelper.hasNumber(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'firstName') && (sm.firstName.length < 2) && !LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name must have at least 2 characters!')
        }

        // last name
        if ((key == null || key == 'lastName') && LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name is required!');
        }
        else if ((key == null || key == 'lastName') && LIB.ValidationHelper.hasNumber(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name cannot contain numeric values!')
        }
        else if ((key == null || key == 'lastName') && (sm.lastName.length < 1) && !LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name must have at least 1 characters!')
        }
        //email
        if ((key == null || key == 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is Required!')
        }
        else if ((key == null || key == 'email') && !LIB.Common.isNullOrEmpty(sm.email) && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email Format is Invalid!')
        }
        //phone
        if ((key == null || key == 'phone') && LIB.Common.isNullOrEmpty(sm.phone)) {
            App.AppBase.addError('phone', 'Phone Number is Required!')
        }
        else if ((key == null || key == 'phone') && !LIB.ValidationHelper.isValidPhoneNumber(sm.phone!)) {
            App.AppBase.addError('phone', 'Phone number is Invalid!')
        }
        //company
        if ((key == null || key == 'companyName') && LIB.Common.isNullOrEmpty(sm.companyName)) {
            App.AppBase.addError('companyName', 'Company Name is Required!');

        }
        else if ((key == null || key == 'companyName') && (sm.companyName.length < 2) && !LIB.Common.isNullOrEmpty(sm.companyName)) {
            App.AppBase.addError('companyName', 'Company Name must have at least 2 characters!');

        }
        //password
        
        if ((key == null || key == 'password') && LIB.Common.isNullOrEmpty(sm.password)) {
            App.AppBase.addError('password', 'Password is Required!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && sm.password.length < 8) {
            App.AppBase.addError('password', 'Password must have at least 8 characters!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && LIB.ValidationHelper.hasSymbolsExceptSpace(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one symbol!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasUpperCaseLetter(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one uppercase letter!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasLowerCaseLetter(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one lowercase letter!')
        }
        else if ((key == null || key == 'password') && !LIB.Common.isNullOrEmpty(sm.password) && !LIB.ValidationHelper.hasNumber(sm.password)) {
            App.AppBase.addError('password', 'Password must have at least one numeric value!')
        }

        

        return (!App.AppBase.hasErrors);

    }

    public validateCompanyProfileEdit(key: any = null, sm: Api.Company) {

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

        // first name
        if ((key == null || key == 'companyName') && LIB.Common.isNullOrEmpty(sm.companyName)) {
            App.AppBase.addError('companyName', 'Company Name is Required!');
        }
        else if ((key == null || key == 'companyName') && (sm.companyName.length < 2) && !LIB.Common.isNullOrEmpty(sm.companyName)) {
            App.AppBase.addError('companyName', 'Company Name must have at least 2 characters!')
        }
   
        if ((key == null || key == 'phone') && LIB.Common.isNullOrEmpty(sm.phone)) {
            App.AppBase.addError('phone', 'Phone Number is Required!')
        }
        else if ((key == null || key == 'phone') && !LIB.ValidationHelper.isValidPhoneNumber(sm.phone)) {
            App.AppBase.addError('phone', 'Phone number is Invalid!')
        }

        if ((key == null || key == 'address') && LIB.Common.isNullOrEmpty(sm.address)) {
            App.AppBase.addError('address', 'Address is Required!')
        }
        if ((key == null || key == 'city') && LIB.Common.isNullOrEmpty(sm.city)) {
            App.AppBase.addError('city', 'City is Required!')
        }
        if ((key == null || key == 'state') && LIB.Common.isNullOrEmpty(sm.state)) {
            App.AppBase.addError('state', 'State is Required!')
        }
        if ((key == null || key == 'country') && LIB.Common.isNullOrEmpty(sm.country)) {
            App.AppBase.addError('country', 'Country is Required!')
        }
        if ((key == null || key == 'zipCode') && LIB.Common.isNullOrEmpty(sm.zipCode)) {
            App.AppBase.addError('zipCode', 'Zip/Postal Code is Required!')
        }
        if ((key == null || key == 'companySizeType') && LIB.Common.isNullOrEmpty(sm.companySizeType)) {
            App.AppBase.addError('companySizeType', 'Company Size selection is Required!')
        }
        if ((key == null || key == 'avgBenchTalentCountType') && LIB.Common.isNullOrEmpty(sm.avgBenchTalentCountType)) {
            App.AppBase.addError('avgBenchTalentCountType', 'This selection is Required!')
        }
        if ((key == null || key == 'companySizeRecruitersType') && LIB.Common.isNullOrEmpty(sm.companySizeRecruitersType)) {
            App.AppBase.addError('companySizeRecruitersType', 'This selection is Required!')
        }

          return (!App.AppBase.hasErrors);
    }


    
    
    public validateCandidateCreateUpdate(key: any = null, sm: Api.Candidate, isAdminEdit:boolean=false): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 

        if ((key == null || key == 'name') && LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('name', 'Name is Required!')
        }
        else if ((key == null || key == 'name') && (sm.name.length < 2) && !LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('name', 'Name must have at least 2 characters!')
        }
        else if ((key == null || key == 'name') && sm.name!==undefined && sm.name.indexOf("@")>-1) {
            App.AppBase.addError('name', 'Name should not contain email!')
        }
     
        if ((key == null || key == 'title') && LIB.Common.isNullOrEmpty(sm.title)) {
            App.AppBase.addError('title', 'Title is Required!')
        }
        else if ((key == null || key == 'title') && sm.title!==undefined && sm.title.indexOf("@")>-1) {
            App.AppBase.addError('title', 'Title should not contain email!')
        }

        if ((key == null || key == 'skills') && LIB.Common.isNullOrEmpty(sm.skills)) {
            App.AppBase.addError('skills', 'Skills is Required!')
        }
        else if ((key == null || key == 'skills') && sm.skills!==undefined && sm.skills.indexOf("@")>-1) {
            App.AppBase.addError('skills', 'Skills should not contain email!')
        }
/* 
        if ((key == null || key == 'location') && LIB.Common.isNullOrEmpty(sm.location)) {
            App.AppBase.addError('location', 'Location is Required!')
        }
 */
        if ((key == null || key == 'location') && sm.location!==undefined && sm.location?.indexOf("@")>-1) {
            App.AppBase.addError('location', 'Location should not contain email!')
        }

        if ((validateAll || key === 'visaTypeId') && LIB.Common.isNullOrEmpty(sm.visaType)) {
            App.AppBase.addError('visaType', 'Visa selection is required!');
        }

        if ((validateAll || key === 'relocationTypeId') && LIB.Common.isNullOrEmpty(sm.relocationType)) {
            App.AppBase.addError('relocationType', 'Relocation selection is required!');
        }
        if ((validateAll || key === 'experience') && LIB.Common.isNullOrEmpty(sm.experience)) {
            App.AppBase.addError('experience', 'Experience is required!');
        }
        else if ((validateAll || key === 'experience') && (sm.experience as number) < 0) {
            App.AppBase.addError('experience', 'Experience must be a positive number!');
        }
        
        if(sm.visaType?.name=='Other'){

            if ((validateAll || key === 'otherVisaType') && LIB.Common.isNullOrEmpty(sm.otherVisaType)) {
                App.AppBase.addError('otherVisaType', 'Specify other type!');
            }
            else if ((validateAll || key === 'otherVisaType') && (sm.otherVisaType!==undefined && sm.otherVisaType?.length>30)) {
                App.AppBase.addError('otherVisaType', 'Other type must be less than 30 characters!');
            }
        }
        if(sm.relocationType?.name=='Other'){
            if ((validateAll || key === 'otherRelocationType') && LIB.Common.isNullOrEmpty(sm.otherRelocationType)) {
                App.AppBase.addError('otherRelocationType', 'Specify other type!');
            }
            else if ((validateAll || key === 'otherRelocationType') && (sm.otherRelocationType!==undefined && sm.otherRelocationType?.length>30)) {
                App.AppBase.addError('otherRelocationType', 'Other type must be less than 30 characters!');
            }
        }
       

        //console.log('validate key: ',key);
       // console.log('validate resume: ',sm.resume);
        //console.log('validate resume: ',LIB.Common.isNullOrEmpty(sm.resume));
       
        if ((validateAll || key === 'resume') && LIB.Common.isNullOrEmpty(sm.resume?.fileName) && !isAdminEdit) {
            App.AppBase.addError('resume', 'Resume Upload is required!');
        }

        return (!App.AppBase.hasErrors);

    }

    
    public validateAdminCandidateCreate(key: any = null, sm: Api.Candidate, isAdminEdit:boolean=false): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();


        this.validateCandidateCreateUpdate(key, sm,isAdminEdit);

        var validateAll = (key === null);
 
        
        if ((key == null || key == 'owner') && LIB.Common.isNullOrEmpty(sm.ownerId)) {
            App.AppBase.addError('owner', 'Owner selection is required!')
        }


        var result =  (!App.AppBase.hasErrors);
        if(App.AppBase.hasErrors){
            console.error(App.AppBase.lastErrorMessage);
        }

        return result;
    }

    public validateAdminJobCreate(key: any = null, sm: Api.Job, isAdminEdit:boolean=false): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();


        this.validateJobCreateUpdate(key, sm,isAdminEdit);

        var validateAll = (key === null);
 
        
        if ((key == null || key == 'owner') && LIB.Common.isNullOrEmpty(sm.ownerId)) {
            App.AppBase.addError('owner', 'Owner selection is required!')
        }


        var result =  (!App.AppBase.hasErrors);
        if(App.AppBase.hasErrors){
            console.error(App.AppBase.lastErrorMessage);
        }

        return result;
    }
    
    

    
    public validateJobCreateUpdate(key: any = null, sm: Api.Job, isAdminEdit:boolean=false): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 
        if ((key == null || key == 'title') && LIB.Common.isNullOrEmpty(sm.title)) {
            App.AppBase.addError('title', 'Title is Required!')
        }
        else if ((key == null || key == 'title') && (sm.title.length < 2) && !LIB.Common.isNullOrEmpty(sm.title)) {
            App.AppBase.addError('title', 'Title must have at least 2 characters!')
        }

        if ((key == null || key == 'description') && (LIB.Common.isNullOrEmpty(sm.description) || sm.description==='<br/>')) {
            App.AppBase.addError('description', 'Description is Required!')
        }

    /*     if ((key == null || key == 'location') && LIB.Common.isNullOrEmpty(sm.location)) {
            App.AppBase.addError('location', 'Location is Required!')
        } */

        if ((validateAll || key === 'jobTypeId') && LIB.Common.isNullOrEmpty(sm.jobType)) {
            App.AppBase.addError('jobType', 'Type selection is required!');
        }

     /*    if ((validateAll || key === 'partnershipTypeId') && LIB.Common.isNullOrEmpty(sm.partnershipType)) {
            App.AppBase.addError('partnershipType', 'Partnership selection is required!');
        }
        if ((validateAll || key === 'requiredVisaTypeId') && LIB.Common.isNullOrEmpty(sm.requiredVisaType)) {
            App.AppBase.addError('requiredVisaType', 'Visa Requirement selection is required!');
        } */

     

        return (!App.AppBase.hasErrors);

    }

    
    public validateAdminCompanyCreate(key: any = null, sm: Api.Company, isAdminEdit:boolean=false): boolean {
        // console.log('validateAdminCompanyCreate');

        App.AppBase.resetErrorMessages();
 
        var validateAll = (key === null);
 
        
        if ((key == null || key == 'companyName') && LIB.Common.isNullOrEmpty(sm.companyName)) {
            App.AppBase.addError('companyName', 'Name is required!')
        }

        if ((key == null || key == 'domain') && LIB.Common.isNullOrEmpty(sm.domain)) {
            App.AppBase.addError('domain', 'Domain is required!')
        }




        var result =  (!App.AppBase.hasErrors);
        if(App.AppBase.hasErrors){
            console.error(App.AppBase.lastErrorMessage);
        }

        return result;
    }

    public validateEmailTemplateCreateUpdate(key: any = null, sm: Api.EmailTemplate): boolean {
        // console.log('validateEmailTemplateCreateUpdate');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);

 
        if ((key == null || key == 'name') && LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('name', 'Name is Required!')
        }
        else if ((key == null || key == 'name') && (sm.name.length < 2) && !LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('name', 'Name must have at least 2 characters!')
        }

        if ((key == null || key == 'fromName') && LIB.Common.isNullOrEmpty(sm.fromName)) {
            App.AppBase.addError('fromName', 'From Name is Required!')
        }
        else if ((key == null || key == 'fromName') && (sm.name.length < 2) && !LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('fromName', 'From Name must have at least 2 characters!')
        }
        else if ((key == null || key == 'fromName') && LIB.ValidationHelper.hasNumber(sm.fromName)) {
            App.AppBase.addError('fromName', 'From Name cannot contain numeric values!')
        }

        //email
        if ((key == null || key == 'fromEmail') && LIB.Common.isNullOrEmpty(sm.fromEmail)) {
            App.AppBase.addError('fromEmail', 'Work Email is Required!')
        }
        else if ((key == null || key == 'fromEmail') && !LIB.Common.isNullOrEmpty(sm.fromEmail) && !LIB.ValidationHelper.isValidEmail(sm.fromEmail)) {
            App.AppBase.addError('fromEmail', 'Work Email Format is Invalid!')
        }
       
   
        if ((key == null || key == 'subject') && LIB.Common.isNullOrEmpty(sm.subject)) {
            App.AppBase.addError('subject', 'Subject is Required!')
        }
        else if ((key == null || key == 'title') && (sm.name.length < 2) && !LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('subject', 'Subject must have at least 2 characters!')
        }

        if ((key == null || key == 'body') && (LIB.Common.isNullOrEmpty(sm.body) || sm.body==='<br/>')) {
            App.AppBase.addError('body', 'Email body is Required!')
        }
     

        return (!App.AppBase.hasErrors);

    }

    public validateProspect(key: any = null, sm: Api.Prospect): boolean{
        App.AppBase.resetErrorMessages();

        if ((key == null || key == 'firstName') && LIB.Common.isNullOrEmpty(sm.firstName)) {
            App.AppBase.addError('firstName', 'First Name is Required!')
        }

        if ((key == null || key == 'lastName') && LIB.Common.isNullOrEmpty(sm.lastName)) {
            App.AppBase.addError('lastName', 'Last Name is Required!')
        }

        //email
        if ((key == null || key == 'email') && LIB.Common.isNullOrEmpty(sm.email)) {
            App.AppBase.addError('email', 'Email is Required!')
        }
        else if ((key == null || key == 'email') && !LIB.Common.isNullOrEmpty(sm.email) && !LIB.ValidationHelper.isValidEmail(sm.email)) {
            App.AppBase.addError('email', 'Email Format is Invalid!')
        }

        return (!App.AppBase.hasErrors);

    }

    public validateBulkImport(key: any = null, sm: Api.BulkEmailProspectsSm): boolean{
        App.AppBase.resetErrorMessages();

        //email
        if ((key == null || key == 'bulkEmails') && LIB.Common.isNullOrEmpty(sm.emailsText)) {
            App.AppBase.addError('bulkEmails', 'Import field cannot be empty')
        }

        return (!App.AppBase.hasErrors);

    }

    public validateSendDailyEmail(key: any = null, sm: Api.DailyEmailSm ): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 
        if ((key == null || key == 'testEmail') && LIB.Common.isNullOrEmpty(sm.testEmail)) {
            App.AppBase.addError('testEmail', 'Test Email is Required!')
        }
        else if ((key == null || key == 'testEmail') && sm.testEmail!==undefined && sm.testEmail.indexOf("@")==-1) {
            App.AppBase.addError('testEmail', 'Test Email - Invalid Email Address format!')
        }
  
        return (!App.AppBase.hasErrors);

    }

    public validateAdminUserRolesChange(key: any = null, sm: Api.AdminChangeUserRolesSm): boolean {
        // console.log('validateCreateProject');

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 
        if ((key == null || key == 'userId') && LIB.Common.isNullOrEmpty(sm.userId)) {
            App.AppBase.addError('userId', 'UserId is Required!')
        }
        if ((key == null || key == 'userRoleIds') && (
            LIB.Common.isNullOrEmpty(sm.userRoleIds) || 
            !Array.isArray(sm.userRoleIds) || 
            sm.userRoleIds.length<1
        )
        ) {
            App.AppBase.addError('userRoleIds', 'Invalid User Roles selection, at least 1 User Role is required!')
        }
        

        return (!App.AppBase.hasErrors);

    }


    
    
    public validateCMSContentPageCreateUpdate(key: any = null, sm: Api.ContentPage, isAdminEdit:boolean=false): boolean {
         
        
        console.log('validateCMSContentPageCreateUpdate key',key);
        console.log('validateCMSContentPageCreateUpdate sm',sm);

        App.AppBase.resetErrorMessages();

        var validateAll = (key === null);
 

        if ((key == null || key == 'name') && LIB.Common.isNullOrEmpty(sm.name)) {
            App.AppBase.addError('name', 'Name is Required!')
        }
       
     
        if ((key == null || key == 'content') && LIB.Common.isNullOrEmpty(sm.content)) {
            App.AppBase.addError('content', 'Content is Required!')
        }
        
        if ((key == null || key == 'title') && LIB.Common.isNullOrEmpty(sm.title)) {
            App.AppBase.addError('title', 'Title is Required!')
        }

        if ((key == null || key == 'urlPath') && LIB.Common.isNullOrEmpty(sm.urlPath)) {
            App.AppBase.addError('urlPath', 'Url Path is Required!')
        }
 
        if ((key == null || key == 'contentSiteId') && LIB.Common.isNullOrEmpty(sm.contentSite)) {
            App.AppBase.addError('contentSite', 'Site is Required!')
        }
        
        if ((key == null || key == 'contentTypeId') && LIB.Common.isNullOrEmpty(sm.contentType)) {
            App.AppBase.addError('contentType', 'Content Type is Required!')
        }
 

        return (!App.AppBase.hasErrors);

    }
}