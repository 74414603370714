import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { useParams, useLocation } from 'react-router';


import { ContentPagesDetailForm } from './ContentPagesDetailForm';



const ContentPagesDetail: React.FC<App.CMSState> = (props) => {


  var validationManager: App.ValidationManager = new App.ValidationManager();
  var params = useParams();
  var location = useLocation();


  React.useEffect(() => {
    console.log('ContentPagesDetailForm componentDidMount');
    var vm = props.vm;

    if (props.hasContentPageDataLoaded)
      return;

    console.log('ContentPagesDetailForm componentDidMount id', params.id);

    var id = Number(params.id);
    vm.selectedContentPageId = -1;

    console.log('ContentPagesDetailForm componentDidMount isNaN', isNaN(id));

    if (isNaN(id))
      return;

    vm.selectedContentPageId = id;
    var searchFilter = new Api.ContentPageFilterVm(); //state.contentPageSearchFilter;
    searchFilter.isSearch = true;

    console.log('onFilterClick id', id);

    searchFilter.id = id;
    App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_LIST_DATA_REQUEST, searchFilter);

  }, []);//Run only Once



  function onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = props;
    var vm = state.vm;

    var itemId = vm.selectedContentPage?.contentPageId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.CMS_CONTENT_PAGES_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    //this.forceUpdate();

  }

  function forceUpdate() {

  }



  function render() {

    console.log('ContentPagesDetail render');

    var state = props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var filter = state.contentPageSearchFilter;

    // console.log('ContentPagesDetail hasContentPageDataLoaded', state.hasContentPageDataLoaded);
    // console.log('ContentPagesDetail isServiceCallPending',this.myListingsService.isServiceCallPending);
    // console.log('ContentPagesDetail vm.isActionInProgress',vm.isActionInProgress);


    if (!currentUser.hasRole(App.UserRoleTypeEnum.Admin)) {
      return null;
    }


    return <App.MainPage
      pageTitle=""
      state={vm}
      hasDataLoaded={state.hasContentPageDataLoaded}
      loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
     // errorMessage={state.apiErrorMessage}
  
     showError={false}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      // isChildPage={true}
      showLeftMenu={true}
    >
      <div>

        <ContentPagesDetailForm state={state} onChange={() => forceUpdate()} />


      </div>



      <LIB.ConfirmModal
        visible={vm.isContentPagesDeleteOpen}
        onCancelClick={() => { vm.isContentPagesDeleteOpen = false; }}
        onConfirmClick={() => onDeleteConfirmClick()}
        title={`Confirm Delete: ${vm.selectedContentPage?.name}`}
        content={`Are you sure you want to delete "${vm.selectedContentPage?.name}" permit?`}
        confirmButtonLabel="Delete"
        disabled={vm.isActionInProgress}
      />


    </App.MainPage>

  }

  return render();
}
export default connect(
  (state: App.AppState) => ({ ...state.cmsState })
)(ContentPagesDetail as any);

