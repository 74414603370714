import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Link, Stack
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
//import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface MyAccountCandidatesAddEditModalProps {
  state: App.MyAccountState;
  onChange?: Function;
}


export class MyAccountCandidatesAddEditModal extends React.Component<MyAccountCandidatesAddEditModalProps> {

  // todo public designSetDataService: App.CandidateDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    // todo this.designSetDataService = new App.CandidateDataService();
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('MyAccountCandidatesAddEditModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('MyAccountCandidatesAddEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onAddOrUpdateClick(isPublished: boolean) {
    console.log('onAddOrUpdateClick');


    var state = this.props.state;
    var vm = state.vm;
    var sm = state.candidateSm;
    var item = vm.selectedCandidate;
    var items = state.candidates;

    item.isPublished = isPublished;

    var isValid = this.validationManager.validateCandidateCreateUpdate(null, item);

    if (!isValid) {
      console.log('validateAddCandidateModal NOT valid, ErrorMessage:', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    if (vm.isCandidatesEditOpen)
      App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPDATE_REQUEST, item);
    else if (vm.isCandidatesAddOpen)
      App.AppStore.dispatch(App.ActionTypes.MY_ACCOUNT_CANDIDATE_ADD_REQUEST, item);

    vm.isActionInProgress = true;

    // this.onCloseClick();

  }

  public onCloseClick() {
    console.log('onCloseClick');
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.candidateSm;

    //  console.log('onCloseClick selectedCandidateOriginal',vm.selectedCandidateOriginal);

    // state.candidates[0] =  vm.selectedCandidateOriginal;

    //reset edits
    App.CandidateManager.update(vm.selectedCandidateOriginal, state.candidates);

    vm.isCandidatesAddOpen = false;
    vm.isCandidatesEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

    // console.log('onCloseClick onChange');

    if (this.props.onChange !== undefined)
      this.props.onChange();

    // console.log('onCloseClick end');

  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    console.log('onAutocompleteChange value', value);
    console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.candidateSm;

    var item = vm.selectedCandidate;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);



    /* if (name == 'name')
      item.name = value;

    else {

      name = LIB.FormHelper.setInputChangeData(selected, item);

      if (LIB.Common.isNullOrEmpty(name))
        return;
    }
 */
    this.validationManager.validateCandidateCreateUpdate(key, item);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCandidate;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateCandidateCreateUpdate(name, item);


    this.forceUpdate();
  }


  public onFileChange(fileInfo: LIB.MSingleFileUploadVm) {
    console.log('onFileChange fileInfo', fileInfo);

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCandidate;

    if (LIB.Common.isNullOrEmpty(fileInfo)) {
      console.log('onFileChange isNullOrEmpty', fileInfo);

      if (item.resume != undefined) {
        item.resume.file = undefined;
        item.resume.filePath = undefined;
        item.resume.fileName = '';
        item.resume.candidateId = undefined;
      }

      item.resumeFile = undefined;

      console.log('onFileChange resume reset', item?.resume);

    }
    else {


      if (item.resume == undefined)
        item.resume = new Api.Attachment();

      item.resume.file = fileInfo.newFile;
      item.resumeFile = fileInfo.newFile;
      item.resume.fileName = fileInfo.newFileName;
      item.resume.candidateId = item.candidateId;
      item.resume.attachmentRecordTypeId = App.AttachmentRecordTypeEnum.Candidate;
      item.resume.attachmentTypeId = App.AttachmentTypeEnum.Resume;
    }

    this.validationManager.validateCandidateCreateUpdate('resume', item);

    var tst = LIB.FormDataHelper.getFormData(item);

    console.log('onFileChange item', item);
    console.log('onFileChange tst', tst);

  }


  /* private onFileChangeOld(e: any) {
    const vm = this.props.vm;
    var files = Array.from(e.target.files);
    var item = vm.selectedItem;
    console.log("onFileChange files:", files);

    //validate
    if (!Array.isArray(files) || files.length < 1) return;

    item.file = files[0];

    console.log("onFileChange file 2:", item.file);

    item.fileName = item.file.name.toString();

    var fileNameParts = item.fileName.split('.');

    if(fileNameParts.length>1)
       fileNameParts.splice(fileNameParts.length-1, 1);

    item.fileName = fileNameParts.join('.');

    this.forceUpdate();
  } */

  public getResumeUploadElement() {


    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCandidate;

    var fileVm = new LIB.MSingleFileUploadVm()

    fileVm.existingFileName = item.resume?.fileName;
    fileVm.existingFilePath = `${App.AppBase.appApiBaseUrl}/files/${item.resume?.filePath}`;

    return <div>

      <h3>Resume</h3>

      <LIB.MSingleFileUpload
        name='resume'
        vm={fileVm}
        onChange={(fl: LIB.MSingleFileUploadVm) => this.onFileChange(fl)}
        pending={(state.isCandidateCreateUpdatePending)}

      />
      {/*   {this.getAlert()} */}
    </div>
  }


  public getForm() {
    //console.log('MyAccountCandidatesAddEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCandidate;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedCandidate;

    if (item === undefined)
      return null;

    //  var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasCandidateDataLoaded}
      loading={(state.isCandidateCreateUpdatePending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >



        <div >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                variant='standard'
                name="name" label="Name"
                value={item.name || ''}
                {...App.AppBase.getInputErrorProps('name')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}
            >
              <TextField
                name="title" label="Title"
                placeholder="i.e Full Stack .NET/React Developer"
                value={item.title || ''}
                {...App.AppBase.getInputErrorProps('title')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>

          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                name="location" label="Location"
                value={item.location || ''}
                {...App.AppBase.getInputErrorProps('location')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />

            </Grid>
            <Grid item xs={12} sm={6}
            >

              <TextField
                name="experience" label="Experience (years)"
                value={item.experience || ''}
                type="number"
                {...App.AppBase.getInputErrorProps('experience')}
                onChange={this.onInputChange.bind(this)}
                //style={{width:120}}
                style={fullWidth}
                inputProps={{ style: formInput }}
                placeholder="i.e 10 years"
              />


            </Grid>

          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >

              <Grid container spacing={2}>
                <Grid item sm={7} >

                  <App.FormAutocomplete
                    label="Visa"
                    name="visaType"
                    data={state.candidateVisaTypes}
                    onChange={this.onAutocompleteChange.bind(this)}
                    labelProperty="name"
                    valueProperty="candidateVisaTypeId"
                    value={item.visaType}
                    textFieldStyle={autocompleteInput}

                  />
                </Grid>
                <Grid item sm={5}

                >
                  <div hidden={item.visaType?.name !== "Other"}>
                    <TextField
                      name="otherVisaType" label="Specify"
                      value={item.otherVisaType || ''}
                      {...App.AppBase.getInputErrorProps('otherVisaType')}
                      onChange={this.onInputChange.bind(this)}
                      style={fullWidth}
                      inputProps={{ style: formInput }}

                    />
                  </div>
                </Grid>
              </Grid>



            </Grid>
            <Grid item xs={12} sm={6}
            >

              <Grid container spacing={2}>
                <Grid item sm={7} >

                  <App.FormAutocomplete
                    label="Relocation"
                    name="relocationType"
                    data={state.candidateRelocationTypes}
                    onChange={this.onAutocompleteChange.bind(this)}
                    labelProperty="name"
                    valueProperty="candidateRelocationTypeId"
                    value={item.relocationType}
                    textFieldStyle={autocompleteInput}

                  />
                </Grid>
                <Grid item sm={5}

                >
                  <div hidden={item.relocationType?.name !== "Other"}>
                    <TextField
                      name="otherRelocationType" label="Specify"
                      value={item.otherRelocationType || ''}
                      {...App.AppBase.getInputErrorProps('otherRelocationType')}
                      onChange={this.onInputChange.bind(this)}
                      style={fullWidth}
                      inputProps={{ style: formInput }}

                    />
                  </div>
                </Grid>
              </Grid>

            </Grid>


          </Grid>

          <div>

            <TextField
              name="skills" label="Skills / Keywords"
              value={item.skills || ''}
              {...App.AppBase.getInputErrorProps('skills')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              placeholder="1 or 2 main skills, i.e .Net, React"
              inputProps={{ style: formInput }}
              multiline={true}
              minRows={2}
              maxRows={5}

            />
          </div>


          <div>

            <Tooltip arrow placement="right"
              title="Hot candidates will be included in daily email to all vendors."

            >
              <FormControlLabel
                control={

                  <Switch name="isHot"
                    checked={item.isHot}
                    onChange={this.onInputChange.bind(this)}
                  />

                }
                style={{ marginLeft: 0 }}
                labelPlacement="start"
                label="Hot"
              />
            </Tooltip>

            <Tooltip arrow placement="right"
              title="When turned On, System will Automatically Renew this Candidate every Week so it never expires."

            >
              <FormControlLabel
                control={

                  <Switch name="isAutoRenew"
                    checked={item.isAutoRenew}
                    onChange={this.onInputChange.bind(this)}
                  />

                }
                style={{ marginLeft: 10 }}
                labelPlacement="start"
                label="Auto Renew"
              />
            </Tooltip>

            {/*  <Tooltip arrow placement="right"
              title="Only Published Candidates will be available to vendors or emails."
            >
              <FormControlLabel
                control={

                  <Switch name="isPublished"
                    checked={item.isPublished}
                    onChange={this.onInputChange.bind(this)}

                    color="primary"
                  />

                }
                style={{ marginLeft: 20 }}
                labelPlacement="start"
                label="Published"
              />
            </Tooltip>
            */}

          </div>


          {this.getResumeUploadElement()}


        </div>


      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('MyAccountCandidatesAddEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isCandidatesAddOpen || vm.isCandidatesEditOpen);
    var item = vm.selectedCandidate;


    var title = "Add Candidate";
    var saveBtnTitle = "Save Draft"; //"Add";
    if (vm.isCandidatesEditOpen) {
      title = `Edit Candidate (${item.candidateId})`;
      saveBtnTitle = "Save Draft";
    }

    var statusTag = App.FeaturedManager.getStatusNameTag(item);

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title} {statusTag}</DialogTitle>
          <DialogContent style={{ width: '35vw', minWidth: 800 }}>
            {/* {this.getAlert(App.AppBase.lastErrorMessage)} */}

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <div style={{ marginRight: 10 }}>Status: {statusTag}</div>

            <Button
              variant="contained"
              color="inherit"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>

            <Button
              color="warning"
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => this.onAddOrUpdateClick(false)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</Button>


            <Tooltip arrow placement="top"
              title="Only Published Candidates will be available to vendors or emails."
            >
 
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => this.onAddOrUpdateClick(true)}
                disabled={vm.isActionInProgress}
              // title='Only Published Candidates will be available to vendors or emails.'
              >Publish</Button>

            </Tooltip>


          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 