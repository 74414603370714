import * as React from 'react';
import * as App from 'AppReferences';
import { Select, Step, Button, Paper, Typography, TextField } from '@mui/material';
import * as LIB from '_LIB';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';



import { createStyles,  Theme } from '@mui/material/styles';
import { CSSProperties } from "react";




export class FormAutocompleteProps {
  label: string;
  name: string;
  value?: any;
  data: any[];
  onChange?: Function;
  labelProperty?: string;
  labelRenderer?: (item: any) => string;
  valueProperty?: string;
  disabled?: boolean;
  textFieldStyle?: CSSProperties;
  textFieldClassName?: string;
  textFieldLabelStyle?: CSSProperties;
  style?: CSSProperties;
  multiple?: boolean;
  allowNewEntries?: boolean;
  hidden?:boolean;

  constructor() {
    this.labelProperty = 'name';
    this.valueProperty = '';
    this.disabled = false;
    this.multiple = false;
    this.allowNewEntries = false;
    this.hidden=false;
  }
}

export class FormAutocomplete extends React.Component<FormAutocompleteProps, {}> {

  constructor(props: any) {
    super(props);

  }


  public onChange(e: React.ChangeEvent<HTMLInputElement>, name: string, selectedRecords: any, labelProperty: string, valueProperty: string) {

    //console.log('FormAutocomplete change');

    var selectedValues: any[];
    var selectedValue: any;
    var multiple = this.props.multiple;
    var finalValue: any;

    if (multiple && Array.isArray(selectedRecords)) {
      selectedValues = [];
      for (var key in selectedRecords) {
        var item = selectedRecords[ key ];

        if (item != null && !LIB.Common.isNullOrEmpty(item[ valueProperty ])) {
          var value = item[ valueProperty ];
          selectedValues.push(value);
        }

      }
      selectedValue = selectedValues;

    }
    else if (selectedRecords != null && typeof selectedRecords === 'string') {
      selectedValue = selectedRecords;



    }
    //single value 
    else if (selectedRecords != null && !LIB.Common.isNullOrEmpty(selectedRecords[ valueProperty ])) {
      // console.log('FormAutocomplete change valueProperty:',valueProperty);
      //console.log('FormAutocomplete change selectedRecords:',selectedRecords);

      selectedValue = selectedRecords[ valueProperty ];
      //console.log('FormAutocomplete change selectedValue:',selectedValue);

      var newValLabel = valueProperty;

      if (valueProperty === labelProperty)
        newValLabel = valueProperty + '_value';

      //console.log('FormAutocomplete change newValLabel:',newValLabel,selectedValue.toString().startsWith('Add "'));

      if (!LIB.Common.isNullOrEmpty(selectedRecords[ labelProperty ]) &&
        selectedRecords[ labelProperty ].toString().startsWith('Add "') &&
        !LIB.Common.isNullOrEmpty(selectedRecords[ newValLabel ])) {

        selectedValue = selectedRecords[ newValLabel ];
        selectedRecords[ valueProperty ] = selectedValue;
        selectedRecords[ labelProperty ] = selectedValue;

        // console.log('FormAutocomplete change selectedValue:',selectedValue);

        //console.log('FormAutocomplete change selectedRecords:',selectedRecords);

      }


      //console.log('FormAutocomplete change selectedValue:',selectedValue);

    }



    if (this.props.onChange !== undefined)
      this.props.onChange(name, selectedValue, selectedRecords,valueProperty);



    setTimeout(() => {
      //this.forceUpdate();
      var buttons = document.getElementsByTagName('button');
      if (buttons !== undefined && buttons.length > 0)
        buttons[ 0 ].focus();
    }, 100);

    //if(e.currentTarget.parentElement!==null)
    //  e.currentTarget.autofocus=false;
    //   this.forceUpdate();

  }

  public getOptionLabel(option: any, labelProperty: string, valueProperty: string): string {
     //console.log('FormAutocomplete getOptionLabel option:',option);

    if (this.props.labelRenderer !== undefined ) {
      var optionVal = this.props.labelRenderer(option);
      // console.log('FormAutocomplete getOptionLabel optionVal:',optionVal);
      if(LIB.Common.isNullOrEmpty(optionVal) || optionVal.includes('undefined'))
        return '';
      return optionVal;
    }  
    
    if (LIB.Common.isNullOrEmpty(valueProperty) &&
      LIB.Common.isNullOrEmpty(labelProperty)) {
      //console.log('Autocomplete getOptionLabel option:'+option);
      return option.toString();
    }

    if (LIB.Common.isNullOrEmpty(option) || LIB.Common.isNullOrEmpty(option[ labelProperty ])) {
      return '';
    }
    var labelVal: string = option[ labelProperty ].toString();

    // console.log('FormAutocomplete getOptionLabel result:',labelVal);

    return labelVal;
  }


  public getValue(data:any[],valueProperty:string, value:any){



   // console.log('FormAutocomplete getValue data:',data);
   // console.log('FormAutocomplete getValue valueProperty:',valueProperty);
   //console.log('FormAutocomplete getValue value:',value);

    if(LIB.Common.isNullOrEmpty(data) 
    || !Array.isArray(data) 
    || LIB.Common.isNullOrEmpty(valueProperty) 
    || LIB.Common.isNullOrEmpty(value)){
      return {};
    } 

   // var idVal = value['ownerId'];
    //console.log('FormAutocomplete getValue idVal:',idVal);
    //console.log('FormAutocomplete getValue value:',value);
 
    // console.log('FormAutocomplete getValue data:',data);
    // console.log('FormAutocomplete getValue valueProperty:',valueProperty);
    // console.log('FormAutocomplete getValue value[valueProperty]:',value[valueProperty]);
 
     var result:any=[];

     if(!this.props.multiple)
      result = data?.find(x=>x[valueProperty]==value[valueProperty] && value[valueProperty]!=undefined);
    else
      result = data?.filter((item) => value?.findIndex((c:any)=> c[valueProperty]==item[valueProperty])>-1 );

    // console.log('FormAutocomplete getValue result:',result);


    return result;

  }

  public isOptionEqualToValue2(option:any, val:any):boolean{

    console.log('FormAutocomplete isOptionEqualToValue2 option:',option);
    console.log('FormAutocomplete isOptionEqualToValue2 val:',val);
 
    return false;

  }
  public isOptionEqualToValue(data:any[],valueProperty:string, value:any):boolean{
  
    if(LIB.Common.isNullOrEmpty(data) 
    || !Array.isArray(data) 
    || LIB.Common.isNullOrEmpty(valueProperty) 
    || LIB.Common.isNullOrEmpty(value)){
      return false;
    } 

    var result = data.findIndex(x=>x[valueProperty]==value[valueProperty]);
 
    return (result>-1);
 
  }


  public render() {

    var data = this.props.data;
    var valueProperty = '';
    var labelProperty = 'name';
    var label = this.props.label;
    var name = this.props.name;

    var value = this.props.value;

    if(this.props.hidden)
        return null;

    if (LIB.Common.isNullOrEmpty(data) || !Array.isArray(data))
      data = [];

    if (this.props.valueProperty != undefined)
      valueProperty = this.props.valueProperty;

    if (this.props.labelProperty != undefined)
      labelProperty = this.props.labelProperty;


    if (LIB.Common.isNullOrEmpty(valueProperty))
      valueProperty = name;

    var textFieldStyle = this.props.textFieldStyle;
    var textFieldClassName = this.props.textFieldClassName;
    // if (LIB.Common.isNullOrEmpty(textFieldClassName))
    //    textFieldClassName='standardAutocompleteTextField';

    if (LIB.Common.isNullOrEmpty(textFieldStyle))
    textFieldStyle = { paddingTop: 20, marginBottom: 10 };
      //textFieldStyle = { paddingTop: 30, marginBottom: 10 };


    var textFieldLabelStyle: any = this.props.textFieldLabelStyle;
    if (LIB.Common.isNullOrEmpty(textFieldLabelStyle))
      textFieldLabelStyle = { style: { marginTop: -15 } };
    else
      textFieldLabelStyle = { style: textFieldLabelStyle };


      var style: any = this.props.style;
      if (LIB.Common.isNullOrEmpty(style))
         style =  { paddingBottom: 20, paddingTop: 0, paddingLeft: 0  };
      

    const filter = createFilterOptions();

     //console.log('FormAutocomplete render value:',value);

    return (<Autocomplete forcePopupIcon={true}
      multiple={this.props.multiple}
      style={style}
      options={data}
      disabled={this.props.disabled}
     // value={value || ''}
      value={this.getValue(data,valueProperty, value)}
     //isOptionEqualToValue={(option:any, val:any)=>this.isOptionEqualToValue(data,valueProperty, value)}
   //  isOptionEqualToValue={(option:any, val:any)=>{return false}}

      onChange={(e: any, selectedRecords: any) => this.onChange(e, name, selectedRecords, labelProperty, valueProperty)}

      getOptionLabel={(option: any) => this.getOptionLabel(option, labelProperty, valueProperty)}
      //freeSolo={this.props.allowNewEntries}
      filterOptions={(options, params) => {
        //console.log('Autocomplete filterOptions:',options, params);

        const filtered = filter(options, params);

        if (!this.props.allowNewEntries)
          return filtered;

        //console.log('Autocomplete filtered:',filtered);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {

          var newItem: any = {}
          newItem[ labelProperty ] = `Add "${params.inputValue}"`;
          if (labelProperty !== valueProperty)
            newItem[ valueProperty ] = params.inputValue;
          else
            newItem[ valueProperty + '_value' ] = params.inputValue;

          // console.log('Autocomplete newItem:',newItem);
          filtered.push(newItem);

        }

        return filtered;
      }}

      id={name}
      renderInput={(params) =>
        <TextField
         // variant='standard'
          {...params}
          label={label}
          name={name}
          {...App.AppBase.getInputErrorProps(name)}

          //onChange={(e)=>{console.log('Autocomplete textfield change');}}
          margin="normal" fullWidth
          style={textFieldStyle}
          className={textFieldClassName}
          InputLabelProps={textFieldLabelStyle}
    />}
    />
    );
  }
}

