import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class CMSState extends LIB.BaseStateModel {


  public vm: App.CMSVm;
  
  public contentPages: Api.ContentPage[];
  public contentPagesCount:number=0;
  public contentPagesFullList: Api.ContentPage[];
  public contentPageSearchFilter: Api.ContentPageFilterVm = new Api.ContentPageFilterVm();
  public hasContentPageDataLoaded: boolean = false;
  public contentPageSm: Api.ContentPage = new Api.ContentPage();
  public isContentPageCreateUpdatePending: boolean = false;

  public categoryTypes:Api.CategoryType[];
  public contentSites:Api.ContentSite[];
  public contentStatusTypes:Api.ContentStatusType[];
  public contentTypes:Api.ContentType[];
  

  public isSearching: boolean;


  constructor() {

    super();

    this.vm = new App.CMSVm();
   
  }



}