

export class MSingleFileUploadVm {  

    existingFileName?:string ='';
    existingFilePath:string='';
    newFileName:string='';
    newFile:any;
    viewMode:string='upload'

    constructor() { 
       //super();
    }
     


}