import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class MyAccountCandidateReducers {


    constructor() {

    }

    public static getReducers(state: App.MyAccountState | undefined, action: App.AppAction): App.MyAccountState {
        var str = "";

        var result = new App.MyAccountState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("MY_ACCOUNT_CANDIDATE"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var myAccountService = new App.MyAccountService();
        var attachmentService = new App.AttachmentService();
    

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_API_ERROR:{
                console.log('MY_ACCOUNT_CANDIDATE_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                var vm = result.vm;
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isCandidateCreateUpdatePending = false;
                vm.isActionInProgress = false;
                break;
            }
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_LIST_DATA_RECEIVED: {
                console.log('MY_ACCOUNT_CANDIDATE_LIST_DATA_RECEIVED:');
                result = { ...state };
                result.candidates = action.payload.candidates;
                result.candidateRelocationTypes = action.payload.candidateRelocationTypes;
                result.candidateVisaTypes = action.payload.candidateVisaTypes;
                result.partnershipTypes = action.payload.partnershipTypes;

                result.hasCandidateDataLoaded = true;
                break;
            }
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_ADD_REQUEST: {
                console.log('MY_ACCOUNT_CANDIDATE_ADD_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;

                vm.setSelectedCandidate(data);
                myAccountService.createCandidate(data);

                result.isCandidateCreateUpdatePending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_ADD_SUCCESS: {
                console.log('MY_ACCOUNT_CANDIDATE_ADD_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };

                result.candidates.splice(0, 0, item); //push at the beginning
                
               // result.candidates.push(item);
 
                vm.isCandidatesAddOpen=false;
                vm.isActionInProgress=false;
                vm.apiErrorMessage='';
                result.isCandidateCreateUpdatePending = false;
               // item.resume = vm.selectedCandidate?.resume;
                vm.setSelectedCandidate(item);
                  
               //console.log('MY_ACCOUNT_CANDIDATE_ADD_REQUEST candidates:', result.candidates);
               result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPDATE_REQUEST: {
                console.log('MY_ACCOUNT_CANDIDATE_UPDATE_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                myAccountService.updateCandidate(data);


                result.isCandidateCreateUpdatePending = true;
                result.forceUpdateCount++;
                console.log('MY_ACCOUNT_CANDIDATE_UPDATE_REQUEST isCandidateCreateUpdatePending:',result.isCandidateCreateUpdatePending);
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPDATE_SUCCESS: {
                console.log('MY_ACCOUNT_CANDIDATE_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               

                let resume = vm.selectedCandidate?.resume;
                  
                var updated =  App.CandidateManager.update(item,result.candidates);
                
                /* if(updated && resume!=undefined){
                    resume.candidateId = item.candidateId;
                }
 
                if(LIB.Common.isNullOrEmpty(resume?.file)){
                   

                    vm.isCandidatesEditOpen=false;
                }
               */
                vm.isCandidatesEditOpen=false;


                vm.isActionInProgress=false;
                vm.apiErrorMessage='';
                result.isCandidateCreateUpdatePending = false;
                vm.setSelectedCandidate(item);
                  
               // console.log('MY_ACCOUNT_CANDIDATE_UPDATE_SUCCESS vm:',vm);


                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_DELETE_REQUEST: {
                console.log('MY_ACCOUNT_CANDIDATE_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                myAccountService.deleteCandidate(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_DELETE_SUCCESS: {
                console.log('MY_ACCOUNT_CANDIDATE_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedCandidate?.candidateId;
                var success = action.payload;
 
                if(success)
                    App.CandidateManager.deleteById(itemId,result.candidates);
                
                vm.isCandidatesDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedCandidate();
                  
                result.forceUpdateCount++;
                break;
            }   

            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPLOAD_REQUEST: {
                console.log('MY_ACCOUNT_CANDIDATE_UPLOAD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                let item = action.payload;
               
                 item.candidateId = vm.selectedCandidate?.candidateId;
                 item.recordId = vm.selectedCandidate?.candidateId;

                 if(LIB.Common.isNullOrEmpty(item?.file)){
                    result.apiErrorMessage="MY_ACCOUNT_CANDIDATE_UPLOAD_REQUEST file is empty!";
                    console.error(result.apiErrorMessage);
                    result.forceUpdateCount++;
                    break;
                }

                attachmentService.candidateUpload(item);

                result.isCandidateCreateUpdatePending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS: {
                console.log('MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

              //  var itemId:any = vm.selectedCandidate?.candidateId;
                var data = action.payload; 
               // vm.selectedCandidate.resume = data;


                let item = result.candidates.find((c) => c.candidateId === data.candidateId);
                console.log('MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS: item', item);
                console.log('MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS: data.candidateId', data.candidateId);
                console.log('MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS: result.candidates', result.candidates);
                //let item = result.candidates[index];
                if (item){
                    item.resume = data;
                }
                    
              //  var updated =  App.CandidateManager.update(item,result.candidates);
               
                console.log('MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS vm.selectedCandidate:', vm.selectedCandidate);

                vm.isCandidatesEditOpen=false;
                vm.isCandidatesAddOpen=false;
                result.isCandidateCreateUpdatePending = false;
                vm.setSelectedCandidate();
                  
                result.forceUpdateCount++;
                break;
            }   
           
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}