import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationManager extends LIB.BaseManager{


  constructor() {
    super('userId');
  }
  
   public static deleteById(userId: number, data: Api.AppUser[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.userId === userId);
    data.splice(index, 1);
    return true;
  } 

  public static deleteProspectById(prospectId: number, data: Api.Prospect[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.prospectId === prospectId);
    data.splice(index, 1);
    return true;
  } 

  public static deleteEmailTemplateById(emailTemplateId: number, data: Api.EmailTemplate[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.emailTemplateId === emailTemplateId);
    data.splice(index, 1);
    return true;
  } 

  public static deleteCandidateById(candidateId: number, data: Api.Candidate[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.candidateId === candidateId);
    data.splice(index, 1);
    return true;
  } 

  
  public static deleteJobById(jobId: number, data: Api.Job[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.jobId === jobId);
    data.splice(index, 1);
    return true;
  } 

  public static deleteCompanyById(companyId: number, data: Api.Company[] ): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.companyId === companyId);
    data.splice(index, 1);
    return true;
  } 

  
/*
  public static getById(candidateId: number, data: Api.Candidate[]) {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(candidateId) )
      return null;

    var found = data.find((item) => item.candidateId === candidateId);
    
    
    if(LIB.Common.isNullOrEmpty(found))
      return null;
 
    return found;
  } */
 
  public static update(item: Api.AppUser, data: Api.AppUser[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.userId == undefined || item?.userId < 1)
      return false;

    let index = data.findIndex((c) => c.userId === item.userId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }

  public static updateEmailTemplate(item: Api.EmailTemplate, data: Api.EmailTemplate[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.emailTemplateId == undefined || item?.emailTemplateId < 1)
      return false;

    let index = data.findIndex((c) => c.emailTemplateId === item.emailTemplateId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }

  public static updateProspect(item: Api.Prospect, data: Api.Prospect[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.prospectId == undefined || item?.prospectId < 1)
      return false;

    let index = data.findIndex((c) => c.prospectId === item.prospectId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }

  public static updateCandidate(item: Api.Candidate, data: Api.Candidate[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.candidateId == undefined || item?.candidateId < 1)
      return false;

    let index = data.findIndex((c) => c.candidateId === item.candidateId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }
  
  public static updateJob(item: Api.Job, data: Api.Job[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.jobId == undefined || item?.jobId < 1)
      return false;

    let index = data.findIndex((c) => c.jobId === item.jobId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }
  


  public static updateCompany(item: Api.Company, data: Api.Company[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.companyId == undefined || item?.companyId < 1)
      return false;

    let index = data.findIndex((c) => c.companyId === item.companyId);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }
  

  
}