
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class CandidateListVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;
 
    public selectedCandidate: Api.Candidate;
    public selectedCandidateOriginal: Api.Candidate;
    
    public isRequestResumeOpen: boolean = false;
    public isViewCandidateOpen: boolean = false;

    

    constructor() {

        super();
    }

    public setSelectedCandidate(item: Api.Candidate | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.Candidate();
 
        this.selectedCandidate = item;
        this.selectedCandidateOriginal = LIB.ObjectHelper.clone(this.selectedCandidate);

    }

    


}