import * as React from 'react';
import {Box} from '@mui/material';
import * as LIB from '_LIB';
import { CSSProperties } from "react";




interface TabPanelProps {
    name?:string;
    selected?: boolean;
    index?: number;
    selectedKey?:string;
    //otherProps?:any;
    children?: React.ReactNode;
    tabPanelStyle?:CSSProperties
    tabBoxStyle?:CSSProperties

}


export class TabPanel extends React.Component<TabPanelProps, {}> {
  

    public render() {

        var selected = this.props.selected;
        var selectedKey = this.props.selectedKey;
        var key = this.props.name;

        if(!LIB.Common.isNullOrEmpty(selectedKey) && selectedKey===key)
            selected=true;

        if(selected===undefined || selected===false)
                return null;
         
      console.log('TabPanel, selected:',selected);
      console.log('TabPanel, selectedKey:',selectedKey);
       // var index= this.props.index;

       var tabPanelStyle:CSSProperties = {width:'100%'};

        if(this.props.tabPanelStyle)
        tabPanelStyle=this.props.tabPanelStyle;
      
        var tabBoxStyle:CSSProperties = {paddingTop:0};
        if(this.props.tabBoxStyle)
        tabBoxStyle=this.props.tabBoxStyle;
      

        return (
            <div
              style={tabPanelStyle}
              role="tabpanel"
             // hidden={value !== index}
              id={`tabpanel-${key}`}
              aria-labelledby={`vertical-tab-${key}`}
             // {...this.props.otherProps}
            >
           <Box p={3} style={tabBoxStyle}>
                {this.props.children}
            </Box>
           
            </div>
          );


    }
}
 