import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon,Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import * as XGrid from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

interface MyAccountCandidateSubmitsTableProps {
  state: App.MyAccountState;
  onChange?: Function;

}

export class MyAccountCandidateSubmitsTable extends React.PureComponent<MyAccountCandidateSubmitsTableProps> {

  public myAccountService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myAccountService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountCandidateSubmitsTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }

  

  

  public getAlertColumnRenderer = (value: any, tableMeta: any, updateValue: any) => (
    <App.AlertTypeIcon name={value} />
  )


  public onSelectionModelChange(model: any[], details: XGrid.GridCallbackDetails) {

    //console.log('onSelectionModelChange model:', model)
    //console.log('onSelectionModelChange details:', details)
/* 
    var vm = this.props.state.vm;
    var state = this.props.state;

    var rows = state.jobs;

    var id = model[ 0 ];

    if (LIB.Common.isNullOrEmpty(id))
      return;

    console.log('onSelectionModelChange id:', id);

    var item = rows.find(x => x.jobId == id);

    console.log('onSelectionModelChange item:', item);

    vm.setSelectedCandidate(item);

    console.log('onSelectionModelChange selectedCandidate', vm.selectedCandidate); */


  }
 
 
  public getTableColumns() {

    var editable = false;
    var readOnly = false;
    //https://mui.com/x/api/data-grid/data-grid/ API

 /* 

    var expColumn: GridColDef = {
      headerName: 'Experience',
      field: 'experience',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.CandidateSubmit;
        var value = item.job?.experience;
        if (LIB.Common.isNullOrEmpty(value))
          return '';
        return value + '+ years'
      }
    } */
   /*  var visaTypeCol: GridColDef = {
      headerName: 'Visa',
      field: 'visaTypeNameOrOther',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.CandidateSubmit;
        var value = item.job?.experience;
        if (LIB.Common.isNullOrEmpty(value))
          return '';
        return value + '+ years'
      }
    } */
 
   /*  var resumeColumn: GridColDef = {
      headerName: 'Resume',
      field: 'resumeFile',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.CandidateSubmit;
        var value = App.CandidateManager.getFullResumePath(item?.job);

        if(LIB.Common.isNullOrEmpty(value))
            return ''; 

        return <a href={value} target='_blank' title="Resume"><Icons.AttachFile style={{ color: 'red' }} /></a>
      }
    } */
    

    var columns: GridColDef[] = [

       {
        headerName: 'Job ID',
        field: 'jobId',
        width: 80,
        editable: editable,
      },   
      {
        headerName: 'Job Title',
        field: 'jobTitle',
        width: 140,
        editable: editable,
      },
      {
        headerName: 'Your Candidate',
        field: 'candidateName',
        width: 140,
        editable: editable,
      },
      
      {
        headerName: 'Listing Agency',
        field: 'ownerCompanyName',
        width: 200,
        editable: editable,
      },
      {
        headerName: 'Listing Manager',
        field: 'ownerUserName',
        width: 170,
        editable: editable,
      },
      {
        headerName: 'Listing Manager Phone',
        field: 'ownerUserPhone',
        width: 170,
        editable: editable,
      },
      {
        headerName: 'Listing Manager Email',
        field: 'ownerUserEmail',
        width: 300,
        editable: editable,
      },
      {
        field: 'submitDate',
        headerName: 'Submit Date',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          let item = params.row as Api.CandidateSubmit;
          if(LIB.Common.isNullOrEmpty(item.submitDate))
            return '';
          return LIB.DateHelper.getFormattedShortDate(item.submitDate);
        }
      },

      
    ];


    return columns;
  }


  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    

  }

 
  public render() {
    console.log('MyAccountCandidateSubmitsTable render');
    var state = this.props.state;
    var vm = state.vm;

    /*   if (LIB.Common.isNullOrEmpty(state) || !state.hasCandidateDataLoaded)
        return <LIB.Loading />;
   */

    if(!Array.isArray(state.candidateSubmits))
        return;

    var filter = state.jobSearchFilter;
    var rows = [...state.candidateSubmits];


    console.log('MyAccountCandidateSubmitsTable render rows',rows);


    return <div style={{ marginTop: 20 }}>

      <Box sx={{ height: '70vh', width: '100%' }}>
        <DataGrid

          getRowId={(row) => row.candidateSubmitId as number}
          rows={rows}
          columns={this.getTableColumns()}
          rowCount={10}
          pageSizeOptions={[ 10 ]}
          checkboxSelection={false}
         
          onRowSelectionModelChange={this.onSelectionModelChange.bind(this)}
       
        />
      </Box>
      

    </div>
  }

};

