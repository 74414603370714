import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';


import { AdministrationCandidatesTable } from './AdministrationCandidatesTable';
import { AdministrationCandidatesAddEditModal } from './AdministrationCandidatesAddEditModal';



class AdministrationCandidates extends React.Component<App.AdministrationState, {}> {

  public adminCandidatesService: App.AdminCandidatesService;

  constructor(props: any) {
    super(props);
    this.adminCandidatesService = new App.AdminCandidatesService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationCandidates componentDidMount');

    if (!this.props.hasCandidateDataLoaded) {
      console.log('AdministrationCandidates getAdministrationCandidatesData');

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_REQUEST, null);

     
    }


  }
 

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationCandidates componentDidUpdate');

  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;

    vm.setSelectedCandidate();

    var item = vm.selectedCandidate;

    vm.isCandidatesAddOpen = true;

    console.log("set ownerId to curentuser");
    item.ownerId = App.AppBase.currentUser.userId;
    
    this.forceUpdate();

  }

  
  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');
 
    var state = this.props;
    var vm = state.vm;
  
    var itemId = vm.selectedCandidate?.candidateId;

    console.log('onDeleteConfirmClick itemId',itemId);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_CANDIDATES_DELETE_REQUEST,itemId);
  
    vm.isActionInProgress=true;

    this.forceUpdate();
 
  }

  public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.candidateSearchFilter;
    searchFilter.isSearch=true;
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_REQUEST, searchFilter);

  }

  public render() {

   // console.log('AdministrationCandidates render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var searchFilter = state.candidateSearchFilter;


    // console.log('AdministrationCandidates hasCandidateDataLoaded', state.hasCandidateDataLoaded);
    // console.log('AdministrationCandidates isServiceCallPending',this.myAccountService.isServiceCallPending);
    // console.log('AdministrationCandidates vm.isActionInProgress',vm.isActionInProgress);


    var createBtn: any = null;

    if (currentUser.hasRole(App.UserRoleTypeEnum.BenchRecruiter)) {
      createBtn = <Button
                  id="btnCreate"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                  color="primary"

                  onClick={() => this.onCreateClick()}
                >Create</Button>
    }


    return <App.MainPage
      pageTitle="Administration > Candidates"
      state={vm}
      hasDataLoaded={state.hasCandidateDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      errorMessage={state.apiErrorMessage}
      isChildPage={true}
    >
      <div style={{   width: '85vw' }} >
      {<LIB.MSearchBar
          filter={searchFilter}
          onSearchClick={() => this.onSearchClick()}
          isSearchInProgress={state.isServiceCallPending}
        />}

        <div style={{ textAlign: 'left', width: '85vw' }} >

          {createBtn}

        </div>

        <AdministrationCandidatesTable state={state} onChange={() => this.forceUpdate()} />



        <AdministrationCandidatesAddEditModal state={state} onChange={() => this.forceUpdate()} />


      </div>



      <LIB.ConfirmModal 
    visible={vm.isCandidatesDeleteOpen}
    onCancelClick={ ()=>{vm.isCandidatesDeleteOpen=false;this.forceUpdate();}}
    onConfirmClick={()=>this.onDeleteConfirmClick()}
    title={`Confirm Delete: (${vm.selectedCandidate?.candidateId}) ${vm.selectedCandidate?.name}`}
    content={`Are you sure you want to delete "${vm.selectedCandidate?.name}" candidate?`}
    confirmButtonLabel="Delete" 
    disabled={vm.isActionInProgress}
    />
 

    </App.MainPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationCandidates as any);

