import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon,Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import * as XGrid from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

interface MyAccountCandidateListTableProps {
  state: App.MyAccountState;
  onChange?: Function;

}

export class MyAccountCandidateListTable extends React.PureComponent<MyAccountCandidateListTableProps> {

  public myAccountService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myAccountService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountCandidateListTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }


  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, params: XGrid.GridRenderCellParams) {

    var vm = this.props.state.vm;
    //var target:any = e.target;
    //console.log(e.currentTarget); 
    if (key == "Edit") {
      vm.isCandidatesEditOpen = true;
    }
    else if (key == "Delete") {
      vm.isCandidatesDeleteOpen = true;

    }

    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();
  }

  public getActionMenuItems(params: XGrid.GridRenderCellParams): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    //var vm = state.vm;
    var candidates = state.candidates;

    var item = candidates.find(x => x.candidateId === params.row.candidateId)
    var isReadOnly = false;

    /* 
        if(item!==undefined)
          isReadOnly = (item.workflowRecord.userPermissions.canEdit===false); */

    var result = [
      <MenuItem key="Edit"
        onClick={(e) => this.onActionMenuItemClick(e, 'Edit', params)}
      >Edit</MenuItem>,
      <MenuItem key="Renew"
      onClick={(e) => this.onActionMenuItemClick(e, 'Edit', params)}
    >Renew</MenuItem>,
      <MenuItem key="Delete"
        onClick={(e) => this.onActionMenuItemClick(e, 'Delete', params)}
      >Delete</MenuItem>

    ];

    return result;


  }

  

  public getAlertColumnRenderer = (value: any, tableMeta: any, updateValue: any) => (
    <App.AlertTypeIcon name={value} />
  )


  public onSelectionModelChange(model: any[], details: XGrid.GridCallbackDetails) {

    //console.log('onSelectionModelChange model:', model)
    //console.log('onSelectionModelChange details:', details)

    var vm = this.props.state.vm;
    var state = this.props.state;

    var rows = state.candidates;

    var id = model[ 0 ];

    if (LIB.Common.isNullOrEmpty(id))
      return;

    console.log('onSelectionModelChange id:', id);

    var item = rows.find(x => x.candidateId == id);

    console.log('onSelectionModelChange item:', item);

    vm.setSelectedCandidate(item);

    console.log('onSelectionModelChange selectedCandidate', vm.selectedCandidate);


  }

  public setSelected(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var found = state.candidates.find(x => x.candidateId === id);

    vm.setSelectedCandidate(found);
  }

  public onNameClick(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.candidateSm;

    this.setSelected(id);

    vm.isCandidatesEditOpen = true;

    if (this.props.onChange !== undefined)
      this.props.onChange();

    this.forceUpdate();

  }

  public getTableColumns() {

    var editable = false;
    var readOnly = false;
    //https://mui.com/x/api/data-grid/data-grid/ API


    var actionsColumn: GridColDef = {
      field: '',
      headerName: 'Actions',
      width: 80,
      maxWidth: 80,
      sortable: false,
      disableColumnMenu: true,
 
      renderCell: (params: XGrid.GridRenderCellParams) => {


        var menuItems = this.getActionMenuItems(params);
        return LIB.XGridHelper.getActionsButton(menuItems);


      }
    }

    var expColumn: GridColDef = {
      headerName: 'Experience',
      field: 'experience',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.Candidate;
        var value = item.experience;
        if (LIB.Common.isNullOrEmpty(value))
          return '';
        return value + '+ years'
      }
    }
    var visaTypeCol: GridColDef = {
      headerName: 'Visa',
      field: 'visaTypeNameOrOther',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.Candidate;
        var value = item.experience;
        if (LIB.Common.isNullOrEmpty(value))
          return '';
        return value + '+ years'
      }
    }




    var nameColumn: GridColDef = {
      field: 'name',
      headerName: 'Name',
      width: 140,
      renderCell: (params: XGrid.GridRenderCellParams) => {

        var item = params.row as Api.Candidate;
        var value = item.name;
        var id = item.candidateId;
        if (readOnly)
          return value;

        var nameLink = <Link
          key={'candidateId_' + id}
          onClick={(e: any) => this.onNameClick(id)}
          color="primary">{value ? value : ''}</Link>;

        if (item?.sortWeight != undefined && item.sortWeight > 0) {

          return App.FeaturedManager.getFeaturedStar(nameLink);

        }
        else
          return nameLink;


      }
    }


    var hotColumn: GridColDef = {
      headerName: 'Hot',
      field: 'isHot',
      width: 50,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.Candidate;
        var value = item.isHot;
        if (value) {
          return <span title="HotList Candidate!"><Icons.Whatshot style={{ color: 'red' }} /></span>
        }
        return '';
      }
    }

    var statusColumn: GridColDef = {
      headerName: 'Status',
      field: 'statusName',
      width: 110,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.Candidate;

        return <div>
            {App.FeaturedManager.getStatusNameTag(item)}
            {App.FeaturedManager.getExpiringTag(item)}
        </div>
         
      }
    }

    var columns: GridColDef[] = [

      {
        headerName: 'ID',
        field: 'candidateId',
        width: 80,
        editable: editable,
      },
      nameColumn,
 
      {
        headerName: 'Title',
        field: 'title',
        flex:1,
        editable: editable,
      },
      {
        headerName: 'Location',
        field: 'location',
        //  width: 200,
        editable: editable,
      },
      /* {
        headerName: 'Skills',
        field: 'skills',
        width: 140,
        editable: editable,
      }, */
      expColumn,
      {
        headerName: 'Visa',
        field: 'visaTypeNameOrOther',
        width: 100,
        editable: editable,
      },
      {
        headerName: 'Relocation',
        field: 'relocationTypeNameOrOther',
        width: 120,
        editable: editable,
      },
      hotColumn,
      {
        field: 'resumeRequestCount',
        headerName: 'Resume Requests',
        width: 90,
        renderHeader: (params) => (
          <Tooltip title="Resume Requests" arrow placement="right"  >
          <div style={{ fontWeight:'500', width:60, overflow:'visible', lineHeight: "1.43rem", whiteSpace: "break-spaces"}}>
            Resume Requests
            </div>
            </Tooltip>
         )
        // editable: editable,
      },
      statusColumn,
      {
        field: 'expireDate',
        headerName: 'Expire Date',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          let item = params.row as Api.Candidate;
          if(LIB.Common.isNullOrEmpty(item.expireDate))
          return '';
          return LIB.DateHelper.getFormattedShortDate(params.row.expireDate);
        }
      },
      {
        field: 'datePublished',
        headerName: 'Published',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          let item = params.row as Api.Candidate;
          if(LIB.Common.isNullOrEmpty(item.datePublished))
            return '';
          return LIB.DateHelper.getFormattedShortDate(item.datePublished);
        }
      },

      actionsColumn

    ];


    return columns;
  }


  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    // vm.selectedRowIndex = rowMeta.rowIndex;

    //todo forceUpdate();

  }


  public onExportClick() {
    console.log('onExportClick');
    var state = this.props.state;

    var rows = state.candidates;

    var includeCols = 'candidateId,name,title,skills,location,experience,visaTypeNameOrOther,relocationTypeNameOrOther,resumeRequestCount,statusName,datePublished';

    LIB.DownloadHelper.downloadCSVTable(rows, 'Candidates',includeCols);

  }

  public render() {
    console.log('MyAccountCandidateListTable render');
    var state = this.props.state;
    var vm = state.vm;

    /*   if (LIB.Common.isNullOrEmpty(state) || !state.hasCandidateDataLoaded)
        return <LIB.Loading />;
   */
    var filter = state.candidateSearchFilter;
    var rows = [...state.candidates];


    return <div style={{ marginTop: 20 }}>

      <Box sx={{ height: '65vh', width: '100%' }}>
        <DataGrid

          getRowId={(row) => row.candidateId as number}
          rows={rows}
          columns={this.getTableColumns()}
          rowCount={100}
          pageSizeOptions={[ 1000 ]}
          checkboxSelection={false}
          
          // disableSelectionOnClick
          // experimentalFeatures={{ newEditingApi: true }}
          //   onRowEditCommit={(id: XGrid.GridRowId, e: any) => { console.log('onRowEditCommit:', id, e) }}
          //  onRowEditStart={(params: XGrid.GridRowParams, e: any) => { console.log('onRowEditStart:', params, e) }}
          //   onRowEditStop={(params: XGrid.GridRowParams, e: any) => { console.log('onRowEditStop:', params, e) }}
          // onCellEditCommit={(params: XGrid.GridCellEditCommitParams, e: any) => { console.log('onCellEditCommit:', params, e) }}
          onRowSelectionModelChange={this.onSelectionModelChange.bind(this)}
        // processRowUpdate={(newRow:any, oldRow:any)=>{console.log('processRowUpdate:', newRow, oldRow)}}
        /*      components={{
              Toolbar: CustomToolbar,
              }} */
        />
      </Box>
      <div className='tableLinkBtn'>
        <Link underline="none"
          onClick={() => this.onExportClick()}
        >
          <Icons.Download fontSize="medium" />
          <span style={{ paddingBottom: 10, verticalAlign: 'top' }}>EXPORT</span>
        </Link>
      </div>



    </div>
  }

};

