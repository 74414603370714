import * as React from 'react';
import { connect } from 'react-redux';
import {
  Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button,
  Grid, Backdrop, CircularProgress, InputAdornment, FormControlLabel, Checkbox, Tooltip
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { Alert } from '@mui/material';
import * as UI from '@mui/material/';

import './Pricing.css';


interface BillingPricingTableProps {
  state: App.AccountState;
  children?: React.ReactNode;

}

export class BillingPricingTable extends React.Component<BillingPricingTableProps> {

  public validationManager: App.ValidationManager;
  public profileManager: App.ProfileManager;
  public jobService: App.JobService;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();
    this.profileManager = new App.ProfileManager();
  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('BillingPricingTable componentDidMount');

    if (!this.props.state.hasBillingPlansDataLoaded) {
      App.AppStore.dispatch(App.ActionTypes.ACCOUNT_BILLING_PLANS_REQUEST);
    }

  }

  public resetData() {
    console.log('BillingPricingTable resetData');
    var profile = this.props.state.profile;

    profile.setData(this.props.state.profileOriginal);
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('BillingPricingTable componentDidUpdate');


  }



  public onSubscribeClick(plan:Api.BillingPlan) {
    console.log('onSubscribeClick plan',plan);


    var state = this.props.state;
    var vm = state.vm;

    if(vm.isActionInProgress){
      console.log('onSubscribeClick ActionInProgress..');
      return;
    }
 
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_BILLING_CREATE_STRIPE_SESSION_REQUEST, plan);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }


  public getPlanBox(plan: Api.BillingPlan, priorPlan: Api.BillingPlan, index: number) {

    if (plan == undefined)
      return null;

    let offerPrice = "";

    var state = this.props.state;
    var vm = state.vm;

    var startIcon:any = undefined;

    if(vm.isActionInProgress){
      startIcon= <UI.CircularProgress style={{color:'white'}} size="1rem"  />;
    }

    return <Grid key={'plan'+plan.billingPlanId} item xs={12} sm={12} md={6} lg={4} xl={4}   >


        <div className="price-and-features-card hide-ctas mb-5 mb-lg-0">
          {plan.isMostPopular ? (
            <div className="tier-color-bar" style={{ backgroundColor: 'rgb(0, 133, 255)' }}></div>
          ) : (
            <div className="tier-color-bar"></div>
          )}
          <div className="price-card-price-container">
            <h2 className="price-card-tier-name">
              <span className="tier-name-text">{plan.name}</span>
              {plan.isMostPopular && (
                <span className="tier-name-extra with-square-top-text">
                  <span className="arrow" style={{ borderRightColor: 'rgb(0, 133, 255)' }}></span>
                  <span className="extra-text" style={{ backgroundColor: 'rgb(0, 133, 255)' }}>
                    Most Popular
                  </span>
                </span>
              )}
            </h2>

            <div className="pricing-section">
              <div className="price-card-price-per-month" style={{ color: 'rgb(51, 51, 51)' }}>
                {!offerPrice ? (
                  <span className="plan-price" style={{ color: 'rgb(162, 93, 220)' }}>
                    ${plan.monthlyPrice?.toFixed(0)}
                  </span>
                ) : (
                  <span style={{ color: 'rgb(162, 93, 220)' }}>
                    <span style={{ textDecoration: 'line-through' }}>${plan.monthlyPrice?.toFixed(0)}</span>
                  </span>
                )}
                <span className=""> / month</span>
                {offerPrice && (
                  <div style={{ color: 'red' }}>
                    ${offerPrice}/month
                    <br />
                    ** SPECIAL OFFER **
                  </div>
                )}
              </div>
            </div>


            <div className="text-center" style={{ marginBottom: '20px', marginTop: '20px' }}>

            {plan.isCurrentPlan && (
              <b>**Current Plan**</b>


            )}
            {!plan.isCurrentPlan && (
              <Button
                id="btnSave"
                color="primary"
                variant="contained"
                className="btn-purple "
                style={{ marginLeft: 15 }}
                prefix=''
                onClick={()=>this.onSubscribeClick(plan)}
                //disabled={vm.isActionInProgress}
                startIcon={startIcon}
  
  
              >
             
                Subscribe</Button>

              )}
              {/*      <a href={`${plan.buttonUrl}`} className="btn-danger btn-sm btn-purple btn-text-sm">
                   {plan.buttonLabel}  

                 
              </a> */}
            </div>


            <div className="tier-description">{plan.description}</div>


          </div>

          <div className="separator" style={{ borderColor: 'rgb(197, 199, 208)' }}></div>

          <div className="tier-includes-container">
            <div className="tier-includes-list">
              {plan.features?.map((feature, featureIndex) => {
                let value = feature.value;
                if (value === '-1') {
                  value = 'Unlimited -';
                }


                return (
                  <div className="price-card-tier-feature" key={featureIndex}>


                    {feature?.billingPlanFeatureTypeId !== App.BillingPlanFeatureTypeEnum.Info && (
                      <>
                        <span style={{ minWidth: '20px', display: 'inline-block' }}>
                          {LIB.ConvertHelper.isBool(value) ? (
                            <Icons.Check style={{ color: 'lawngreen' }} />
                          ) : (
                            value
                          )}
                        </span>
                        <span>{feature.billingPlanFeatureType?.label}</span>

                        <Tooltip arrow placement="right"
                          title={`${feature?.billingPlanFeatureType?.description} ${feature?.valueDescription || ''}`}
                        >
                          <Icons.Info style={{ color: 'gray' }} />
                        </Tooltip>

                      </>
                    )}
                    {feature?.billingPlanFeatureTypeId === App.BillingPlanFeatureTypeEnum.Info && (
                      <>
                        <span>{value}</span>
                        {feature?.valueDescription && (

                          <Tooltip arrow placement="right"
                            title={`${feature?.valueDescription}`}
                          >
                            <Icons.Info style={{ color: 'gray' }} />
                          </Tooltip>


                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>



      </Grid>;
    

  }


  public render() {

    console.log('BillingPricingTable render');

    var state = this.props.state;
    var item = state.profile;
    var company = state.company;

    var user = state.profile;//App.AppBase.currentUser;
    //console.log('render user',user);


    var theme = App.AppBase.theme;


    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var plans = state.billingPlans;

    if (LIB.Common.isNullOrEmpty(plans) || plans.length < 1)
      return null;

    console.log('BillingPricingTable plans', plans);

    var header = <h3>Upgrade: Select a Plan</h3>;

    if(company.hasActiveBillingPlan)
        header = <h3>Billing Plans:</h3>;

    let count = 0;

    return (

      <div className="container" style={{ marginBottom: '50px' }}>

       {header}

        <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {
              plans?.map((plan, index) => {

                const priorPlan = index > 0 ? plans[ index - 1 ] : plan;

                var item = this.getPlanBox(plan, priorPlan, index);

                return item;
              })}
          </Grid>
        </div>
      </div>
    );


  }
};
