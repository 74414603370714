import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, FormControl, FormHelperText, InputLabel
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';

interface ViewCandidateModalProps {
  state: App.CandidateListState;
  onChange?: Function;
}


export class ViewCandidateModal extends React.Component<ViewCandidateModalProps> {


  constructor(props: any) {
    super(props);

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('ViewCandidateModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('ViewCandidateModal componentDidUpdate');


  }


  public onSubmitClick(e: React.ChangeEvent<{}>) {
    console.log('onSubmitClick');


    var state = this.props.state;
    var vm = state.vm;
    vm.isViewCandidateOpen = false;
    vm.isRequestResumeOpen = true;
   /*  var item = vm.selectedCandidate;

    console.log('onSubmitClick selectedCandidate', item);

    

    var sm = state.resumeRequestSm;
    sm.candidateId = item.candidateId;

    if (vm.isViewCandidateOpen)
      App.AppStore.dispatch(App.ActionTypes.CANDIDATE_LIST_REQUEST_RESUME, sm);

    vm.isActionInProgress = true;

    App.RouteManager.routeToPath('?'); */

    this.forceUpdate();

    if (this.props.onChange)
          this.props.onChange();
  }

  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;

    vm.isViewCandidateOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';
    App.RouteManager.routeToPath('?');

    this.forceUpdate();

  }

  /* public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.resumeRequestSm;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    //todo this.validationManager.validateJobCreateUpdate(name, item);


    this.forceUpdate();
  } */

  public getForm() {
    //console.log('ViewCandidateModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCandidate;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var textStyle: CSSProperties = {
      fontSize: 17,
      padding: 20,
    };


    var item = vm.selectedCandidate;



    if (item === undefined)
      return null;

    var sm = state.resumeRequestSm;
    sm.candidateId = item.candidateId;
 

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >

        <div style={{ width: '45vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} style={textStyle} >
              <b>ID: </b>{item.candidateId}

            </Grid>
            <Grid item xs={12} sm={6} style={textStyle}
            >
              <b>Agency: </b>{item.companyName}

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} style={textStyle} >
              <b>Name: </b>{item.name}

            </Grid>
            <Grid item xs={12} sm={6} style={textStyle}
            >
              <b>Title: </b>{item.title}

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} style={textStyle}>
              <b>Location: </b>{item.location}

            </Grid>
            <Grid item xs={12} sm={6} style={textStyle}>
              <b>Experience (years): </b>{item.experience}

            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} style={textStyle}>
              <b>Visa: </b>{item.visaType?.name}

            </Grid>
            <Grid item xs={12} sm={6} style={textStyle}
            >
              <b>Relocation: </b>{item.relocationType?.name}

            </Grid>
          </Grid>

          <div style={{ fontSize: 17, marginTop: 17, marginLeft: 10, marginBottom: 10 }}>
            <b>Skills: </b>{item.skills}

          </div>

          {/* <div
            style={{ marginTop: 20 }}
          >

          </div> */}
          {/* <div>

            <TextField
              name="message" label="Message"
              value={sm.message || ''}
              {...App.AppBase.getInputErrorProps('message')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              multiline={true}
              inputProps={{ style: formInput }}
              minRows={3}
              maxRows={8}
            />
          </div>

          <Alert severity="info" style={{marginBottom:10}}>
        Request the resume from the agency by writing a Message and clicking on Request. 
        The Recruiting Agency will be notified and will respond to your request.  
        </Alert> */}

        </div>

      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {

    console.log('ViewCandidateModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isViewCandidateOpen);
    var item = vm.selectedCandidate;

    if (!isOpen)
      return;

    var title = `Candidate - `;


    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title} {item.name}
          
          </DialogTitle>
          <DialogContent >

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Close</Button>



            {App.AppBase.isUserAuthenticated &&
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={this.onSubmitClick.bind(this)}
                disabled={vm.isActionInProgress}
              >Resume Request</Button>

            }
            {!App.AppBase.isUserAuthenticated &&
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={(e) => App.RouteManager.routeToPath("/Login")}
              >Login to Continue</Button>

            }


          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 