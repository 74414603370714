import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import { Alert } from '@mui/material';

 

class ConfirmEmailToken extends React.Component<App.AccountState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('ConfirmEmailToken componentDidMount');


    if(App.AppBase.isUserAuthenticated){
      //call logout
     App.AppStore.dispatch(App.ActionTypes.ACCOUNT_LOGOUT_REQUEST);
    }  

   this.checkConfirmEmail();
 
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('ConfirmEmailToken componentDidUpdate');
  }

  
  public onResendInviteClick(e: React.ChangeEvent<{}>) {
    console.log('onResendInviteClick');

    var state = this.props;
      
    //call api
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST);

   // this.forceUpdate();

  }

  public checkConfirmEmail(){


    var state=this.props;
    var vm = state.confirmEmailVm;
    var sm = state.confirmEmailSm;

    sm.userId = LIB.UrlHelper.getQueryString('userId');
    sm.token = LIB.UrlHelper.getQueryString('token');

    console.log('ConfirmEmailToken componentDidMount sm',sm);
    
    vm.iconType = 'loading';
    vm.pageTitle = 'Confirmming Email....';
    vm.status = 'pending';

    if(LIB.Common.isNullOrEmpty(sm.userId) || LIB.Common.isNullOrEmpty(sm.token)){
      vm.iconType='error';
      vm.pageTitle='Error: Invalid Email Confirmation token';
    } 
    else{

      App.AppStore.dispatch(App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_TOKEN_REQUEST, sm);

    }
  
  }

  public getSuccessMsg(){

    var state=this.props;
    var vm = state.confirmEmailVm;

    if(vm.iconType!=='success')
        return null;

   var appInfo = App.AppBase.appInfo;
 
    return <div style={{paddingLeft:'8rem', paddingRight:'8rem', margin:'3rem'}}>
    
    <Alert severity="success">Successfully Confirmed! You can now Login and continue!</Alert>

    <div style={{marginTop:30, textAlign:'center'}}>
      <Button variant="contained" color="primary" 
                onClick={(e) => App.RouteManager.routeToPath("/Login")} 
       >Login</Button>
       </div>
     </div>
  }

  public render() {

    console.log("ConfirmEmailToken Render");
    var state=this.props;
    var vm = state.confirmEmailVm;
    var sm = state.confirmEmailSm;
    var currentUser = App.AppBase.currentUser;
 

    return <App.MainPage
      state={vm}
      hasDataLoaded={true}
      loading={false}//state.isServiceCallPending}
      showContentWhenInProgress={true}
      showModalForLoading={true}
    >
      
       <App.QuickMessageContainer
      iconType={vm.iconType}
      message={vm.pageTitle}
    >
      {/* <div style={{ margin:20 }}>{currentUser.email}</div>
  */}

      {this.getSuccessMsg()}
 

    </App.QuickMessageContainer>;


    </App.MainPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.account })
)(ConfirmEmailToken as any);
