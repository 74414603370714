import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class AdminCandidatesService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getCandidatesListData(filter:Api.CandidateFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCandidate/getCandidateListData?${urlPrams}`;
        console.log("testing : getUsersListData");
        
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
    public createCandidate(data:Api.Candidate) {
        console.log("AdminCandidatesService.createUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCandidate/createCandidate`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_CANDIDATES_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }
    public updateCandidate(data:Api.Candidate) {
        console.log("AdminCandidatesService.updateUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCandidate/updateCandidate`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_CANDIDATES_UPDATE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public deleteCandidate(candidateId:number) {
        console.log("AdminCandidatesService.deleteUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCandidate/deleteCandidate?candidateId=${candidateId}`;
        this.post(apiUrl, candidateId, 
            App.ActionTypes.ADMINISTRATION_CANDIDATES_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    

    
 
}
