import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationCandidateReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_CANDIDATES"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);


        var adminCandidatesService = new App.AdminCandidatesService();

        // const action = incomingAction as KnownAction;
        switch (action.type) {
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var searchFilter = action.payload;
                
                // prospectsService.getProspectsListData(searchFilter);
                adminCandidatesService.getCandidatesListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_RECEIVED: {
                console.log('ADMINISTRATION_CANDIDATES_LIST_DATA_RECEIVED:');
                result = { ...state };
                console.log("payload", action.payload);
                if (LIB.Common.isNullOrEmpty(result.candidateSearchFilter?.keyword)) {
                    result.candidatesFullList = action.payload.candidates;
                }
                result.candidates = action.payload.candidates;
                result.candidatesCount = action.payload.candidatesCount;
                result.candidateRelocationTypes = action.payload.candidateRelocationTypes;
                result.candidateVisaTypes = action.payload.candidateVisaTypes;
                result.listingStatusTypes = action.payload.listingStatusTypes;
 

                result.isServiceCallPending = false;
                result.hasCandidateDataLoaded = true;
                console.log("result", result);
                break;
            }

            
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_CANDIDATES_LIST_PAGE_RESET:');
                result = { ...state };
                console.log("payload", action.payload);
                result.candidates = result.candidatesFullList; 
                result.candidateSearchFilter.keyword = '';
              //  result.isServiceCallPending = false;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_CANDIDATES_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                adminCandidatesService.updateCandidate(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_CANDIDATES_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };

                App.AdministrationManager.updateCandidate(item, result.candidates);

                vm.apiErrorMessage = ''; //to clear any api error msg
                vm.isCandidatesEditOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                vm.setSelectedCandidate(item);//

                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_ADD_REQUEST: {
                console.log('ADMINISTRATION_CANDIDATES_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                adminCandidatesService.createCandidate(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_ADD_SUCCESS: {
                console.log('ADMINISTRATION_CANDIDATES_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };
                //  var item = vm.;

                console.log("item pushed: ", (item));
                // console.log(item.constructor.name); 

                result.candidates.splice(0, 0, item); //push at the beginning
                vm.apiErrorMessage = '';
                vm.isCandidatesAddOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                vm.setSelectedCandidate(); //
                console.log("vm.setSelectedCandidate();:, ", vm.setSelectedCandidate);
                // result.adminCreateProspectSm = new Api.Prospect();


                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_CANDIDATES_DELETE_REQUEST: {
                console.log('ADMINISTRATION_CANDIDATES_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var itemId = action.payload;

                adminCandidatesService.deleteCandidate(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_CANDIDATES_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_CANDIDATES_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId: any = vm.selectedCandidate?.candidateId;
                var success = action.payload;

                if (success)
                    App.AdministrationManager.deleteCandidateById(itemId, result.candidates);

                vm.isCandidatesDeleteOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                //vm.setSelectedUser();

                result.forceUpdateCount++;
                break;
            }




            default:
                result = state;
                //return result;
                break;
        }
        console.log('ADMINISTRATION_CANDIDATES_DATA_RECEIVED result2:', result);
        //  console.log('AdministrationCandidate_DATA_RECEIVED action:',action);


        if (action.postActionCallBack) {
            console.log("postactioncallback");
            action.postActionCallBack(result);
        }
        return result;
    }



}