import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class CandidateManager extends LIB.BaseManager{


  constructor() {
    super('candidateId');
  }
  
  public static deleteById(candidateId: number, data: Api.Candidate[]): boolean {

    if (!Array.isArray(data))
      return false;

    let index = data.findIndex((item) => item.candidateId === candidateId);
    data.splice(index, 1);
    return true;
  } 

  public static getById(candidateId: number, data: Api.Candidate[]) {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(candidateId) )
      return null;

    var found = data.find((item) => item.candidateId === candidateId);
    
    
    if(LIB.Common.isNullOrEmpty(found))
      return null;
 
    return found;
  }
 
  public static update(item: Api.Candidate, data: Api.Candidate[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item?.candidateId == undefined || item?.candidateId < 1)
      return false;

    let index = data.findIndex((c) => c.candidateId === item.candidateId);
    
    if (index < 0)
      return false;
      
   /*  console.log('update data',data);
    console.log('update index',index);
    console.log('update item',item);
    console.log('update data[index]',data[index]); */
   

    data[index] =  item;

    return true;
  }

  public static getFullResumePath(item:Api.Candidate | undefined){

    var filePath = item?.resume?.filePath;

    if(!filePath)
    return '';

    var val  = `${App.AppBase.appApiBaseUrl}/files/${filePath}`;

    return val;
  }



}