import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';

import { AdminMenuItem } from './AdminMenuItem';
import { AboutMenuItem } from './AboutMenuItem';
import { TutorialsMenuItem } from './TutorialsMenuItem';



import { Link as RouterLink } from 'react-router-dom';
import * as Router from 'react-router-dom';

import { createStyles,  Theme } from '@mui/material/styles';


/* 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
); */

export class TopMenuState {
  open: boolean;
  anchorEl: null | HTMLElement;
  constructor() {
    this.open = false;
    this.anchorEl = null;
  }
}
//export const LeftMenu: React.FC<App.GlobalState> = (props) => {
class TopMenu extends React.Component<App.GlobalState, TopMenuState> {

  constructor(props: any) {
    super(props);

    this.state = new TopMenuState();

  }




  public onTabChange(value: any) {
    // this.props.history.push(value);
  }


  public onLogoClick() {

    App.RouteManager.routeToPath("/");

  }

  public onMobileMenuClick() {
    console.log('onMobileMenuClick');
    var vm = this.props.globalUIState;
    vm.isMobileMenuOpen = !vm.isMobileMenuOpen;
  
    //console.log('onMobileMenuClick isMobileMenuOpen:', vm.isMobileMenuOpen);
    App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

  }

  

  public getTopButtons(){

    if(App.AppBase.isUserAuthenticated)
      return null;


    return <div  >
    <Button style={{color:'white'}}
   onClick={(e) =>App.RouteManager.routeToPath("/Login")}
    >Login</Button>
    <Button style={{color:'white'}}
      onClick={(e) =>App.RouteManager.routeToPath("/Register")}
    >Register</Button>
  </div>
  }

  public render() {
 

   // var globalData = App.AppBase.getState().globalData;
  //  var globalState = globalData.globalUIState;

    var currentUser = App.AppBase.currentUser;
    var aboutMenu = null;
    if (currentUser?.realUserIsAdmin) {
      aboutMenu = <AboutMenuItem />;
    }

     var vm = this.props.globalUIState;
     console.log('TopMenu globalUIState:',vm);

    var logoTxt = '';

 
  /*   if(!App.AppBase.isProd() && !LIB.Common.isNullOrEmpty(App.AppBase.envName))
        logoTag = `${App.AppBase.appInfo.appName} (${App.AppBase.envName})`; */

     if(!App.AppBase.isProd() && !LIB.Common.isNullOrEmpty(App.AppBase.envName))
     logoTxt = ` (${App.AppBase.envName})`;

     var logoTag:any=null;
     if(!LIB.Common.isNullOrEmpty(logoTxt))
      logoTag = <span style={{marginTop:10}}>{logoTxt}</span>

    var logo:any =   <UI.Typography variant="h6" style={{ flexGrow: 1, height:50, display:'inline-flex' }} 
    onClick={() => this.onLogoClick()} className="MouseHand" >
      <img src="/appLogo.png"  style={{border:0, height:50}} ></img>
     {logoTag}
    
 </UI.Typography>

   var isMobile = App.AppBase.getIsMobile();

   if(vm.isMobile!=isMobile){
    vm.isMobile = isMobile;
    App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

   }
  
   if(isMobile){
    logo =  <UI.Typography variant="h6" style={{ flexGrow: 1 }} onClick={() => this.onMobileMenuClick()} className="MouseHand" >
     <Icons.Menu />
 </UI.Typography>
   }
  
   //console.log('TopMenu Render isMobile',isMobile);


    return (
      <UI.AppBar position="fixed" style={{ zIndex: 7000 }}>
        <UI.Toolbar>
          {/*    <UI.IconButton edge="start"   color="inherit" aria-label="menu">
            <MenuIcon />
          </UI.IconButton> */}
        
          {logo}
         
          {aboutMenu} 
             
          <TutorialsMenuItem  globalState={vm}   />
          |
          <App.AccountMenuItem
          currentUser={App.AppBase.currentUser}
          globalState={vm}
          />
          <AdminMenuItem 
          currentUser={App.AppBase.currentUser}
          globalState={vm}
           />

            
          {this.getTopButtons()}
 

          {/*   <AdminMenuItem  />
     */}
        </UI.Toolbar>
      </UI.AppBar>
    );
  }
}
export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(TopMenu as any);



