import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';
import { Table, TableBody, TableCell, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';



export class CandidateService extends App.BaseAppService {


    constructor() {

        super();
        //this.isAccessTokenRequired = true;
    }

    public getCandidateListData(filter: Api.CandidateFilterVm | null = null, onAfterStateSet: Function | null = null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Candidate/getCandidateListData?${urlPrams}`;

        this.get(apiUrl,
            App.ActionTypes.CANDIDATE_LIST_PAGE_DATA_RECEIVED,
            App.ActionTypes.CANDIDATE_LIST_API_ERROR,
            "", null, onAfterStateSet
        );

    }

    public showSuccessMessage() {
         
        var msg = <div>
            Successfully Sent
            <br />
            Go to
            <RouterLink style={{marginRight:5, marginLeft:5}}
                to={'/MyAccount/ResumeRequests'}
                //onClick={(e: any) => this.onNameClick(id)}
                color="primary">Resume Requests</RouterLink> 
             to see the Resume
        </div>

        App.AppBase.showMessage(msg, false, 8000);

       /*  var vm = new App.GlobalUIStateVm();
        vm.selectLeftMenuItem = '/MyAccount/ResumeRequests';
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_SELECT_LEFT_MENU, vm);
 */
        var state = App.AppBase.getState();
        var vm = state.globalData.globalUIState;
        vm.countResumeRequests++;
        vm.selectLeftMenuItem = '/MyAccount/ResumeRequests';
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);
 
    }

    public requestResume(sm: Api.CandidateRequestResumeSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Candidate/requestResume`;

        this.post(apiUrl, sm,
            App.ActionTypes.CANDIDATE_LIST_REQUEST_RESUME_SUCCESS,
            App.ActionTypes.CANDIDATE_LIST_API_ERROR, '',
            () => this.showSuccessMessage()
        );

    }

}
