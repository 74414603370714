import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as Icons from '@mui/icons-material/';
import * as React from 'react';


export class FeaturedManager {

 
    public static getFeaturedStar(value:any): any {

        var result =  <div title='Featured Candidate'>{value} <span  className='featuredCandidateStarSpan'> 
        <Icons.Star style={{color:'#1890ff', paddingTop:2}} /></span> 
        </div>

        return result;
    }

    public static getListingStatusColor(listingStatusTypeId: any) {

        if (listingStatusTypeId == App.ListingStatusTypeEnum.Draft)
          return 'orange';
        else if (listingStatusTypeId == App.ListingStatusTypeEnum.Published)
          return 'green';
        else if (listingStatusTypeId == App.ListingStatusTypeEnum.Expired)
          return 'red';
    
        return '';
      }
    
    public static getStatusNameTag(item:any){
        if(LIB.Common.isNullOrEmpty(item) || item.listingStatusTypeId<1)
            return '';

        var color = App.FeaturedManager.getListingStatusColor(item?.listingStatusTypeId);
        return <span style={{ color: color, textTransform: 'uppercase', fontWeight: '500' }}>{item?.statusName}</span>
    }
    
    public static getExpiringTag(item:any){
      if(LIB.Common.isNullOrEmpty(item) || item.listingStatusTypeId<1 || item?.expiringDays<0)
          return '';

      var color = 'black';
      let warningLimit = 11;
      if(item.expiringDays < warningLimit && item.expiringDays>-1)
        color = 'red';

      return <div style={{fontSize:11,color:color}}>Expiring in {item.expiringDays} days</div>

     
  }
  

    
  

}