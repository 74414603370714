import * as LIB from '_LIB';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Table, TableBody, TableCell, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, TableSortLabel } from '@mui/material';
import { CSSProperties } from 'react';
import * as Icons from '@mui/icons-material/';
import * as React from 'react';
import * as App from 'AppReferences';


export class MUITableHelper {



  private static onMenuClick(e: React.MouseEvent<HTMLElement>) {

    var menu = e.currentTarget;
    menu.setAttribute("style", "margin:100");

  }

  public static getActionsButton(value: any, tableMeta: any, updateValue: any, menuItems: any[]) {

    //console.log('getActionsButton:',tableMeta.rowData);
    //var requestId = value;
    //console.log(tableMeta);

    return (
      <PopupState variant="popover"  >
        {(popupState) => (

          <div  >
            <Button
              {...bindTrigger(popupState)}

            >
              <Icons.Menu />
              <Icons.ArrowDropDown />

            </Button>
            <Menu  {...bindMenu(popupState)}
              onClick={popupState.close}
              id="actionMenu"
              //getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

            >
              {menuItems}


            </Menu>
          </div>
        )}
      </PopupState>
    );

  }

  public static getColumnSetupShortDate(label: string, name: string, filter: boolean = false, sortDirection: string = ""): any {

    var customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
      return LIB.DateHelper.getFormattedShortDate(value);

    };
    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter, sortDirection, customBodyRender);
    return column;
  }

  public static getColumnSetupDateTime(label: string, name: string, filter: boolean = false, sortDirection: string = ""): any {

    var customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
      return LIB.DateHelper.getFormattedDateWithAMPM(value);

    };
    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter, sortDirection, customBodyRender);
    return column;
  }


  public static getObjectChildPropertyColumnShortDate(label: string, name: string, childPropertyName: string, filter: boolean = false) {

    var customBodyRender = (value: any, tableMeta: any, updateValue: any) => {

      var propVal = LIB.MUITableHelper.getObjectChildPropertyValue(value, childPropertyName);
      if (LIB.Common.isNullOrEmpty(propVal))
        return;

      return LIB.DateHelper.getFormattedShortDate(propVal);

    };
    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter, "", customBodyRender);
    return column;
  }

  public static getObjectChildPropertyColumnDate(label: string, name: string, childPropertyName: string, filter: boolean = false) {

    var customBodyRender = (value: any, tableMeta: any, updateValue: any) => {

      var propVal = LIB.MUITableHelper.getObjectChildPropertyValue(value, childPropertyName);
      if (LIB.Common.isNullOrEmpty(propVal))
        return;

      return LIB.DateHelper.getFormattedDateWithAMPM(propVal);

    };
    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter, "", customBodyRender);
    return column;
  }

  public static getObjectChildChildPropertyColumn(label: string, name: string, childPropertyName: string,
    childChildPropertyName: string, filter: boolean = false) {

    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter);
    column.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      () => {
        var childVal = LIB.MUITableHelper.getObjectChildPropertyValue(value, childPropertyName);
        if (LIB.Common.isNullOrEmpty(childVal))
          return;

        var childChildVal = LIB.MUITableHelper.getObjectChildPropertyValue(childVal, childChildPropertyName);

        if (LIB.Common.isNullOrEmpty(childChildVal))
          return;
        return <span >{childChildVal}</span>
      }
    );
    return column;
  }

//  public static getChildObjectSortCompare(childPropertyName: string, order: string): Function {
 //   return (item1: any, item2: any) => {
  public static getChildObjectSortCompare(childPropertyName: string, sortDirection: string): Function {
        return (item1: any, item2: any) => {
    
      console.log('getObjectChildPropertyColumn: item1', item1);
      console.log('getObjectChildPropertyColumn: item2', item2);
      console.log('getObjectChildPropertyColumn: childPropertyName', childPropertyName);

      try {

        let val1 = item1.data[ childPropertyName ];
        let val2 = item2.data[ childPropertyName ];

        var result = val1.localeCompare(val2);

        console.log('getObjectChildPropertyColumn: sortCompare result', result);

        return (result) * (sortDirection === 'asc' ? 1 : -1);

      } catch (error) {
        console.log('getObjectChildPropertyColumn: error', error);

        return -1;
      }

    };
  }

  public static getObjectChildPropertyColumn(label: string, name: string, childPropertyName: string,
    filter: boolean = false) {

    // console.log('getObjectChildPropertyColumn name: ', name);


    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter);

    var customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      () => {

        var propVal = LIB.MUITableHelper.getObjectChildPropertyValue(value, childPropertyName);
        if (LIB.Common.isNullOrEmpty(propVal))
          return;
        return <span>{propVal}</span>
      }
    );

    //if(customBodyRender2!=null)
    column.options.customBodyRender = customBodyRender;

    column.options.sortCompare=(sortDirection: string) => {
       console.log('sortCompare sortDirection',sortDirection);
      return LIB.MUITableHelper.getChildObjectSortCompare(childPropertyName,sortDirection);
    }

    
    return column;
  }

  public static getCustomRenderValueColumn(label: string, name: string, getCustomVal: Function, filter: boolean = false) {

    var column = LIB.MUITableHelper.getColumnSetup(label, name, filter);
    column.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      () => {

        var propVal = getCustomVal(value);
        if (LIB.Common.isNullOrEmpty(propVal))
          return;
        return <span >{propVal}</span>
      }
    );


    return column;
  }

  public static getObjectChildPropertyValue(value: any, childPropertyName: string) {


    if (LIB.Common.isNullOrEmpty(value))
      return null;

    var propVal = value[ childPropertyName ];
    if (LIB.Common.isNullOrEmpty(propVal))
      return null;
    return propVal;
  }


  public static getColumnSetup(label: string, name: string, filter: boolean = false,
    sortDirection: string = "", customRender: any = undefined, canSort:boolean=true): any {

    var sortOrder: any = {};

    if (!LIB.Common.isNullOrEmpty(sortDirection)) {
      sortOrder = {
        name: name,
        direction: sortDirection
      }

    }
    var column =  {
      name: name,
      label: label,
      options: {
        filter: filter,
        sort: canSort,
        customBodyRender: customRender
      },
      sortOrder: sortOrder
    }

    //column.options.sortCompare = LIB.MUITableHelper.getChildObjectSortCompare;
 
    return column;
  }


  public static getColumnSetupNumberOrEmptyString(label: string, name: string,
    filter: boolean = false, sortDirection: string = ""): any {

    var customRender = (value: any, tableMeta: any, updateValue: any) => {

      var val = LIB.ConvertHelper.getNumberOrEmptyString(value, 2);
      if (LIB.Common.isNullOrEmpty(val))
        return;

      return <span>${val}</span>
    };
    var col = LIB.MUITableHelper.getColumnSetup(label, name, filter, sortDirection, customRender);


    return col;

  }

  public static getCustomColumnHeaderOld(value: any, style: CSSProperties) {//, tableMetaData: any, updateValue: any) {
    //console.log('customHeadRender2:', value)
    return (
      <TableCell key={'header_' + value.name + value.index} style={style}>
        {value.label}
      </TableCell>
    )
  }


  public static getCustomColumnHeader(columnMeta: any, handleToggleColumn: any, sortOrder: any, style: CSSProperties = {}) {//, tableMetaData: any, updateValue: any) {
    // console.log('getCustomColumnHeader2 columnMeta:', columnMeta);
    // console.log('getCustomColumnHeader2 sortOrder:', sortOrder);
    // console.log('getCustomColumnHeader2 sortDirection2:', typeof(sortDirection));
    // console.log('getCustomColumnHeader2 sortColumn:', sortColumn);
    var column = columnMeta;
    //   if( typeof(sortDirection)==="object")
    //     sortDirection = undefined;

    var sortDirection = column.name === sortOrder.name ? sortOrder.direction : undefined;
    return (
      <TableCell key={'header_' + column.name + columnMeta.index} style={{ cursor: 'pointer', ...style }}
        onClick={(val) => {
          handleToggleColumn(columnMeta.index);


        }}
      >
        {column.label}
        <TableSortLabel active={sortDirection !== undefined} direction={sortDirection} />

      </TableCell>
    )
  }



  public static getTextColumnRender(value: any, maxWidth: number = -1, maxChars: number = -1, linkTitle: string = '') {

    if (LIB.Common.isNullOrEmpty(linkTitle) && maxChars > -1)
      linkTitle = value;
    // console.log('getLinkColumnRender updateValue:',updateValue);

    if (maxChars > -1) {
      value = LIB.StringHelper.maxLength(value, maxChars);
    }

    var style: CSSProperties = {};

    if (maxWidth > -1)
      style.maxWidth = maxWidth;

    return <div title={linkTitle} style={style}>{value}</div>
  };


  public static getLastModifiedDateColumn(name: string = 'Last Modified Date') {

    var column = LIB.MUITableHelper.getColumnSetupDateTime(name, "dateModified");
    column.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { width: 170 });
    }

    return column;
  }


  public static getDateCreatedColumn(label: string = 'Date Added') {

    var column = MUITableHelper.getDateColumn(label, "dateCreated");

    return column;
  }


  public static getDateColumn(label: string, name: string) {

    var column = LIB.MUITableHelper.getColumnSetupDateTime(label, name);
    column.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { width: 170 });
    }

    return column;
  }


  public static getTableMaxWidthByWindowSize(): string {

    var maxWidth = '100vw';
    var isMobile = App.AppBase.getIsMobile();
    if (window.innerWidth < 1500 && !isMobile)
      maxWidth = '68vw';

      

    return maxWidth;

  }

}