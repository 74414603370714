import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, FormControl, FormHelperText, InputLabel, Stack, Paper, Box, Tooltip
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';


class BillingLimitReachedModal extends React.Component<App.GlobalState> {


  constructor(props: any) {
    super(props);

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('BillingLimitReachedModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('BillingLimitReachedModal componentDidUpdate');


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {

    console.log('BillingLimitReachedModal render');

    var currentUser = App.AppBase.currentUser;

    var isOpen = true;

    if (!isOpen)
      return;

    var title = `Candidate - `;

    const StatPaper = styled(Paper)(({ theme }) => ({
      width: 140,
      height: 120,
      padding: theme.spacing(2),
      ...theme.typography.body2,
      textAlign: 'center',
    }));

    var statNumber: CSSProperties = {
      fontSize: 25
    };

    var stats = this.props.globalUIState.companyStats;

    return (
      <div>
        <Dialog
          open={isOpen}
          //onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >

          </DialogTitle>
          <DialogContent >

            <div style={{ padding: 10, width: '100%', textAlign: 'center', minHeight: '70vh' }} >

              <div style={{ textAlign: 'center', marginTop: -20 }} >
                <Icons.Warning style={{ fontSize: '7rem' }} color='warning' fontSize='large' />
              </div>

              <h1 style={{ fontWeight: 400, marginTop: 0 }} >Free Plan Limit Reached!</h1>

              <h2 style={{ margin: 20, marginTop: -20 }}>{currentUser?.companyName}</h2>
              <Alert severity="warning" >

                <div style={{ fontSize: 16, textAlign: 'left' }}>

                  Your company Free plan limit has been reached.
                  <br /> If you're not the manager,
                  please ask your Manager(s) or operation's manager to
                  Upgrade to a paid plan so you can continue using Talgy.

                </div>

              </Alert>

              <Alert severity="warning" style={{ marginTop: 20 }} >

                Urgent: If Upgrade is not done soon, all Jobs/Candidates from <b>{currentUser?.companyName}</b> company users will Expire and be deleted from Talgy.

              </Alert>

              <h3 style={{ marginTop: 20 }}>{currentUser?.companyName} Company Stats:</h3>

              <div style={{ marginTop: -20, fontSize: 14, marginBottom: 10 }}> Company Stats from ALL users of the company <b>{currentUser?.companyName}</b> </div>


              <Stack direction="row" spacing={2}>

                <StatPaper square><span style={statNumber}>{stats?.usersCount}</span> <br />
                  Users

                  <Tooltip arrow placement="right"
                    title={`${stats?.companyUsersEmails}`}
                  >
                    <Icons.Info color='warning'
                    style={{   marginBottom: -7, marginLeft:5 }} />
                  </Tooltip>


                </StatPaper>
                <StatPaper square><span style={statNumber}>{stats?.jobsCount}</span> <br /> Jobs</StatPaper>
                <StatPaper square><span style={statNumber}>{stats?.candidatesCount}</span> <br /> Candidates</StatPaper>

                <StatPaper square><span style={statNumber}>{stats?.sentResumeRequests}</span> <br /> Sent Resume Requests</StatPaper>
                <StatPaper square><span style={statNumber}>{stats?.sentCandidateSubmits}</span> <br /> Sent Candidate Submits</StatPaper>
                <StatPaper square><span style={statNumber}>{stats?.receivedCandidateSubmits}</span> <br /> Received Candidate Submits</StatPaper>
                <StatPaper square><span style={statNumber}>{stats?.receivedResumeRequests}</span> <br /> Received Resume Requests</StatPaper>



              </Stack>



            </div>



          </DialogContent>

          {/*  <div style={{ marginTop: 30, textAlign: 'center' }}>
                <Button variant="contained" color="primary"
                // onClick={(e) => this.onResendInviteClick(e)}
                >Upgrade</Button>
              </div> */}

          <DialogActions>

            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => App.RouteManager.routeToPath('/Account/Billing')}
            // disabled={vm.isActionInProgress}
            >Upgrade</Button>

          </DialogActions>
        </Dialog>
      </div>
    );



  }
}
export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(BillingLimitReachedModal as any);