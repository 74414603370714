import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon,Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import * as XGrid from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

interface MyAccountResumeRequestsTableProps {
  state: App.MyAccountState;
  onChange?: Function;

}

export class MyAccountResumeRequestsTable extends React.PureComponent<MyAccountResumeRequestsTableProps> {

  public myAccountService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myAccountService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('MyAccountResumeRequestsTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }

  

  

  public getAlertColumnRenderer = (value: any, tableMeta: any, updateValue: any) => (
    <App.AlertTypeIcon name={value} />
  )


  public onSelectionModelChange(model: any[], details: XGrid.GridCallbackDetails) {

    //console.log('onSelectionModelChange model:', model)
    //console.log('onSelectionModelChange details:', details)
/* 
    var vm = this.props.state.vm;
    var state = this.props.state;

    var rows = state.candidates;

    var id = model[ 0 ];

    if (LIB.Common.isNullOrEmpty(id))
      return;

    console.log('onSelectionModelChange id:', id);

    var item = rows.find(x => x.candidateId == id);

    console.log('onSelectionModelChange item:', item);

    vm.setSelectedCandidate(item);

    console.log('onSelectionModelChange selectedCandidate', vm.selectedCandidate); */


  }

  public setSelected(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var found = state.candidates.find(x => x.candidateId === id);

    vm.setSelectedCandidate(found);
  }

  public onNameClick(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var sm = state.candidateSm;

    this.setSelected(id);

    vm.isCandidatesEditOpen = true;

    if (this.props.onChange !== undefined)
      this.props.onChange();

    this.forceUpdate();

  }

  public getTableColumns() {

    var editable = false;
    var readOnly = false;
    //https://mui.com/x/api/data-grid/data-grid/ API

 

    var expColumn: GridColDef = {
      headerName: 'Experience',
      field: 'experience',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.ResumeRequest;
        var value = item.candidate?.experience;
        if (LIB.Common.isNullOrEmpty(value))
          return '';
        return value + '+ years'
      }
    }
    var visaTypeCol: GridColDef = {
      headerName: 'Visa',
      field: 'visaTypeNameOrOther',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.ResumeRequest;
        var value = item.candidate?.experience;
        if (LIB.Common.isNullOrEmpty(value))
          return '';
        return value + '+ years'
      }
    }




    var nameColumn: GridColDef = {
      field: 'candidateName',
      headerName: 'Candidate',
      width: 140,
      renderCell: (params: XGrid.GridRenderCellParams) => {

        var item = params.row as Api.ResumeRequest;
        var value = item.candidateName;
        var id = item.candidateId;
        if (readOnly)
          return value;

        var nameLink = <Link
          key={'candidateId_' + id}
          onClick={(e: any) => this.onNameClick(id)}
          color="primary">{value ? value : ''}</Link>;

        
          return nameLink;


      }
    }
 
    var resumeColumn: GridColDef = {
      headerName: 'Resume',
      field: 'resumeFile',
      width: 100,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        var item = params.row as Api.ResumeRequest;
        var value = App.CandidateManager.getFullResumePath(item?.candidate);

        if(LIB.Common.isNullOrEmpty(value))
            return ''; 

        return <a href={value} target='_blank' title="Resume"><Icons.AttachFile style={{ color: 'red' }} /></a>
      }
    }
    

    var columns: GridColDef[] = [

     /*  {
        headerName: 'ID',
        field: 'resumeRequestId',
        width: 80,
        editable: editable,
      },   */
      {
        headerName: 'Candidate',
        field: 'candidateName',
        width: 140,
        editable: editable,
      },
      resumeColumn,
      {
        headerName: 'Title',
        field: 'title',
        width: 140,
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.ResumeRequest;
          return item?.candidate?.title;
        }
      },
      {
        headerName: 'Skills',
        field: 'skills',
        flex:1,
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.ResumeRequest;
          return item?.candidate?.skills;
        }
      },
     
      expColumn,
      {
        headerName: 'Visa',
        field: 'visaTypeNameOrOther',
        width: 100,
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.ResumeRequest;
          return item?.candidate?.visaTypeNameOrOther;
        }
      },
      {
        headerName: 'Relocation',
        field: 'relocationTypeNameOrOther',
        width: 120,
        renderCell: (params: XGrid.GridRenderCellParams) => {
          var item = params.row as Api.ResumeRequest;
          return item?.candidate?.relocationTypeNameOrOther;
        }
      },
      {
        headerName: 'Agency',
        field: 'ownerCompanyName',
        //  width: 200,
        editable: editable,
      },
      {
        headerName: 'Recruiter',
        field: 'ownerUserName',
        width: 150,
        editable: editable,
      },
      {
        headerName: 'Recruiter Phone',
        field: 'ownerUserPhone',
        width: 120,
        editable: editable,
      },
      {
        field: 'requestDate',
        headerName: 'Request Date',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => {
          let item = params.row as Api.ResumeRequest;
          if(LIB.Common.isNullOrEmpty(item.requestDate))
            return '';
          return LIB.DateHelper.getFormattedShortDate(item.requestDate);
        }
      },

      
    ];


    return columns;
  }


  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    

  }

 
  public render() {
    //console.log('MyAccountResumeRequestsTable render');
    var state = this.props.state;
    var vm = state.vm;

    /*   if (LIB.Common.isNullOrEmpty(state) || !state.hasCandidateDataLoaded)
        return <LIB.Loading />;
   */
    var filter = state.candidateSearchFilter;
    var rows = [...state.resumeRequests];


    //console.log('MyAccountResumeRequestsTable render rows',rows);


    return <div style={{ marginTop: 20 }}>

      <Box sx={{ height: '70vh', width: '100%' }}>
        <DataGrid

          getRowId={(row) => row.resumeRequestId as number}
          rows={rows}
          columns={this.getTableColumns()}
          rowCount={10}
          pageSizeOptions={[ 10 ]}
          checkboxSelection={false}
         
          onRowSelectionModelChange={this.onSelectionModelChange.bind(this)}
       
        />
      </Box>
      

    </div>
  }

};

