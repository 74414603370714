import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class CandidateListState  extends LIB.BaseStateModel {
 
  
    public vm:App.CandidateListVm = new App.CandidateListVm();
 
    public candidates:Api.Candidate[];
    public candidatesCount:number=0;

    public searchFilter:Api.CandidateFilterVm = new Api.CandidateFilterVm();
    public candidatesFullList:Api.Candidate[];

    public resumeRequestSm:Api.CandidateRequestResumeSm = new Api.CandidateRequestResumeSm();
   
    constructor() { 
        
        super();
      
      
    }
     


}