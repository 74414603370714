import * as React from 'react';
import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, FormControl, FormHelperText, InputLabel
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';

interface RequestResumeModalProps {
  state: App.CandidateListState;
  onChange?: Function;
}


export class RequestResumeModal extends React.Component<RequestResumeModalProps> {

  // todo public designSetDataService: App.JobDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    // todo this.designSetDataService = new App.JobDataService();
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('RequestResumeModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('RequestResumeModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onSubmitClick(e: React.ChangeEvent<{}>) {
    console.log('onSubmitClick');


    var state = this.props.state;
    var vm = state.vm;
   // var sm = state.jobSm;
    var item = vm.selectedCandidate;
     
    console.log('onSubmitClick selectedCandidate',item);

   // var isValid = this.validationManager.validateJobCreateUpdate(null, item);

   /*  if (!isValid) {
      console.log('validateAddJobModal NOT valid');
      this.forceUpdate();
      return;
    } */
 
    var sm = state.resumeRequestSm;
    sm.candidateId = item.candidateId;
     
    if (vm.isRequestResumeOpen)
      App.AppStore.dispatch(App.ActionTypes.CANDIDATE_LIST_REQUEST_RESUME, sm);
   
    vm.isActionInProgress = true; 

    App.RouteManager.routeToPath('?');

    this.forceUpdate();

  }

  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
     
    //reset edits
    //App.JobManager.update(vm.selectedCandidateOriginal, state.jobs);

    vm.isRequestResumeOpen = false;
     
    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';
    App.RouteManager.routeToPath('?');

    this.forceUpdate();

  }
 
  

  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.resumeRequestSm;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);

    if (LIB.Common.isNullOrEmpty(name))
      return;


    //todo this.validationManager.validateJobCreateUpdate(name, item);


    this.forceUpdate();
  }

  public getForm() {
    //console.log('RequestResumeModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCandidate;


    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedCandidate;



    if (item === undefined)
      return null;

    var sm = state.resumeRequestSm;
    sm.candidateId = item.candidateId;
 

    return <App.MainPage
      state={vm}
      hasDataLoaded={state.hasDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      <form     >
 
      
        <Alert severity="info" style={{marginBottom:10}}>
        Request the resume from the agency by writing a Message and clicking on Request. 
        The Recruiting Agency will be notified and will respond to your request.  
        </Alert>
 
         <div>ID: <b style={{marginRight:10}}>{item.candidateId}</b> Name: <b style={{marginRight:10}}>{item.name}</b>  Agency: <b>{item.companyName}</b> </div>
            <div>

            <TextField
              name="message" label="Message"
              value={sm.message || ''}
              {...App.AppBase.getInputErrorProps('message')}
              onChange={this.onInputChange.bind(this)}
              style={fullWidth}
              multiline={true}
              inputProps={{ style: formInput }}
              minRows={3}
              maxRows={8}
            />
          </div>   

         
 

      </form>

    </App.MainPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
     
    console.log('RequestResumeModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isRequestResumeOpen);
    var item = vm.selectedCandidate;


    var title = `Request Resume`;// for: ${item?.name}`;
    

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ width: '30vw', minWidth:'300px' }}>

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</Button>


 
        {App.AppBase.isUserAuthenticated && 
            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onSubmitClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Request Resume</Button>
            
        }
         {!App.AppBase.isUserAuthenticated && 
             <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={(e) =>App.RouteManager.routeToPath("/Login")}
              >Login to Continue</Button>

         }


          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 