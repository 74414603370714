import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class CmsContentService extends App.BaseAppService  {
 

   // private attachmentService:App.AttachmentService;

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
        //this.attachmentService = new App.AttachmentService();
    } 

  
    public getContentPageListData(filter:Api.ContentPageFilterVm|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/CmsContent/getContentPageListData?${urlPrams}`;
 
        this.get(apiUrl,
            App.ActionTypes.CMS_CONTENT_PAGES_LIST_DATA_RECEIVED,
            App.ActionTypes.CMS_API_ERROR 
            );

    }
     
    public createContentPage(data:Api.ContentPage) {
        console.log("CmsContentService.createContentPage data: ", data);

       // var formData = LIB.FormDataHelper.getFormData(data);

       // console.log("CmsContentService.createContentPage formData: ",formData);
  
        const apiUrl = `${App.AppBase.appApiBaseUrl}/CmsContent/createContentPage`;
        this.post(apiUrl, data, 
            App.ActionTypes.CMS_CONTENT_PAGES_ADD_SUCCESS,
            App.ActionTypes.CMS_API_ERROR 
 
             );
 
    }

  /*   public onUploadSuccess(){
        console.log("onUploadSuccess");

        vm.isContentPagesEditOpen=false;

    }

    public onUploadError(error:any){
        console.log("onUploadError error",error);
    } */

    public updateContentPage(data:Api.ContentPage) {
        console.log("CmsContentService.updateContentPage data: ", data);

       // var formData = LIB.FormDataHelper.getFormData(data);

       // console.log("CmsContentService.updateContentPage formData: ",formData);
       // console.log("CmsContentService.updateContentPage JSON formData: ",JSON.stringify(formData));
 
      
        const apiUrl = `${App.AppBase.appApiBaseUrl}/CmsContent/updateContentPage`;
        this.post(apiUrl, data, 
            App.ActionTypes.CMS_CONTENT_PAGES_UPDATE_SUCCESS,
            App.ActionTypes.CMS_API_ERROR 
            );
 
    }

    public deleteContentPage(contentPageId:number) {
        console.log("CmsContentService.deleteContentPage contentPageId",contentPageId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/CmsContent/deleteContentPage?contentPageId=${contentPageId}`;
        this.post(apiUrl, contentPageId, 
            App.ActionTypes.CMS_CONTENT_PAGES_DELETE_SUCCESS,
            App.ActionTypes.CMS_API_ERROR
            );
 
    }
  
    public generateContentFromAIScript(contentPageId:number) {
        console.log("CmsContentService.contentPageId");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/CmsContent/generateContentFromAIScript?contentPageId=${contentPageId}`;
        this.get(apiUrl, 
            App.ActionTypes.CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_SUCCESS,
            App.ActionTypes.CMS_API_ERROR
            );
 
    }
    
     
}
