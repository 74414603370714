import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationCompanyReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_COMPANIES"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);


        var adminCompaniesService = new App.AdminCompaniesService();

        // const action = incomingAction as KnownAction;
        switch (action.type) {
            case App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var searchFilter = action.payload;
                
                // prospectsService.getProspectsListData(searchFilter);
                adminCompaniesService.getCompaniesListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_RECEIVED: {
                console.log('ADMINISTRATION_COMPANIES_LIST_DATA_RECEIVED:',action.payload);
                result = { ...state };

 
                if (LIB.Common.isNullOrEmpty(result.companySearchFilter?.keyword)) {
                    result.companiesFullList = action.payload.companies;
                }
                result.companies = action.payload.companies;
                result.companiesCount = action.payload.companiesCount;
 

                console.log("ADMINISTRATION_COMPANIES_LIST_DATA_RECEIVED companies", result.companies);

                result.isServiceCallPending = false;
                result.hasCompanyDataLoaded = true;
                console.log("result", result);
                break;
            }

            
            case App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_COMPANIES_LIST_PAGE_RESET:');
                result = { ...state };
                console.log("payload", action.payload);
                result.companies = result.companiesFullList; 
                result.companySearchFilter.keyword = '';
              //  result.isServiceCallPending = false;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_COMPANIES_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_COMPANIES_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                adminCompaniesService.updateCompany(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_COMPANIES_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_COMPANIES_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };

                App.AdministrationManager.updateCompany(item, result.companies);

                vm.apiErrorMessage = ''; //to clear any api error msg
                vm.isCompaniesEditOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                vm.setSelectedCompany(item);//

                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_COMPANIES_ADD_REQUEST: {
                console.log('ADMINISTRATION_COMPANIES_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var data = action.payload;
                adminCompaniesService.createCompany(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_COMPANIES_ADD_SUCCESS: {
                console.log('ADMINISTRATION_COMPANIES_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm;
                var item = { ...action.payload };
                //  var item = vm.;

                console.log("item pushed: ", (item));
                // console.log(item.constructor.name); 

                result.companies.splice(0, 0, item); //push at the beginning
                vm.apiErrorMessage = '';
                vm.isCompaniesAddOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;

                vm.setSelectedCompany(); //
                console.log("vm.setSelectedCompany();:, ", vm.setSelectedCompany);
                // result.adminCreateProspectSm = new Api.Prospect();


                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_COMPANIES_DELETE_REQUEST: {
                console.log('ADMINISTRATION_COMPANIES_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage = '';
                var vm = result.vm;
                var itemId = action.payload;

                adminCompaniesService.deleteCompany(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.ADMINISTRATION_COMPANIES_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_COMPANIES_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId: any = vm.selectedCompany?.companyId;
                var success = action.payload;

                if (success)
                    App.AdministrationManager.deleteCompanyById(itemId, result.companies);

                vm.isCompaniesDeleteOpen = false;
                vm.isActionInProgress = false;
                result.isServiceCallPending = false;
                //vm.setSelectedUser();

                result.forceUpdateCount++;
                break;
            }




            default:
                result = state;
                //return result;
                break;
        }
        console.log('ADMINISTRATION_COMPANIES_DATA_RECEIVED result2:', result);
        //  console.log('AdministrationCompany_DATA_RECEIVED action:',action);


        if (action.postActionCallBack) {
            console.log("postactioncallback");
            action.postActionCallBack(result);
        }
        return result;
    }



}